import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { db } from "./firebase";

function FooterForm() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/contact");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const initialFormData = {
    name: "",
    companyname: "",
    companyAddress: "",
    email: "",
    phone: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [loader, setLoader] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const validateForm = () => {
    const errors = {};

    // Validate email
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is not valid";
    }

    // Validate name
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }

    // Validate phone number
    if (!formData.phone.trim()) {
      errors.phone = "Phone number is required";
    } else if (formData.phone.length >= 12) {
      errors.phone = "Phone number should be less than 12 characters";
    }

    // Add more validation rules for other fields here

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setLoader(true);

    db.collection("contacts")
      .add({
        name: formData.name,
        email: formData.email,
        message: formData.message,
        companyname: formData.companyname,
        companyAddress: formData.companyAddress,
        phone: formData.phone,
      })
      .then(() => {
        setLoader(false);
        setSubmissionStatus("Your message has been submitted👍");
      })
      .catch((error) => {
        setSubmissionStatus("Submission failed");
        setLoader(false);
      });

    // Clear form fields after submission
    setFormData(initialFormData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setValidationErrors({ ...validationErrors, [name]: "" });
  };
  const resetForm = () => {
    setFormData(initialFormData);
    setValidationErrors({});
    setSubmissionStatus(null);
  };
  return (
    <div>
      <div
        className="contact--form"
        style={{
          opacity: 1,
          transition: "opacity 0.9s ease",
        }}
      >
        <div className="container container--full"></div>
        <div className="container">
          <div className="ctf-mwrapper">
            <div
              className="ctf-mwrapper-inner"
              style={{ cursor: "pointer" }}
              initial={{ y: "100%", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, ease: "easeOut" }}
            >
              <div onClick={handleButtonClick}>
                <Link
                  to="/home"
                  className="planner-close"
                  style={{ cursor: "pointer" }}
                >
                  Close
                  <span className="planner-close-icon"></span>
                </Link>
              </div>

              <div className="ctf-stepm">
                <motion.h1
                  initial={{ y: "100%", opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1, ease: "easeOut" }}
                >
                  <h2 className="ctf-inpstep__title">
                    <span>Start a project</span>
                  </h2>
                </motion.h1>

                <div className="step-instructions">
                  <p
                    initial={{ y: "100%", opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 1, ease: "easeOut" }}
                  >
                    Thanks for your interest in working with us. Please complete
                    the details below and we’ll get back to you.
                  </p>
                </div>
              </div>
              {submissionStatus ? (
                <div>
                  <h1>{submissionStatus}</h1>
                  <div className="hidennformgg">
                  <button onClick={resetForm} style={{position:'absolute'}} className="hiddnfb">Send Another</button>
                  </div>
                </div>
              ) : (
              <form onSubmit={handleSubmit}>
                {/* <h1>Contact Us 🤳</h1> */}
                {/* {submissionStatus && <h1>{submissionStatus}</h1>} */}

                <div className="form-field">
                  <label>Name</label>
                  <input
                    name="name"
                    className="form-control validate"
                    placeholder="Your name here"
                    value={formData.name}
                    onChange={handleChange}
                    type="text"
                  />
                  {validationErrors.name && (
                    <span className="error" style={{ color: "red" }}>
                      {validationErrors.name}
                    </span>
                  )}
                </div>
                <div className="form-field">
                  <label>Company Name</label>
                  <input
                    name="companyname"
                    placeholder="Company Name"
                    value={formData.companyname}
                    onChange={handleChange}
                    className="form-control validate"
                    type="text"
                  />
                </div>
                <div className="form-field">
                  <label>Company Address</label>
                  <input
                    name="companyAddress"
                    placeholder="Company Address"
                    value={formData.companyAddress}
                    onChange={handleChange}
                    className="form-control validate"
                    type="text"
                  />
                </div>
                <div className="form-field clearfix">
                  <label>Email</label>
                  <input
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    className="form-control validate"
                  />
                  {validationErrors.email && (
                    <span className="error" style={{ color: "red" }}>
                      {validationErrors.email}
                    </span>
                  )}
                </div>
                {/* <label>Message</label>
      <textarea
        name="message"
        placeholder="Message"
        value={formData.message}
        onChange={handleChange}
      ></textarea> */}

                <div className="form-field clearfix">
                  <label>Phone Number</label>
                  <input
                    name="phone"
                    type="text"
                    placeholder="Phone Number"
                    value={formData.phone}
                    onChange={handleChange}
                    className="form-control validate"
                  />
                  {validationErrors.phone && (
                    <span className="error" style={{ color: "red" }}>
                      {validationErrors.phone}
                    </span>
                  )}
                </div>
                <div className="ctf-button">
                  <button
                    type="submit"
                    // style={{ background: loader ? "#ccc" : " rgb(2, 2, 110)" }}
                    className="ctf-submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
               )}
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterForm;
