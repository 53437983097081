import React from "react";
import "./project.css";
import laptop from "../Assets/laptop.png";
import Capture from "../Assets/Capture.PNG";
import n from "../Assets/n.PNG";
import download from "../Assets/download.png";
import m from "../Assets/mm.avif";
import i from "../Assets/i.png";
import g from "../Assets/g.png";
import h from "../Assets/h.png";
import k from "../Assets/k.png";
import cc from "../Assets/cc.png";
import abo from "../Assets/abo.png";
import transition from "../../transition";
import PBanner from "./PBanner";
import pro2 from "../Assets/pro2.jpg";
import pro3 from "../Assets/pro3.png";
import pro4 from "../Assets/pro4.png";import { Link, useNavigate } from "react-router-dom";
import hotelp from "../Assets/hotelp.png";
import hosp from "../Assets/hosp.png";
import pra from "../Assets/pra.png";
import Fade from 'react-reveal/Fade';
// import Fade from 'react-reveal/Fade';
function Project() {
  const bnavigate = useNavigate();
  const phosnavigate = useNavigate();
  const phoshandleButtonClick = () => {
    phosnavigate("/projects/projecthotel");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // const bnavigate = useNavigate();
  const bhandleButtonClick = () => {
    bnavigate("/projects/projecthotel");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const pgnavigate = useNavigate();
  const pghandleButtonClick = () => {
    bnavigate("/projects/ProjectGvk");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const prnavigate = useNavigate();
  const prahandleButtonClick = () => {
    bnavigate("/projects/ProjectPra");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const phenavigate = useNavigate();
  const phehandleButtonClick = () => {
    bnavigate("/projects/Projecthelica");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <div class="hel-mainp hPVNmo" style={{ cursor: "pointer" }}>
        <main>
          <section>
            <div class="sc-hLBbgP biGtBB">
              <div direction="column" class="sc-dkrFOg gPVkTy sc-elAWhN kxFcfd">
                <div class="sc-dkrFOg ieMbRd">
                  <div class="sc-iBYQkv kKRCRY">
                    <div class="sc-ftTHYK kQjXco">
                      <div class="sc-dkrFOg dnXFIz">
                        <PBanner />
                        {/* <h1 class="sc-eDvSVe jtaJGO">Projects</h1> */}
                        <div class="sc-kiPvrU ciArwO">
                          <span class="sc-eDvSVe kBmkal">6</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="sc-iBYQkv fStySe">
                    <div class="sc-ftTHYK cHNgNo">
                      <span class="sc-jSUZER TPnBF">
                        Select examples of our work
                      </span>
                    </div>
                  </div>
                </div>
                <div class="sc-gswNZR bSqfQs"></div>
              </div>
              <div class="sc-cLNonn jyhgZP">
                <div
                  direction="column"
                  class="sc-dkrFOg kgqXNr sc-iTFTee duQrUb  "
                  onClick={bhandleButtonClick}
                >
                  <div class="sc-iAEawV iLneeU">
                  <Fade bottom>
                    
                    <a class="sc-eeMvmM gEEGsZ">
                      <div className="laptop">
                        <img
                          src={hotelp}
                          className="img1"
                          class="sc-fnGiBr gYRMlI sc-cUEOzv iceamZ"
                        ></img>
                        {/* <img
                       src={i}
                       
                       width="100%"
                       height="100%"
                       class="sc-fnGiBr gYRMlI sc-cUEOzv iceamZ img2"
                   /> */}
                      </div>
                    </a>
                    </Fade>
                    <div class="sc-jOiSOi hrfGHw">
                      <div>
                        <div
                          class="sc-dkrFOg fTibjT sc-jcMfQk hQOsvn"
                          color="#F76567"
                        >
                          <div class="sc-dkrFOg fTibjT sc-cabOPr OUdfV">
                            <svg
                              width="11"
                              height="14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.537 13.022A1 1 0 0 1 0 12.178V1.822A1 1 0 0 1 1.537.978l8.137 5.178a1 1 0 0 1 0 1.688l-8.137 5.178Z"
                                fill="#fff"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Fade bottom>
                  <span class="sc-eDvSVe lhOvMH newppp" >
                  37th Crescent Hotel 
                  </span>
                  </Fade>
                  <br></br>
                  <span class="sc-jSUZER TPnBF sc-dPWrhe jGWXwK">
                
                  The Perfect Place to Relax and Rejuvenate
                  </span>
                </div>
                <div
                  mobile="false"
                  class="sc-kMjNwy iwagXM"
                  style={{
                    position: "fixed",
                    top: "0",
                    bottom: "0",
                    left: "0",
                    right: "0",
                    zIndex: "9999999",
                    overflow: "hidden",
                    pointerEvents: "none",
                  }}
                ></div>
                <div
                  direction="column"
                  class="sc-dkrFOg kgqXNr sc-iTFTee hDkvno"
                    onClick={prahandleButtonClick}
                >
                  <div class="sc-iAEawV iLneeU">
                    <a class="sc-eeMvmM gEEGsZ">
                      <div className="laptop">
                        <img
                          src={pra}
                          className="img1"
                          class="sc-fnGiBr gYRMlI sc-cUEOzv iceamZ"
                        ></img>
                        {/* <img
                       src={n}
                       
                       width="100%"
                       height="100%"
                       class="sc-fnGiBr gYRMlI sc-cUEOzv iceamZ img2"
                   /> */}
                      </div>
                    </a>
                    <div class="sc-jOiSOi hrfGHw">
                      <div>
                        <div
                          class="sc-dkrFOg fTibjT sc-jcMfQk ehAIsa"
                          color="#F7F629"
                        >
                          <div class="sc-dkrFOg fTibjT sc-cabOPr hqFLYc">
                            <svg
                              width="11"
                              height="14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.537 13.022A1 1 0 0 1 0 12.178V1.822A1 1 0 0 1 1.537.978l8.137 5.178a1 1 0 0 1 0 1.688l-8.137 5.178Z"
                                fill="#fff"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Fade bottom>
                  <span class="sc-eDvSVe lhOvMH newppp" >
                  Prabhu Industries
                
                  </span>
                  </Fade>
                  <br></br>
                  <span class="sc-jSUZER TPnBF sc-dPWrhe jGWXwK">
                  The rudimentory process of business starts here.
                  </span>
                </div>
                <div
                  mobile="false"
                  class="sc-kMjNwy iwagXM"
                  style={{
                    position: "fixed",
                    top: "0",
                    bottom: "0",
                    left: "0",
                    right: "0",
                    zIndex: "9999999",
                    overflow: "hidden",
                    pointerEvents: "none",
                  }}
                ></div>
                <div
                  direction="column"
                  class="sc-dkrFOg kgqXNr sc-iTFTee duQrUb"
                  onClick={phehandleButtonClick}
                >
                  <div class="sc-iAEawV iLneeU">
                    <a class="sc-eeMvmM gEEGsZ">
                      <div className="laptop">
                        <img
                          src={k}
                          class="sc-fnGiBr gYRMlI sc-cUEOzv iceamZ"
                        ></img>
                        {/* <img
                       src={n}
                       
                       width="100%"
                       height="100%"
                       class="sc-fnGiBr gYRMlI sc-cUEOzv iceamZ mimg2"
                   /> */}
                      </div>
                    </a>
                    <div class="sc-jOiSOi hrfGHw">
                      <div>
                        <div
                          class="sc-dkrFOg fTibjT sc-jcMfQk lnbWJV"
                          color="#5950FF"
                        >
                          <div class="sc-dkrFOg fTibjT sc-cabOPr kRkBdY">
                            <svg
                              width="11"
                              height="14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.537 13.022A1 1 0 0 1 0 12.178V1.822A1 1 0 0 1 1.537.978l8.137 5.178a1 1 0 0 1 0 1.688l-8.137 5.178Z"
                                fill="#fff"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Fade bottom>
                  <span class="sc-eDvSVe lhOvMH newppp" >
                  Helica Technologies 
                  </span>
                  </Fade>
                  <br></br>
                  <span class="sc-jSUZER TPnBF sc-dPWrhe jGWXwK">
                  
                  The rudimentory process of business starts here.
                  </span>
                </div>
                <div
                  mobile="false"
                  class="sc-kMjNwy iwagXM"
                  style={{
                    position: "fixed",
                    top: "0",
                    bottom: "0",
                    left: "0",
                    right: "0",
                    zIndex: "9999999",
                    overflow: "hidden",
                    pointerEvents: "none",
                  }}
                ></div>
                <div
                  direction="column"
                  class="sc-dkrFOg kgqXNr sc-iTFTee hDkvno"
                  onClick={pghandleButtonClick}
                >
                  <div class="sc-iAEawV iLneeU">
                    <a class="sc-eeMvmM gEEGsZ">
                      <img
                        src={cc}
                        width="100%"
                        height="100%"
                        class="sc-fnGiBr gYRMlI sc-cUEOzv iceamZ"
                      />
                    </a>
                    <div class="sc-jOiSOi hrfGHw">
                      <div>
                        <div
                          class="sc-dkrFOg fTibjT sc-jcMfQk aWySW"
                          color="#000938"
                        >
                          <div class="sc-dkrFOg fTibjT sc-cabOPr bWcGnu">
                            <svg
                              width="11"
                              height="14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.537 13.022A1 1 0 0 1 0 12.178V1.822A1 1 0 0 1 1.537.978l8.137 5.178a1 1 0 0 1 0 1.688l-8.137 5.178Z"
                                fill="#fff"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Fade bottom>
                  <span class="sc-eDvSVe lhOvMH newppp" >
                  
                  GVK power and engineering soltions
                  </span>
                  </Fade>
                  <br></br>
                  <span class="sc-jSUZER TPnBF sc-dPWrhe jGWXwK">
                  
                  Industrial Electrical Distribution Solutions for a Better Tomorrow
                  </span>
                </div>
                <div
                  mobile="false"
                  class="sc-kMjNwy iwagXM"
                  style={{
                    position: "fixed",
                    top: "0",
                    bottom: "0",
                    left: "0",
                    right: "0",
                    zIndex: "9999999",
                    overflow: "hidden",
                    pointerEvents: "none",
                  }}
                ></div>
             
             
                {/* <div
                  direction="column"
                  class="sc-dkrFOg kgqXNr sc-iTFTee hDkvno"
                >
                  <div class="sc-iAEawV iLneeU">
                    <a class="sc-eeMvmM gEEGsZ">
                      <img
                        src={hosp}
                        width="100%"
                        height="100%"
                        class="sc-fnGiBr gYRMlI sc-cUEOzv iceamZ"
                      />
                    </a>
                    <div class="sc-jOiSOi hrfGHw">
                      <div>
                        <div
                          class="sc-dkrFOg fTibjT sc-jcMfQk emUOB"
                          color="#2196F3"
                        >
                          <div class="sc-dkrFOg fTibjT sc-cabOPr nisVx">
                            <svg
                              width="11"
                              height="14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.537 13.022A1 1 0 0 1 0 12.178V1.822A1 1 0 0 1 1.537.978l8.137 5.178a1 1 0 0 1 0 1.688l-8.137 5.178Z"
                                fill="#fff"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Fade bottom>
                  <span class="sc-eDvSVe lhOvMH newppp" >
               
                  Pioneering world-class healthare for all
                  </span>
                  </Fade>
                  <br></br>
                  <span class="sc-jSUZER TPnBF sc-dPWrhe jGWXwK">
                  Sanjeevini Hospital
                  </span>
                </div>
                <div
                  mobile="false"
                  class="sc-kMjNwy iwagXM"
                  style={{
                    position: "fixed",
                    top: "0",
                    bottom: "0",
                    left: "0",
                    right: "0",
                    zIndex: "9999999",
                    overflow: "hidden",
                    pointerEvents: "none",
                  }}
                ></div> */}
              </div>
              <div class="sc-gswNZR bSqfQs"></div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}

export default transition(Project);
