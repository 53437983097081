import React from "react";
import { Link,useNavigate } from "react-router-dom";
import transition from "../../transition";
import { motion } from 'framer-motion';
// import Cban from "../Landing/Cban";
import "./Contact.css";
import Fade from 'react-reveal/Fade';
function Contact() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    
    navigate("/contactform");
  };

  
  return (
    <div>
      
    
            <main  className="content-main" >
              <div className="banner banner--contact">
                <div className="container">
                  <div className="banner__caption">
                    <div className="banner__caption-inner">
                    <motion.h1
  className="banner__title"
  initial={{ y: '100%', opacity: 0 }}
  animate={{ y: 0, opacity: 1 }}
  transition={{ duration: 1, ease: 'easeOut' }}
>
  Let's make something great together.
  <div  class="border-line-btm bg-black contact"></div>
</motion.h1>;


                      <div className="banner__content">
                      <Fade bottom>
                        <p>
                          Contact us or start a project, we’ll get right back to you.
                          
                        </p>
                        </Fade>

                        {/* <div className="banner__link"  onClick={handleButtonClick} style={{cursor:'pointer'}}>
                          <Link href="/contactform" className="btn btn--planner"  >
                            <span className="flex">
                             
                              <span className="btn__label"  style={{cursor:'pointer'}}  >Start a project</span>
                              <span className="btn__arrow">
                                
                              </span>
                            </span>
                          </Link>{" "}
                        </div> */}
                        <div class="contparent" onClick={handleButtonClick}>
  
  <button class="btn-gradient-2">Start a project</button>
</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
            </main>
           
          </div>
        
    
  );
}

export default transition(Contact);
