import React, { useEffect, useRef } from "react";
import LocomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/src/locomotive-scroll.scss";
import { timeline } from "motion";
import imagesLoaded from "imagesloaded";
import "./newload.css"
// import "../styles/home.scss";
import useLocomotiveScroll from "../hooks/useLocomotiveScroll";

const preloadImages = (selector) => {
  return new Promise((resolve) => {
    imagesLoaded(
      document.querySelectorAll(selector),
      { background: true },
      resolve
    );
  });
};

function getSectionHeight(element) {
  const { height } = element.getBoundingClientRect();
  const { childElementCount } = element;
  return height / childElementCount;
}

export default function NewLoader() {
  const scrollRef = useRef(null);
  const countRef = useRef(null);
  const countRef2 = useRef(null);
  const loaderRef = useRef(null);

  const titleRef = useRef(null);
  const imageRef = useRef(null);

  const [locomotiveRef] = useLocomotiveScroll({
    ref: scrollRef,
    smooth: true,
    smoothMobile: true,
  });

  useEffect(() => {
    // Preload images
    Promise.all([preloadImages(".grid-item-media")]).then(() => {
      if (locomotiveRef.current) {
        locomotiveRef.current.update();
      }
    });
  }, []);

  useEffect(() => {
    if (countRef.current && countRef2.current && loaderRef.current) {
      const transformAmount = getSectionHeight(countRef.current);

      const sequence1 = new Array(3).fill("").flatMap((_, index) => [
        [countRef.current, { y: `-${transformAmount * (index + 1)}px` }],
        [
          countRef2.current,
          { y: `-${transformAmount * (index + 1)}px` },
          { at: "-1.8" },
        ],
      ]);

      timeline(sequence1, {
        defaultOptions: { easing: [0.77, 0, 0.175, 1], duration: 1 },
      });
    }
  }, []);

  useEffect(() => {
    const sequence2 = [
      [titleRef.current, { y: 100 }],
      [imageRef.current, { scale: 1.2 }, { at: "<" }],
      [countRef.current, { opacity: 0 }, { at: "<" }],
      [countRef2.current, { opacity: 0 }, { at: "<" }],
      [loaderRef.current, { y: "-100vh" }, { at: "-0.5" }],
      [titleRef.current, { y: 0 }, { at: "-.5" }],
      [imageRef.current, { scale: 1 }, { at: "<" }],
    ];

    timeline(sequence2, {
        defaultOptions: { easing: [0.77, 0, 0.175, 1], duration: 1 }, // Adjust duration and delay here
      });
  }, []);

  return (
    <>
    <div className="newloaderrr">
      <div className="loader-container" ref={loaderRef}>
        <div className="counter-container">
          <ul className="counter-list" ref={countRef}>
            <li>
              <h3>2</h3>
            </li>
            <li>
              <h3>9</h3>
            </li>
            <li>
              <h3>6</h3>
            </li>
            <li>
              <h3>9</h3>
            </li>
            
          </ul>
        </div>

        <div className="counter-container">
          <ul className="counter-list" ref={countRef2}>
            <li>
              <h3>3</h3>
            </li>
            <li>
              <h3>9</h3>
            </li>
            <li>
              <h3>8</h3>
            </li>
            <li>
              <h3>9</h3>
            </li>
          </ul>
        </div>
      </div>
     
      </div>
    </>
  );
}
