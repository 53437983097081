import React from "react";
import "./Navbar.css";

import { useState, useContext } from "react";
import { Link } from "react-router-dom";
// import Navi from "../Navi/Navi";
import openlogo from "../Assets/openlogo.png";
import lik from "../Assets/lik.png";
import yot from "../Assets/yot.png";
import fcb from "../Assets/fcb.png";
import insta from "../Assets/insta.png";
import close from "../Assets/close.png";
import logo from "../Assets/hlogo.png";
import { motion } from 'framer-motion';
import transition from "../../transition";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [isLinksVisible, setLinksVisible] = useState({
    work: false,
    about: false,
    home: false,
    contact: false,
  });

  const toggleLinkVisibility = (link) => {
    setLinksVisible((prevState) => ({
      ...prevState,
      [link]: !prevState[link],
    }));
  };

  return (
    <div className="nav">
      <header className="is-bg-light headerbar" data-v-23f22e4b>
        <div className="inner container size-fluid" data-v-23f22e4b>
        <Link to="/" className="logo" data-v-23f22e4b onClick={scrollToTop}>
  <img src={logo}></img>
 
</Link>

          <div className="spacer" data-v-23f22e4b></div>

          <div className="hamburger-menu" onClick={toggleMenu}  
          initial={{ scaleY: 1 }} 
          animate={{ scaleY: isOpen ? 0 : 1 }} 
          exit={{ scaleY: 0 }} 
          transition={{ duration: 0.5, ease: [0.22, 1, 0.36, 1] }} >
            <div
              className={`line ${isOpen ? "active" : ""}`}
              onClick={toggleMenu}
            ></div>
            <div
              className={`line ${isOpen ? "active" : ""}`}
              onClick={toggleMenu}
            ></div>
            <div
              className={`line ${isOpen ? "active" : ""}`}
              onClick={toggleMenu}
            ></div>

            {isOpen && (
              <motion.ul
              className={`menu ${isOpen ? "active" : ""}`}
              initial={{ scalex: 1 }}
              animate={{ scalex: isOpen ? 0 : 1 }}
              exit={{ scale: 1 }}
              transition={{ duration: 5, ease: [0.2, 1, 0.36, 1]

               }}
               style={{ transition: "all 0.9s ease"}}
               
            >
                <div>
                  <div class="wrapper">
                    <div class="page-holder">
                      <div
                        class="w-full fixed top-0 h-50 laptop:fixed py-12 z-30"
                        data-header=""
                      >
                        <div class="container overflow-hidden header-holder">
                          <div class="grid grid-cols-2 gap-x-20 items-center md:grid-cols-12">
                            <div class="flex md:col-span-6">
                              <a
                                class="inline-flex items-center header-logo"
                                href="/"
                              >
                                <span class="sr-only">Homepage</span>
                              </a>
                            </div>
                            <div class="relative md:col-span-6">
                              <div class="flex laptop:absolute header-burger-bar justify-end laptop:h-full laptop:right-0">
                                <div class="cursor-pointer" data-menu-opener>
                                  <svg
                                    class="w-25 h-auto"
                                    fill="none"
                                    height="21"
                                    viewbox="0 0 26 21"
                                    width="26"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      fill="currentColor"
                                      height="5.05556"
                                      width="26"
                                    ></rect>
                                    <rect
                                      fill="currentColor"
                                      height="5.05556"
                                      width="26"
                                      y="7.94434"
                                    ></rect>
                                    <rect
                                      fill="currentColor"
                                      height="5.05556"
                                      width="26"
                                      y="15.8887"
                                    ></rect>
                                  </svg>
                                </div>
                              </div>
                              <div class="uppercase font-bold text-xs items-center justify-between header-nav-bar hidden laptop:flex">
                                <div class="hidden laptop:block">
                                  <ul class="grid items-center gap-x-15 grid-flow-col justify-start">
                                    <li>
                                      <a
                                        class="inline-flex items-center group"
                                        href="/about/"
                                      >
                                        <span class="rounded-full h-10 w-10 bg-current inline-flex mr-8"></span>
                                        <span class="link" data-text="About">
                                          <span class="link__inner">
                                            <span>About</span>
                                            <span>About</span>
                                          </span>
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="inline-flex items-center group"
                                        href="/blogs/"
                                      >
                                        <span class="rounded-full h-10 w-10 bg-current inline-flex mr-8"></span>
                                        <span class="link" data-text="Blogs">
                                          <span class="link__inner">
                                            <span>Blogs</span>
                                            <span>Blogs</span>
                                          </span>
                                        </span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <div class="ml-auto">
                                  <a
                                    class="flex items-baseline group"
                                    href="/contact/"
                                    data-page-modal-view-link=""
                                  >
                                    <span class="inline-block arrow-before-text h-14 mr-8 object-contain w-14">
                                      <svg
                                        class="svgi"
                                        aria-labelledby="symbol-arrow-right-desc-s"
                                        role="group"
                                      >
                                        <desc id="symbol-arrow-right-desc-s">
                                          arrow-right icon
                                        </desc>
                                        <use></use>
                                      </svg>
                                    </span>
                                    <span class="link" data-text="Get in touch">
                                      <span class="link__inner">
                                        <span>Get in touch</span>
                                        <span>Get in touch</span>
                                      </span>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="w-full left-0 absolute top-0 bg-gray-900 grid h-screen md:pt-0 pt-95"
                          data-menu
                        >
                          <div class="absolute left-0 top-0 py-12 lg:px-30 px-25 w-auto z-2">
                            <Link
                              class="inline-flex items-center header-logo text-white"
                              to="/"
                              onClick={toggleMenu}
                            >
                              <span class="sr-only">Homepage</span>

                              <Link
                                to="/"
                                className="logo"
                                data-v-23f22e4b
                                onClick={scrollToTop}
                              >
                                <img
                                  src={openlogo}
                                  className="navopenlogo"
                                ></img>
                              </Link>
                            </Link>
                          </div>
                          <a className="nav-email">info@helica.in</a>
                          <div className="navsocial-icons-container">
                            <img src={lik}></img>

                            <img src={yot}></img>
                            <img src={fcb}></img>
                            <img src={insta}></img>
                          </div>

                          <div class="relative grid h-full">
                            <div
                              class="
                  w-full
                   h-full absolute top-0 bg-gray-200 md:w-2/3 right-0"
                              data-close-block
                            >
                              <div
                                class="w-full py-15 absolute cursor-pointer group px-20 z-2"
                                data-menu-close
                                onClick={toggleMenu}
                              >
                                <span class="flex uppercase justify-between items-start font-medium leading-xs md:text-19xl pointer-events-none text-7xl">
                                  <span class="flex-grow">
                                    <span class="flex overflow-hidden">
                                      <span class="relative ch inline-block">
                                       
                                      </span>
                                      <span class="relative ch inline-block">
                                      
                                      </span>
                                      <span class="relative ch inline-block">
                                      
                                      </span>
                                      <span class="relative ch inline-block">
                                      
                                      </span>
                                      <span class="relative ch inline-block">
                                       
                                      </span>
                                    </span>
                                  </span>

                                  <Link>
                                    <img src={close} className="closeimg"></img>
                                  </Link>
                                </span>
                              </div>
                            </div>
                            <div className="relative grid grid-rows-3 h-7/10 mt-auto">
                            <Link
                                class="flex uppercase lg:p-30 relative leading-xs -mb-1 bg-gray-100 font-bold group md:text-3xl ml-auto p-25 text-xl animated-link" 
                                to="/home"
                                data-menu-item
                                onClick={toggleMenu}
                                style={{ animationDelay: isOpen ? '0.8s' : '0s' }}
                              >
                                <div class="flex w-full justify-between">
                                  <span class="flex">Home</span>
                                  <span class="flex bg-current h-10 rounded-full w-10 md:h-25 md:p-5 md:w-25 p-3">
                                    <span class="w-full h-full bg-gray-100 duration-500 group-hover:scale-0 header-item-dot rounded-full transition-transform"></span>
                                  </span>
                                </div>
                              </Link>
                            
                              <Link
                                class="flex uppercase lg:p-30 relative leading-xs -mb-1 bg-gray-100 font-bold group md:text-3xl ml-auto p-25 text-xl"
                                to="/about"
                                data-menu-item
                                onClick={toggleMenu}
                                style={{ animationDelay: isOpen ? '0.6s' : '0s' }}
                              >
                                <div class="flex w-full justify-between">
                                  <span class="flex">About</span>
                                  <span class="flex bg-current h-10 rounded-full w-10 md:h-25 md:p-5 md:w-25 p-3">
                                    <span class="w-full h-full bg-gray-100 duration-500 group-hover:scale-0 header-item-dot rounded-full transition-transform"></span>
                                  </span>
                                </div>
                              </Link>

                              <Link
                                className={`flex uppercase lg:p-30 relative leading-xs -mb-1 bg-gray-100 font-bold group md:text-3xl ml-auto p-25 text-xl animated-link ${
                                  isLinksVisible.work ? "visible" : ""
                                }`}
                                to="/projects"
                                style={{ zIndex: "555", animationDelay: isOpen ? '0.4s' : '0s' }}
                                onClick={toggleMenu}
                                data-menu-item
                              >
                                <div class="flex w-full justify-between">
                                  <span class="flex" style={{textDecoration:'none'}}>Work</span>
                                  <span class="flex bg-current h-10 rounded-full w-10 md:h-25 md:p-5 md:w-25 p-3">
                                    <span class="w-full h-full bg-gray-100 duration-500 group-hover:scale-0 header-item-dot rounded-full transition-transform"></span>
                                  </span>
                                </div>
                              </Link>

                              <Link
                                class="flex uppercase lg:p-30 relative leading-xs -mb-1 bg-gray-100 font-bold group md:text-3xl ml-auto p-25 text-xl animated-link"
                                to="/contact"
                                data-page-modal-view-link
                                onClick={toggleMenu}
                                data-menu-item
                                style={{ animationDelay: isOpen ? '0.2s' : '0s' }}
                              >
                                <div class="flex w-full justify-between">
                                  <span class="flex">Contact us</span>
                                  <a
                                    className="nav-emaile"
                                    style={{ textTransform: "lowercase" }}
                                  >
                                    info@helica.in
                                  </a>

                                  <span class="flex bg-current h-10 rounded-full w-10 md:h-25 md:p-5 md:w-25 p-3">
                                    <span class="w-full h-full bg-gray-100 duration-500 group-hover:scale-0 header-item-dot rounded-full transition-transform"></span>
                                  </span>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </motion.ul>
            )}
          </div>
        </div>
      </header>
    </div>
  );
}

export default transition(Navbar);
