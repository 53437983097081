import React from "react";
import "./projectdetails.css";
import hotelp from "../Assets/hotelp.png";
import food from "../Assets/food.jpg"
import foo1 from "../Assets/food2.jpg"
import bur1 from "../Assets/bur1.jpg"
import bur2 from "../Assets/bur2.jpg"
import phm from "../Assets/phm.png"
import fullh from "../Assets/fullh.png"
import m from "../Assets/m.png"
import all from "../Assets/all.png"
import pimain from "../Assets/pimain.png"
import pilap from "../Assets/pilap.png"
import pic from "../Assets/pic.png"
import pif from "../Assets/pif.png";
import transition from "../../transition";
import icontab from "../Assets/icontab.png"
function ProjectPra() {
  const images = [
    {
      id: 1,
      src: "https://images.unsplash.com/photo-1543007630-9710e4a00a20?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1935&q=80",
      alt: "Image 1",
    },
    {
      id: 2,
      src: "https://images.unsplash.com/photo-1543007630-9710e4a00a20?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1935&q=80",
      alt: "Image 2",
    },

    
  ];
  return (
    <div className="projectdetailgvk">
      <div class="header-aside-trigger"></div>
      <div class="header-aside">
        <svg
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="274"
          height="276"
          viewBox="0 0 274 276"
        >
          <path
            fill="#E2D1FF"
            fill-rule="evenodd"
            d="M151.551 150.546c13.85 0 25.118-11.256 25.118-25.091h25.117c0 27.67-22.535 50.181-50.235 50.181s-50.235-22.511-50.235-50.181V50.182c0-13.835-11.268-25.091-25.118-25.091-13.85 0-25.118 11.256-25.118 25.09H25.962c0-27.67 22.536-50.18 50.236-50.18 27.7 0 50.235 22.51 50.235 50.18v75.274c0 13.835 11.268 25.09 25.118 25.09zm-125.589 0c0 55.34 45.071 100.363 100.471 100.363V276C57.183 276 .845 219.721.845 150.546h25.117zm247.5-129.13l-28.796 28.766 28.796 28.765L255.7 96.69l-28.796-28.765-28.796 28.765-17.761-17.742 28.796-28.765-28.796-28.766 17.761-17.741 28.796 28.765L255.7 3.675l17.762 17.741z"
          />
        </svg>
      </div>
      <section
        class="banner banner--case-study"
        style={{ color: "#2e2e2e", backgroundColor: "#9aa2a5" }}
      >
        <div class="banner__image">
          <img
            width="1800"
            height="1076"
            src={pimain}
            class="attachment-full size-full"
            alt="WA Good Food Guide Website Case Study"
            loading="eager"
            srcset={pimain}
          />{" "}
        </div>

        <div class="container">
          <div class="banner__caption">
            <h1 class="banner__title" data-waay="step-up">
              <span data-waay-step>
                <span class="banner__subtitle" style={{color:'white'}}>Prabhu industries </span>
              </span>{" "}
              <span data-waay-step style={{color:'white'}}>Building A Better Future One Industry At A Time.</span>
            </h1>
          </div>
        </div>
      </section>
      <main id="skip-to-content" class="content-main">
        <div class="section section--floating-cta">
          <div class="section__inner">
            <a
              href="#"
              target="_blank"
              rel="noopener"
              title="Visit the WA Good Food Guide website (New window)"
              class="block__link step-up btn btn--link-arrow"
              data-delay="3"
              style={{ backgroundColor: "#ffffff", color: "#000000" }}
            >
              <span class="btn__label" style={{ marginTop: "-4rem" }}>
              Visit the Prabhu industries
              </span>
            </a>
          </div>
        </div>

        <section
          class="case-study__introduction case-study__introduction--alt"
          style={{ backgroundColor: "#9aa2a5", color: "#ffffff" }}
        >
          <div class="container">
            <div class="case-study__introduction-inner">
              <div
                class="case-study__introduction-description"
                data-waay="step-up"
              >
                <div class="case-study__introduction-copy" data-waay-step>
                  <p>
                  PRABHU INDUSTRIES 40 Years of expertise Indian incorporated company, specializing in providing construction, fabrication and erection of WATER – OIL – AIR - STEAM facilities, for process industries. 
                  <br></br> <br></br>
                  PENTA - X ENGINEERS 25 Years of expertise Indian incorporated company, specialized in Turnkey Projects.
                  <br></br> <br></br>
                  STEAMMAX ENGINEERING SERVICES 10 Years of Product and services to Process Industries
                  </p>
                </div>
              </div>
              <div class="case-study__introduction-features">
                <ul data-waay="step-up">
                  <li data-waay-step>Digital Strategy</li>
                  <li data-waay-step>Editorial Focus</li>
                  <li data-waay-step>UX &amp; UI Design</li>
                  <li data-waay-step>Flexible Grid Layout</li>
                  <li data-waay-step>Intuitive Content Management</li>
                </ul>

              
              </div>
            </div>
          </div>
        </section>
        <section class="case-study__modular-content">
          <section
            class="case-study__block case-study__block--1 case-study__image case-study__image--full-width"
            style={{ backgroundColor: "9aa2a5" }}
          >
            <div class="block__image">
              <div class="block__image-spacer"></div>

              <img
                width="1800"
                height="1000"
                src={pilap}
                class="attachment-full size-full"
                alt="laptop showing wa good food guide homepage"
                loading="lazy"
                srcset={pilap}
                style={{marginTop:'2rem',width:'109%'}}
                sizes="(max-width: 1800px) 100vw, 1800px"
              />
            </div>
          </section>
       
          <section
            class="case-study__block case-study__block--3 case-study__wysiwyg"
            style={{ backgroundColor: "#ffffff", color: "#222222" }}
          >
            <div class="container container--sm" data-waay="step-up">
              <h2 class="block__title" data-waay-step>
                Designing for flexibility
              </h2>
              <div class="block__content" data-waay-step>
                <p>
                 User-friendly navigation, clear information hierarchy, and responsive layouts to accommodate various devices. The design should adapt to changing content needs and accommodate updates seamlessly. A modular design approach and a robust CMS can enhance flexibility, ensuring the site remains efficient and visually appealing. Accessibility features and user testing should also be integrated to guarantee usability for a diverse audience.
                </p>
              </div>
            </div>
          </section>
          <section
            class="case-study__block case-study__block--4 case-study__image"
            style={{ backgroundColor: "#ffffff" }}
          >
            <div class="container">
              <div class="block__image">
                <div class="block__image-spacer"></div>

                <img
                  width="1400"
                  height="1049"
                  src={pic}
                  class="attachment-full size-full"
                  alt="chef cards"
                  loading="lazy"
                  srcset={pic}
                  sizes="(max-width: 1400px) 100vw, 1400px"
                />
              </div>
            </div>
          </section>
          {/* <section
            class="case-study__block case-study__block--5 case-study__wysiwyg"
            style={{ color: "#222222" }}
          >
            <div class="container container--sm" data-waay="step-up">
              <h2 class="block__title" data-waay-step>
                Grid view vs Story view
              </h2>
              <div class="block__content" data-waay-step>
                <p>
                  For visitors wanting to compare venues quickly, we default to
                  the Grid View, offering an overview of restaurants, ratings,
                  price ranges, and locations.
                </p>
                <p>
                  For visitors chasing deeper engagement, the Story View, offers
                  a feel reminiscent of lifestyle magazines. It features bigger
                  visuals, more detail about the venue, and a taste of what
                  makes each establishment so special.
                </p>
              </div>
            </div>
          </section> */}
          <section
            class="case-study__block case-study__block--6 case-study__image case-study__image--full-width"
            style={{ backgroundColor: "#ffffff", color: "#222222" }}
          >
            <div class="block__image">
              <div class="block__image-spacer"></div>

              <img
                width="1800"
                height="1400"
                src={icontab}
                class="attachment-full size-full"
                alt="ipad and iphone devices showing top 50 restaurant listings"
                loading="lazy"
                srcset={icontab}
                sizes="(max-width: 1800px) 100vw, 1800px"
              />
            </div>
          </section>
        
          <section
            class="case-study__block case-study__block--8 case-study__aside-image  case-study__aside-image--flipped case-study__aside-image--sticky"
            style={{ backgroundColor: "#000000", color: "#ffffff" }}
          >
            <div class="container">
              {" "}
              <div class="block__inner">
                <div>
                  <div class="block__image">
                    <div class="block__image-spacer"></div>

                    <img
                      width="815"
                      height="3352"
                      src={pif}
                      class="attachment-full size-full"
                      alt="wa good food guide homepage screenshot"
                      loading="lazy"
                      srcset={pif}
                      sizes="(max-width: 815px) 100vw, 815px"
                    />
                  </div>
                </div>
                <div>
                  <div class="block__content block--sticky">
                    <div class="block__content-inner" data-waay="step-up">
                      <h2 class=" block__title" data-waay-step>
                        
                      </h2>

                      <div data-waay-step>
                        <p>
                        With a strong support of a qualified and experienced team of professionals, who make an endless endeavor to drive the organization to prosperity and success. The team working with us consists of knowledgeable technicians, engineers, designers and many more.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>{" "}
        </section>

        <div class="arrow-cursor">
          <svg
            class="arrow-cursor__icon"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="94"
            height="87"
            viewBox="0 0 94 87"
          >
            <path
              class="arrow-cursor__path"
              fill="none"
              fill-rule="evenodd"
              stroke="#000000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
              d="M54.177 83.487a3 3 0 0 1-4.244 0l-8.484-8.484a3 3 0 0 1 0-4.244l18.576-18.592H4.997a3 3 0 0 1-3-3v-12a3 3 0 0 1 3-3h55.028L41.45 15.607a3 3 0 0 1 0-4.244l8.484-8.484a3 3 0 0 1 4.244 0l36.064 36.06a6 6 0 0 1 0 8.488l-36.064 36.06z"
            />
          </svg>{" "}
        </div>

        <section
          class="case-study__footer case-study__footer--alt"
          style={{ backgroundColor: "#000000", color: "#ffffff" }}
        >
          <div class="container">
            <div class="block__inner">
              <div class="block__content" data-waay="step-up">
                <h2 data-waay-step>
                  
                </h2>

                <div class="block__description" data-waay-step>
                  <p>
                  PRABHU INDUSTRIES is consistent in terms of quality, reliable in performance and cost effective in operation, these three elements of our products have enabled us to strong market goodwill along with wide client base.
                  </p>
                </div>
              </div>

              <div class="block__link-container">
                <a
                  href="#"
                  target="_blank"
                  rel="noopener"
                  title="Visit the WA Good Food Guide website (New window)"
                  class="block__link step-up btn btn--link-arrow"
                  data-delay="3"
                  style={{ backgroundColor: "#000000", color: "#ffffff" }}
                >
                  <span class="btn__label">Visit the Prabhu industries</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>

      
    </div>
  );
}

export default transition(ProjectPra);
