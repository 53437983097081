import logo from './logo.svg';
import './App.css';
import Project from './components/Project/Project';
import Navbar from './components/Navbar/Navbar';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import About from './components/About/About';
import Landing from './components/Landing/Landing';
import Footer from './components/Footer/Footer';
import Contact from './components/Contact/Contact';
import FooterForm from './components/Footer/FooterForm';
import Home from './components/Home/Home';
import Nav from './components/Nav/Nav';
import Text from './Text';
import { AnimatePresence,motion } from 'framer-motion';
import { useEffect } from 'react';
import Lenis from '@studio-freight/lenis';
import Newland from './components/Newlanding/Newland';
import Loader from './Loader';
import { useState } from 'react';
import Erp from './components/NewErp/Erp';
import Projecthote from './components/ProjectDetails/Projecthote';
import ProjectPra from './components/ProjectDetails/ProjectPra';
import ProjectHotel from './components/ProjectDetails/ProjectHospital';
import ProjectHospital from './components/ProjectDetails/ProjectHospital';
import NewLoader from './components/Loader/NewLoader';
// import SmoothScroll from './SmoothScroll';
import ProjectGvk from './components/ProjectDetails/ProjectGvk';
import Projecthelica from './components/ProjectDetails/Projecthelica';
function App() {
  // useEffect(() => {
  //   const lenis = new Lenis();

  //   function raf(time) {
  //     lenis.raf(time);
  //     requestAnimationFrame(raf);
  //   }

  //   requestAnimationFrame(raf);

    
  //   const scrollToBottom = () => {
  //     window.scrollTo({
  //       top: document.body.scrollHeight,
  //       behavior: 'smooth', 
        
  //     });
  //   };

  //   setTimeout(scrollToBottom, 1000);
  // }, []);
  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    // After 2 seconds, hide the loader
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 2000);
  
    return () => {
      clearTimeout(timer);
    };
  }, []);
  
  return (
    <Router>
      <AnimatePresence mode='wait'>
      {showLoader && <NewLoader />} 
      {/* <NewLoader /> */}
      <motion.div
          // key={location.pathname}
          initial="pageInitial"
          animate="pageAnimate"
          exit="pageExit"
          // variants={{
          //   pageInitial: {
          //     opacity: 0,
          //   },
          //   pageAnimate: {
          //     opacity: 1,
          //   },
          //   pageExit: {
          //     opacity: 0,
          //   },
          // }}
        >
          
          <NavbarContainer />
          <Routes>
          <Route path="/home" element={<Home />} />
          <Route exact path="/" element={<Newland />} />
          <Route exact path="/erpcomingsoon" element={<Erp />} />
          <Route path="/about" element={<About />} />
          <Route exact path="/projects" element={<Project />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/contactform" element={<FooterForm />} />
          <Route path="/contact" element={<Contact />} />
          <Route exact path="/projects/projecthotel" element={<Projecthote />} />
          <Route exact path="/projects/ProjectPra" element={<ProjectPra />} />
          <Route exact path="/projects/ProjectHospital" element={<ProjectHospital />} />
          <Route exact path="/projects/ProjectGvk" element={<ProjectGvk />} />
          <Route exact path="/projects/Projecthelica" element={<Projecthelica />} />
          </Routes>
          <FooterContainer />
        </motion.div>
      </AnimatePresence>
    </Router>
  );
}

function NavbarContainer() {
  const location = useLocation();

  // Conditionally render Navbar except on the specified route
  if (location.pathname !== '/' && location.pathname !== '/erpcomingsoon') {
    return <Navbar />;
  }

  return null;
}

function FooterContainer() {
  const location = useLocation();

  // Conditionally render Footer
  if (
    location.pathname === '/about' ||
    location.pathname === '/' ||
    location.pathname === '/home' ||
    location.pathname === '/projects' ||
    location.pathname === '/contact' 
    || location.pathname === '/erpcomingsoon'
    // || location.pathname === '/projects/projecthotel'
    // || location.pathname === '/projects/ProjectPra'
    // || location.pathname === '/projects/ProjectHospital'
    // || location.pathname === '/projects/ProjectGvk'
    // || location.pathname === '/projects/Projecthelica'
  ) {
    return <Footer />;
  }

  return null;
}

export default App;
