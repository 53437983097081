import React, { useRef } from "react";
import "./Home.css";
import Footer from "../Footer/Footer";
import laptop from "../Assets/laptop.png";
import Capture from "../Assets/Capture.PNG";
import download from "../Assets/download.png";
import m from "../Assets/mm.avif";
import brb from "../Assets/brb.png";
import clpb from "../Assets/clpb.png";
import fav from "../Assets/favb.png";
import gvk from "../Assets/gvkb.png";
import Fade from "react-reveal/Fade";
import hob from "../Assets/hob.png";
import inb from "../Assets/inb.png";
import pib from "../Assets/pib.png";
import sanb from "../Assets/sanb.png";
import ssvb from "../Assets/ssvb.png";
import gvkv from "../Assets/gvv.mp4";
import i from "../Assets/i.png";
import g from "../Assets/g.png";
import gvk2 from "../Assets/gvk2.png"
import hotel from "../Assets/hotel.png"
import h from "../Assets/h.png";
import k from "../Assets/k.png";
import cc from "../Assets/cc.png";
import anime from "animejs"; // Import the anime.js library
import { TweenMax, Expo } from "gsap";
import { Link, useNavigate } from "react-router-dom";
import { motion as mo } from "framer-motion";
import { motion } from "framer-motion";
import "../Footer/Footer.css";
import pro2 from "../Assets/pro2.jpg";
import pro3 from "../Assets/pro3.png";
import pro4 from "../Assets/pro4.png";
import hotelp from "../Assets/hotelp.png";
import { useState, useEffect } from "react";
import gsap from "gsap";
import SplitText from "./Split3.min";
import hosp from "../Assets/hosp.png";
import pra from "../Assets/pra.png";
import Banner from "./Banner";
import transition from "../../transition";
import ma from "../Assets/m.png";
import pimain from "../Assets/pimain.png";
import Accordion from "./Accordian";
function Home() {
  const [opacity, setOpacity] = React.useState(0);

  const handleMouseEnter = () => {
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setOpacity(0);
  };

  const [selectedGrid, setSelectedGrid] = useState(null);

  const gridItemsData = [
    {
      id: 0,
      src: "https://plus.unsplash.com/premium_photo-1675063044882-522a7d281b2f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1976&q=80",
      alt: "Image 1",
    },
    {
      id: 1,
      src: "https://plus.unsplash.com/premium_photo-1675063044882-522a7d281b2f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1976&q=80",
      alt: "Image 2",
    },
    {
      id: 2,
      src: "https://plus.unsplash.com/premium_photo-1675063044882-522a7d281b2f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1976&q=80",
      alt: "Image 3",
    },
    {
      id: 3,
      src: "https://plus.unsplash.com/premium_photo-1675063044882-522a7d281b2f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1976&q=80",
      alt: "Image 4",
    },
  ];

  const handleGridClick = (gridIndex) => {
    setSelectedGrid(gridIndex);
  };

  const closeGrid = () => {
    setSelectedGrid(null);
  };
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setAnimate(true);
    }, 100);

    return () => clearTimeout(animationTimeout);
  }, []);
  const renderGridItems = () => {
    return gridItemsData.map((item) => (
      <div
        key={item.id}
        className={`grid-item ${selectedGrid === item.id ? "selected" : ""}`}
        onClick={() => handleGridClick(item.id)}
      >
        <img src={item.src} alt={item.alt} />
      </div>
    ));
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.4,
    };

    // IMAGE ANIMATION

    let revealCallback = (entries) => {
      entries.forEach((entry) => {
        let container = entry.target;

        if (entry.isIntersecting) {
          container.classList.add("animating");
          return;
        }

        if (entry.boundingClientRect.top > 0) {
          container.classList.remove("animating");
        }
      });
    };

    let revealObserver = new IntersectionObserver(revealCallback, options);

    document.querySelectorAll(".reveal").forEach((reveal) => {
      revealObserver.observe(reveal);
    });

    // TEXT ANIMATION

    let fadeupCallback = (entries) => {
      entries.forEach((entry) => {
        let container = entry.target;
        container.classList.add("not-fading-up");

        if (entry.isIntersecting) {
          container.classList.add("fading-up");
          return;
        }

        if (entry.boundingClientRect.top > 0) {
          container.classList.remove("fading-up");
        }
      });
    };

    let fadeupObserver = new IntersectionObserver(fadeupCallback, options);

    document.querySelectorAll(".fadeup").forEach((fadeup) => {
      fadeupObserver.observe(fadeup);
    });
  }, []);
  const scrollRef = React.useRef(null);

  // const [locomotiveRef] = useLocomotiveScroll({
  //   ref: scrollRef,
  //   smooth: true,
  //   smoothMobile: true,
  // });
  //

  useEffect(() => {
    const split = new SplitText("#header-text", {
      type: "lines",
      linesClass: "lineChildren",
    });

    const splitParent = new SplitText("#header-text", {
      type: "lines",
      linesClass: "lineParent",
    });

    gsap.to(split.lines, {
      duration: 1,
      y: 0,
      opacity: 1,
      stagger: 0.1,
      ease: "power2",
    });
  }, []);
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/about");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const anavigate = useNavigate();
  const ahandleButtonClick = () => {
    navigate("/projects");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const banner = {
    animate: {
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.1,
      },
    },
  };
  const letterAni = {
    initial: { y: 400 },
    animate: {
      y: 0,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1,
      },
    },
  };
  const AnimatedLetters = ({ title, disabled }) => (
    <motion.span
      className="row-title"
      variants={disabled ? null : banner}
      initial="initial"
      animate="animate"
    >
      {[...title].map((letter) => (
        <motion.span
          className="row-letter"
          variants={disabled ? null : letterAni}
        >
          {letter}
        </motion.span>
      ))}
    </motion.span>
  );
  const [openPanelIndex, setOpenPanelIndex] = useState(null);

  const togglePanel = (index) => {
    if (openPanelIndex === index) {
      setOpenPanelIndex(null);
    } else {
      setOpenPanelIndex(index);
    }
  };
  const bnavigate = useNavigate();
  const bhandleButtonClick = () => {
    bnavigate("/projects/projecthotel");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const phosnavigate = useNavigate();
  const phoshandleButtonClick = () => {
    bnavigate("/projects/ProjectHospital");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const pgnavigate = useNavigate();
  const pghandleButtonClick = () => {
    bnavigate("/projects/ProjectGvk");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const prnavigate = useNavigate();
  const prahandleButtonClick = () => {
    bnavigate("/projects/ProjectPra");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const phenavigate = useNavigate();
  const phehandleButtonClick = () => {
    bnavigate("/projects/Projecthelica");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //zoomed
  const [isZoomed, setIsZoomed] = useState(false);

  // Function to handle zooming the image
  const handleImageZoom = () => {
    if (!isZoomed) {
      TweenMax.to(".container", 2, {
        scale: "2",
        y: "90%",
        ease: Expo.easeInOut,
      });
      setIsZoomed(true);
    }
  };

  // Wrap every letter in a span using anime.js
  const text = "Reach The Fullest";
  const letters = text.split("");

  const letterSpans = letters.map((letter, index) => (
    <span key={index} className="letter">
      {letter}
    </span>
  ));

  const animateText = () => {
    const spanTexts = document.querySelectorAll(".span-text");
    spanTexts.forEach((spanText) => {
      const letters = spanText.textContent.split("");

      spanText.innerHTML = letters
        .map(
          (letter, index) =>
            `<span style="animation-delay: ${index * 0.1}s">${letter}</span>`
        )
        .join("");
    });
  };

  const [aanimate, setAanimate] = useState(false);
  const sectionRef = useRef(null);

  const aahandleScroll = () => {
    if (sectionRef.current) {
      const sectionTop = sectionRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (sectionTop < windowHeight * 0.75) {
        setAanimate(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", aahandleScroll);
    return () => {
      window.removeEventListener("scroll", aahandleScroll);
    };
  }, []);

  //
  const [abanimate, setAabnimate] = useState(false);
  const asectionRef = useRef(null);

  const abhandleScroll = () => {
    if (asectionRef.current) {
      const sectionTop = asectionRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (sectionTop < windowHeight * 0.75) {
        setAabnimate(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", abhandleScroll);
    return () => {
      window.removeEventListener("scroll", abhandleScroll);
    };
  }, []);
  const accordionData = [
    {
      title: "Digital Marketing",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s,",
    },
    {
      title: "Web Development",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s,",
    },
    {
      title: "UI & UX Design",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s,",
    },
  ];
  const imageUrls = [
    gvk,
    clpb,
    hob,
    pib,
    ssvb
    // Add more image URLs here
  ];
  ///xoom

  return (
    <>
      <mo.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.75, ease: "easeOut" }}
        className=""
      >
        {/* <Banner/> */}
        <div
          className="helicamainhome-container"
          //  data-scroll-container
          //  ref={scrollRef}
        >
          <section id="00">
            <section className="homemainani">
              <div class="sc-hLBbgP biGtBB">
                <div
                  direction="column"
                  class="sc-dkrFOg jjGFiK sc-kImNAt iFLIyJ"
                >
                  <motion.div
                    initial={{ opacity: 0, y: 80 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      ease: "easeInOut",
                      duration: 1,
                      delay: 0.4,
                    }}
                    className="row-col"
                  >
                    <div class="sc-iBYQkv dPjOtZ">
                      <div class="sc-ftTHYK kQjXcl">
                        {/* <span class="sc-eDvSVe jtaJGO sc-hOzowv ebVANy" > */}
                        <span
                          className={`sc-eDvSVe jtaJGO sc-hOzowv ebVANy rise-animation ${
                            animate ? "rise-animation" : ""
                          }`}
                        >
                          <span
                            className={`span-text intro-one ${
                              animate ? "rise-animation" : ""
                            }`}
                          >
                            One Stop
                          </span>{" "}
                        </span>
                      </div>
                    </div>
                  </motion.div>
                  <div class="sc-gswNZR bSqfQs sc-fvEvSO jScPpe"></div>
                  <div class="sc-dkrFOg dnXFIz sc-cZFQFd dhvaQG">
                    <div class="sc-dkrFOg fqWTSa sc-jTjUTQ eDiuCq"></div>
                    <div class="sc-fcCDlF">
                      <div class="sc-jRwbcX gZggJj">
                        <div class="sc-iBYQkv dPjOtZ">
                          <div class="sc-ftTHYK kQjXci">
                            <span class="sc-jSUZER TPnBF sc-dsHJmm fCBcmj">
                              (Since ’19)
                            </span>
                            <img src=""></img>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="sc-fcCDlF fLIQJI">
                      <div class="sc-jRwbcX dYQehN">
                        <div class="sc-iBYQkv dPjOtZ">
                          <div class="sc-ftTHYK irUQJo">
                            <span class="sc-eDvSVe jtaJGO sc-hOzowv gJcQLt">
                              <span
                                className={`span-text intro-one ${
                                  animate ? "rise-animation" : ""
                                }`}
                              >
                                Software solution
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="sc-jRwbcX gZggJj">
                      <div class="sc-iBYQkv dPjOtZ">
                        <div class="sc-ftTHYK kQjXci">
                          <div class="sc-eGugkK WkfHE"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="sc-gswNZR bSqfQs sc-fvEvSO jScPpe"></div>
                  <div class="sc-iBYQkv gwvfrW">
                    <div class="sc-ftTHYK cHNfGi">
                      <span class="sc-eDvSVe jtaJGO sc-hOzowv gJcQLt">
                        <span
                          className={`span-text intro-one ${
                            animate ? "rise-animation" : ""
                          }`}
                        >
                          {/* <span style={{opacity:'0'}} className="kaaa">  */}
                          with UI/UX
                          {/* </span> */}
                        </span>
                      </span>
                    </div>
                  </div>
                  <section class="content">
                    <div class="cover"></div>
                    <div class="row">
                      <div class="cell cell--text">
                        <span class="sc-eDvSVe jtaJGO sc-hOzowv ebVANy">
                          {/* Time-tested */}
                        </span>
                      </div>
                      <div class="cell cell--images">
                        <div class="cell__img">
                          <div
                            class="cell__img-inner"
                            style={{
                              backgroundImage: `url('https://images.unsplash.com/photo-1642184834911-61e5c38033c5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1046&q=80)`,
                            }}
                            opacity={opacity}
                          ></div>
                        </div>
                        <div class="cell__img">
                          <div
                            class="cell__img-inner"
                            style={{
                              backgroundImage: `url('https://images.unsplash.com/photo-1642184834911-61e5c38033c5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1046&q=80)`,
                            }}
                            opacity={opacity}
                          ></div>
                        </div>
                        <div class="cell__img">
                          <div
                            class="cell__img-inner"
                            style={{
                              backgroundImage: `url('https://images.unsplash.com/photo-1642184834911-61e5c38033c5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1046&q=80)`,
                            }}
                            opacity={opacity}
                          ></div>
                        </div>
                        <div class="cell__img">
                          <div
                            class="cell__img-inner"
                            style={{
                              backgroundImage: `url('https://images.unsplash.com/photo-1642184834911-61e5c38033c5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1046&q=80)`,
                            }}
                            opacity={opacity}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </section>
          <section className="hoesani">
            <section class="content">
              <div class="cover"></div>
              <div class="row">
                <div class="cell cell--text">
                  {/* <h2 class="cell__title oh"><span class="oh__inner">Nobody's Love</span></h2> */}

                  <span class="sc-eDvSVe jtaJGO sc-hOzowv ebVANy">
                    <span
                      className={`span-text intro-one ${
                        animate ? "rise-animation" : ""
                      }`}
                    >
                      One stop
                    </span>{" "}
                  </span>
                </div>
              </div>
              <div class="sc-gswNZR bSqfQs sc-fvEvSO jScPpe"></div>
            </section>

            <section class="content">
              <div class="cover"></div>
              <div class="row">
                <div class="cell cell--text">
                  {/* <h2 class="cell__title oh"><span class="oh__inner">Nobody's Love</span></h2> */}
                  <span class="sc-jSUZER TPnBF sc-dsHJmm fCBcmj">
                    (Since ’19)
                  </span>
                  <span class="sc-eDvSVe jtaJGO sc-hOzowv gJcQLt">
                    <span
                      className={`span-text intro-one ${
                        animate ? "rise-animation" : ""
                      }`}
                    >
                      Software Solution
                    </span>
                  </span>
                </div>
              </div>
              <div class="sc-gswNZR bSqfQs sc-fvEvSO jScPpe"></div>
            </section>
            <section class="content">
              <div class="cover"></div>
              <div class="row">
                <div class="cell cell--text">
                  {/* <h2 class="cell__title oh"><span class="oh__inner">Nobody's Love</span></h2> */}
                  <span class="sc-eDvSVe jtaJGO sc-hOzowv gJcQLt">
                    <span
                      className={`span-text intro-one ${
                        animate ? "rise-animation" : ""
                      }`}
                    >
                      <span
                        style={{ textTransform: "lowercase", opacity: "0" }}
                        className="kaaa"
                      >
                        {" "}
                      </span>
                      with UI/UX
                    </span>
                  </span>
                </div>
              </div>
            </section>
          </section>
          <section>
            <section className="section_process">
              <div className="padding-global">
                <div className="container-large _90vw">
                  <div className="padding-top padding-xhuge">
                    <div
                      class="sc-gswNZR bSqfQs homescrol"
                      style={{ marginTop: "15rem" }}
                    ></div>
                    <div className="w-layout-grid process-layout_component">
                      <div
                        className="process-layout_content-left"
                        style={{ top: "0" }}
                      >
                        <div className="margin-bottom margin-medium">
                          <div className="section-number-wrapper margin-bottom margin-medium">
                            <div className="section-name-wrapper">
                              <div
                                className="text-size-small text-colour-grey"
                                style={{ marginTop: "-5rem" }}
                              >
                                ↓ Scroll
                              </div>
                         
                            </div>
                          </div>
                          <div className="margin-bottom">
                            <h2
                              text-split=""
                              words-slide-from-right=""
                              className="heading-style-h2 _8vw-title"
                            ></h2>
                          </div>
                        </div>
                        <p
                          style={{ opacity: "1" }}
                          className="text-size-medium max-width-medium text-weight-light"
                        ></p>
                      </div>

                      <div className="process-layout_content-right">
                        <div className="margin-bottom margin-xsmall">
                          <div
                            style={{ opacity: "1" }}
                            className="icon-embed-medium w-embed home"
                          >
                            <p className="homeinrooop" style={{color:'black'}}>Intro</p>
                          </div>
                        </div>
                        <div className="margin-bottom margin-xsmall">
                          <h3
                            text-split=""
                            words-slide-from-right=""
                            className="heading-style-h5 text-style-allcaps text-weight-medium"
                          >
                            <br />
                          </h3>
                        </div>
                        <Fade bottom>
                          <p
                            style={{ opacity: "1" }}
                            className="text-size-regular"
                          >
                            <span style={{ fontWeight: "bold" }}>
                              {" "}
                              We are a small elite team
                            </span>
                            ,
                            <span
                              className={`span-text intro-one ${
                                animate ? "rise-animation" : ""
                              }`}
                            >
                              engaging in digital experiences and creating brand
                              identities
                              <p style={{ opacity: "0" }}>
                                {" "}
                                designers, developers, and strategists who build
                                memorable brands and engaging digital
                                experiences that help businesses succeed.
                              </p>
                            </span>
                            <br></br>
                          </p>
                        </Fade>
                        <div
                          class="sc-bCfvAP hBILsM"
                          onClick={handleButtonClick}
                        >
                          <a class="sc-fLcnxK kLLsQO">
                            <span class="sc-jSUZER TPnBF">
                              <div class="sc-bBABsx kvGHMY">
                                <span class="sc-jSUZER TPnBF">Know More </span>
                              </div>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>

        
          <section className="tiles" id="grid">
      <div className="tiles__wrap">
        <div
          className="tiles__line"
          data-scroll
          data-scroll-speed="1"
          data-scroll-target="#grid"
          data-scroll-direction="horizontal"
        >
          {imageUrls.map((imageUrl, index) => (
            <div
              className="tiles__line-img"
              key={index}
              style={{ backgroundImage: `url(${imageUrl})` }}
            ></div>
          ))}
        </div>
      </div>
    </section>
          <div class="sc-gswNZR bSqfQs"></div>

          <section className="hhm-p01">
            <div class="hel-mainp hPVNmo">
              <main>
                <p
                  style={{ textAlign: "center", color: "black" }}
                  className="hm-ourwork"
                >
                  Our Work
                </p>
                <section className="hm-pro01">
                  <div class="sc-hLBbgP biGtBB">
                    <div
                      direction="column"
                      class="sc-dkrFOg gPVkTy sc-elAWhN kxFcfd"
                    >
                      <div class="sc-dkrFOg ieMbRd">
                        <div class="sc-iBYQkv kKRCRY">
                          <div class="sc-ftTHYK kQjXco">
                            <div class="sc-dkrFOg dnXFIz" >
                              <h1
                                class="sc-eDvSVe jtaJGO"
                                
                              >
                                <span
                                  className="span-text intro-one "
                                    
                                >
                                  Projects
                                </span>
                              </h1>

                            
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="sc-cLNonn jyhgZP" style={{ cursor: "pointer" }}>
                      <div
                        direction="column"
                        class="sc-dkrFOg kgqXNr sc-iTFTee duQrUb fadeup"
                        onClick={bhandleButtonClick}
                      >
                        <div class="sc-iAEawV iLneeU">
                          <a class="sc-eeMvmM gEEGsZ">
                            <div className="laptop">
                              <img
                                src={ma}
                                className="img1"
                                class="sc-fnGiBr gYRMlI sc-cUEOzv iceamZ"
                              ></img>
                            </div>
                          </a>
                          <div class="sc-jOiSOi hrfGHw">
                            <div>
                              <div
                                class="sc-dkrFOg fTibjT sc-jcMfQk hQOsvn"
                                color="#F76567"
                              >
                                <div class="sc-dkrFOg fTibjT sc-cabOPr OUdfV">
                                  <svg
                                    width="11"
                                    height="14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.537 13.022A1 1 0 0 1 0 12.178V1.822A1 1 0 0 1 1.537.978l8.137 5.178a1 1 0 0 1 0 1.688l-8.137 5.178Z"
                                      fill="#fff"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <span class="sc-eDvSVe lhOvMH">
                        37th cresent
                          <br></br>
                          {/* Your Bangalore Oasis
                    <br></br>
                    The Perfect Getaway */}
                        </span>
                        <span class="sc-jSUZER TPnBF sc-dPWrhe jGWXwK">The Perfect Place to Relax and Rejuvenate</span>
                      </div>
                      <div
                        mobile="false"
                        class="sc-kMjNwy iwagXM"
                        style={{
                          position: "fixed",
                          top: "0",
                          bottom: "0",
                          left: "0",
                          right: "0",
                          zIndex: "9999999",
                          overflow: "hidden",
                          pointerEvents: "none",
                        }}
                      ></div>
                      <div
                        direction="column"
                        class="sc-dkrFOg kgqXNr sc-iTFTee hDkvno fadeup"
                        onClick={phehandleButtonClick}
                      >
                        <div class="sc-iAEawV iLneeU">
                          <a class="sc-eeMvmM gEEGsZ">
                            <div className="laptop">
                              <img
                                src={k}
                                class="sc-fnGiBr gYRMlI sc-cUEOzv iceamZ"
                              ></img>
                            </div>
                          </a>
                          <div class="sc-jOiSOi hrfGHw">
                            <div>
                              <div
                                class="sc-dkrFOg fTibjT sc-jcMfQk ehAIsa"
                                color="#F7F629"
                              >
                                <div class="sc-dkrFOg fTibjT sc-cabOPr hqFLYc">
                                  <svg
                                    width="11"
                                    height="14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.537 13.022A1 1 0 0 1 0 12.178V1.822A1 1 0 0 1 1.537.978l8.137 5.178a1 1 0 0 1 0 1.688l-8.137 5.178Z"
                                      fill="#fff"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <span class="sc-eDvSVe lhOvMH">
                        Helica Technologies
                         
                        </span>
                        <span class="sc-jSUZER TPnBF sc-dPWrhe jGWXwK"> The rudimentory process of business starts here.</span>
                      </div>
                      <div
                        mobile="false"
                        class="sc-kMjNwy iwagXM"
                        style={{
                          position: "fixed",
                          top: "0",
                          bottom: "0",
                          left: "0",
                          right: "0",
                          zIndex: "9999999",
                          overflow: "hidden",
                          pointerEvents: "none",
                        }}
                      ></div>
                      <div
                        direction="column"
                        class="sc-dkrFOg kgqXNr sc-iTFTee duQrUb fadeup"
                        onClick={pghandleButtonClick}
                      >
                        <div class="sc-iAEawV iLneeU">
                          <a class="sc-eeMvmM gEEGsZ">
                            <div className="laptop">
                              <img
                                src={gvk2}
                                className="img1"
                                class="sc-fnGiBr gYRMlI sc-cUEOzv iceamZ"
                              ></img>
                            </div>
                          </a>
                          <div class="sc-jOiSOi hrfGHw">
                            <div>
                              <div
                                class="sc-dkrFOg fTibjT sc-jcMfQk lnbWJV"
                                color="#5950FF"
                              >
                                <div class="sc-dkrFOg fTibjT sc-cabOPr kRkBdY">
                                  <svg
                                    width="11"
                                    height="14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.537 13.022A1 1 0 0 1 0 12.178V1.822A1 1 0 0 1 1.537.978l8.137 5.178a1 1 0 0 1 0 1.688l-8.137 5.178Z"
                                      fill="#fff"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <span class="sc-eDvSVe lhOvMH">
                        
                        Gvk Power & Engineering Solutions
                        </span>
                        <span class="sc-jSUZER TPnBF sc-dPWrhe jGWXwK">Industrial Electrical Distribution Solutions for a Better Tomorrow.</span>
                      </div>
                      <div
                        mobile="false"
                        class="sc-kMjNwy iwagXM"
                        style={{
                          position: "fixed",
                          top: "0",
                          bottom: "0",
                          left: "0",
                          right: "0",
                          zIndex: "9999999",
                          overflow: "hidden",
                          pointerEvents: "none",
                        }}
                      ></div>
                      <div
                        direction="column"
                        class="sc-dkrFOg kgqXNr sc-iTFTee hDkvno fadeup"
                        onClick={prahandleButtonClick}
                      >
                        <div class="sc-iAEawV iLneeU">
                          <a class="sc-eeMvmM gEEGsZ">
                            <img
                              src={pimain}
                              width="100%"
                              height="100%"
                              class="sc-fnGiBr gYRMlI sc-cUEOzv iceamZ"
                            />
                          </a>
                          <div class="sc-jOiSOi hrfGHw">
                            <div>
                              <div
                                class="sc-dkrFOg fTibjT sc-jcMfQk aWySW"
                                color="#000938"
                              >
                                <div class="sc-dkrFOg fTibjT sc-cabOPr bWcGnu">
                                  <svg
                                    width="11"
                                    height="14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.537 13.022A1 1 0 0 1 0 12.178V1.822A1 1 0 0 1 1.537.978l8.137 5.178a1 1 0 0 1 0 1.688l-8.137 5.178Z"
                                      fill="#fff"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <span class="sc-eDvSVe lhOvMH">
                       
                          Prabhu Industries
                          <br></br>
                          {/* Industrial Solutions for a Better Tomorrow. */}
                        </span>
                        <span class="sc-jSUZER TPnBF sc-dPWrhe jGWXwK">   Building a Better Future one industry at a time.</span>
                      </div>
                      <div
                        mobile="false"
                        class="sc-kMjNwy iwagXM"
                        style={{
                          position: "fixed",
                          top: "0",
                          bottom: "0",
                          left: "0",
                          right: "0",
                          zIndex: "9999999",
                          overflow: "hidden",
                          pointerEvents: "none",
                        }}
                      ></div>
                    </div>
                
                    <div
                      className="hm-01center-container"
                      style={{ marginTop: "rem" }}
                    >
                      <div
                        class="sc-bCfvAP hBILsM hm-01centered-button"
                        onClick={ahandleButtonClick}
                      >
                        <a class="sc-fLcnxK kLLsQO">
                          <span class="sc-jSUZER TPnBF">
                            <div class="sc-bBABsx kvGHMY">
                              <span class="sc-jSUZER TPnBF">View project</span>
                            </div>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </div>
          </section>
        

          <section id="03">
           <Accordion/>
          </section>
        </div>
      </mo.div>
    </>
  );
}

export default transition(Home);
