import React from "react";
import "./projectdetails.css";
import hotelp from "../Assets/hotelp.png";
import food from "../Assets/food.jpg"
import foo1 from "../Assets/food2.jpg"
import bur1 from "../Assets/bur1.jpg"
import bur2 from "../Assets/bur2.jpg"
import phm from "../Assets/phm.png"
import fullh from "../Assets/fullh.png"
import m from "../Assets/m.png"
import all from "../Assets/all.png";
import gvktab from "../Assets/gvktab.png"
import Fade from 'react-reveal/Fade';
import gvkfull from "../Assets/gvkfull.png"
import transition from "../../transition";
import gvklaptop from "../Assets/gvklaptop.png"
import gvkcl from "../Assets/gvkcl.png"
function ProjectGvk() {
  const images = [
    {
      id: 1,
      src: "https://images.unsplash.com/photo-1543007630-9710e4a00a20?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1935&q=80",
      alt: "Image 1",
    },
    {
      id: 2,
      src: "https://images.unsplash.com/photo-1543007630-9710e4a00a20?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1935&q=80",
      alt: "Image 2",
    },

    // Add more images as needed
  ];
  return (
    <div className="projectdetailgvk">
      <div class="header-aside-trigger"></div>
      <div class="header-aside">
        <svg
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="274"
          height="276"
          viewBox="0 0 274 276"
        >
          <path
            fill="#E2D1FF"
            fill-rule="evenodd"
            d="M151.551 150.546c13.85 0 25.118-11.256 25.118-25.091h25.117c0 27.67-22.535 50.181-50.235 50.181s-50.235-22.511-50.235-50.181V50.182c0-13.835-11.268-25.091-25.118-25.091-13.85 0-25.118 11.256-25.118 25.09H25.962c0-27.67 22.536-50.18 50.236-50.18 27.7 0 50.235 22.51 50.235 50.18v75.274c0 13.835 11.268 25.09 25.118 25.09zm-125.589 0c0 55.34 45.071 100.363 100.471 100.363V276C57.183 276 .845 219.721.845 150.546h25.117zm247.5-129.13l-28.796 28.766 28.796 28.765L255.7 96.69l-28.796-28.765-28.796 28.765-17.761-17.742 28.796-28.765-28.796-28.766 17.761-17.741 28.796 28.765L255.7 3.675l17.762 17.741z"
          />
        </svg>
      </div>
      <section
        class="banner banner--case-study"
        style={{ color: "#2e2e2e", backgroundColor: "#9aa2a5" }}
      >
        <div class="banner__image">
          <img
            width="1800"
            height="1076"
            src="https://plus.unsplash.com/premium_photo-1678841804212-89327cc9fb5d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80"
            class="attachment-full size-full"
            alt="WA Good Food Guide Website Case Study"
            loading="eager"
            srcset="https://plus.unsplash.com/premium_photo-1678841804212-89327cc9fb5d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80"
          />{" "}
        </div>

        <div class="container">
          <div class="banner__caption">
            <h1 class="banner__title" data-waay="step-up">
              <span data-waay-step>
                <span class="banner__subtitle">
                <Fade bottom>
                <span style={{ fontFamily: "MaisonNeue, HelveticaNeue-Light, 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif" ,textTransform:'capitalize'}}>
                Gvk power & engineering solutions
                  </span>
                  </Fade></span>
                  
              </span>{" "}
              <span data-waay-step>
         

              <Fade bottom ><span style={{ fontFamily: "MaisonNeue, HelveticaNeue-Light, 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif" ,textTransform:'initial'}}>Industrial Electrical Distribution Solutions for a Better Tomorrow.</span> </Fade></span>
            </h1>
          </div>
        </div>
      </section>
      <main id="skip-to-content" class="content-main">
        <div class="section section--floating-cta">
          <div class="section__inner">
            <a
              href="#"
              target="_blank"
              rel="noopener"
              title="Visit the WA Good Food Guide website (New window)"
              class="block__link step-up btn btn--link-arrow"
              data-delay="3"
              style={{ backgroundColor: "#ffffff", color: "#000000" }}
            >
              <span class="btn__label" style={{ marginTop: "-4rem" }}>
                Visit the Gvk 
              </span>
            </a>
          </div>
        </div>

        <section
          class="case-study__introduction case-study__introduction--alt"
          style={{ backgroundColor: "#9aa2a5", color: "#ffffff" }}
        >
          <div class="container">
            <div class="case-study__introduction-inner">
              <div
                class="case-study__introduction-description"
                data-waay="step-up"
              >
                <div class="case-study__introduction-copy" data-waay-step>
                  <p>
                  After completing a comprehensive rebranding effort, Gvk power & engineering solutions, a prominent Bangalorean panel board manufacturer, embarked on a quest for a digital companion to elevate their online visibility.
                  Collaboratively, we envisioned a pioneering digital presence that would authentically convey their brand identity, vision, and core values while providing an unparalleled user experience.
                  <br></br>
                  Our joint efforts aimed to create an innovative online representation that accurately mirrored the essence of Gvk power & engineering solutions, showcasing their commitment to quality and design excellence.
                  </p>
                </div>
              </div>
              <div class="case-study__introduction-features">
                <ul data-waay="step-up">
                  <li data-waay-step>Digital Strategy</li>
                  <li data-waay-step>Editorial Focus</li>
                  <li data-waay-step>UX &amp; UI Design</li>
                  <li data-waay-step>Flexible Grid Layout</li>
                  <li data-waay-step>Intuitive Content Management</li>
                </ul>

             
              </div>
            </div>
          </div>
        </section>
        <section class="case-study__modular-content">
          <section
            class="case-study__block case-study__block--1 case-study__image case-study__image--full-width"
            style={{ backgroundColor: "9aa2a5" }}
          >
            <div class="block__image">
              <div class="block__image-spacer"></div>

              <img
                width="1800"
                height="1000"
                src={gvklaptop}
                class="attachment-full size-full"
                alt="laptop showing wa good food guide homepage"
                loading="lazy"
                srcset={gvklaptop}
                sizes="(max-width: 1800px) 100vw, 1800px"
              />
            </div>
          </section>
       
          <section
            class="case-study__block case-study__block--3 case-study__wysiwyg"
            style={{ backgroundColor: "#ffffff", color: "#222222" }}
          >
            <div class="container container--sm" data-waay="step-up">
              <h2 class="block__title" data-waay-step>
                Designing for flexibility
              </h2>
              <div class="block__content" data-waay-step>
                <p>
                Given their extensive history and unwavering commitment to their craft, the new website needed to be more than a showcase of their exquisite products; it had to embody the very essence of the company.In collaboration, we delved into creating an online presence that would not just present users with a static display of products but immerse them in the company's ethos and heritage.
                <br>
                </br>
                The end result of our efforts is a website divided into three distinct components: Products, Brand, and World
                </p>
              </div>
            </div>
          </section>
          <section
            class="case-study__block case-study__block--4 case-study__image"
            style={{ backgroundColor: "#ffffff" }}
          >
            <div class="container">
              <div class="block__image">
                <div class="block__image-spacer"></div>

                <img
                  width="1400"
                  height="1049"
                  src={gvkcl}
                  class="attachment-full size-full"
                  alt="chef cards"
                  loading="lazy"
                  srcset={gvkcl}
                  sizes="(max-width: 1400px) 100vw, 1400px"
                />
              </div>
            </div>
          </section>
          <section
            class="case-study__block case-study__block--5 case-study__wysiwyg"
            style={{ color: "#222222" }}
          >
            <div class="container container--sm" data-waay="step-up">
              <h6 class="block__title" data-waay-step>
              A product experience that not only looks stunning but also operates flawlessly, ensuring a delightful user experience.
              </h6>
              <div class="block__content" data-waay-step>
                <p>
                At the heart of the website's success, it was imperative to design a product experience that could match the immersive nature of a physical showroom. While the Collections section would narrate distinct stories for the origins of each product series, the actual exploration of individual products needed to be straightforward, instinctive, and aesthetically pleasing. Drawing inspiration from comprehensive customer research and a range of prototype iterations, we developed a platform that not only breathed life into the products within a 3D virtual space within the web browser but also empowered users to visualize those products in real-time across the various available finishes.
                </p>
             
              </div>
            </div>
          </section>
          <section
            class="case-study__block case-study__block--6 case-study__image case-study__image--full-width"
            style={{ backgroundColor: "#ffffff", color: "#222222" }}
          >
            <div class="block__image">
              <div class="block__image-spacer"></div>

              <img
                width="1800"
                height="1400"
                src={gvktab}
                class="attachment-full size-full"
                alt="ipad and iphone devices showing top 50 restaurant listings"
                loading="lazy"
                srcset={gvktab}
                sizes="(max-width: 1800px) 100vw, 1800px"
              />
            </div>
          </section>
        
          <section
            class="case-study__block case-study__block--8 case-study__aside-image  case-study__aside-image--flipped case-study__aside-image--sticky"
            style={{ backgroundColor: "#000000", color: "#ffffff" }}
          >
            <div class="container">
              {" "}
              <div class="block__inner">
                <div>
                  <div class="block__image">
                    <div class="block__image-spacer"></div>

                    <img
                      width="815"
                      height="3352"
                      src={gvkfull}
                      class="attachment-full size-full"
                      alt="wa good food guide homepage screenshot"
                      loading="lazy"
                      srcset={gvkfull}
                      sizes="(max-width: 815px) 100vw, 815px"
                    />
                  </div>
                </div>
                <div>
                  <div class="block__content block--sticky">
                    <div class="block__content-inner" data-waay="step-up">
                      <h2 class=" block__title" data-waay-step>
                      A precise and thorough articulation of the brand's identity and values
                      </h2>

                      <div data-waay-step>
                        <p>
                        With a well-defined brand commitment and a wealth of meaningful content, every individual page of the Sussex narrative was meticulously crafted and developed to mirror and bolster the content it housed. Be it the topics of sustainability, the company's mission, or the people behind it, every facet was meticulously examined to fully engross the user in the company's essence and vision. These pages were designed to offer a profound journey into the very core of the brand's DNA, ensuring that the user could delve deeply into the comprehensive exploration of Sussex's identity and values
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>{" "}
        </section>

        <div class="arrow-cursor">
          <svg
            class="arrow-cursor__icon"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="94"
            height="87"
            viewBox="0 0 94 87"
          >
            <path
              class="arrow-cursor__path"
              fill="none"
              fill-rule="evenodd"
              stroke="#000000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
              d="M54.177 83.487a3 3 0 0 1-4.244 0l-8.484-8.484a3 3 0 0 1 0-4.244l18.576-18.592H4.997a3 3 0 0 1-3-3v-12a3 3 0 0 1 3-3h55.028L41.45 15.607a3 3 0 0 1 0-4.244l8.484-8.484a3 3 0 0 1 4.244 0l36.064 36.06a6 6 0 0 1 0 8.488l-36.064 36.06z"
            />
          </svg>{" "}
        </div>

        <section
          class="case-study__footer case-study__footer--alt"
          style={{ backgroundColor: "#000000", color: "#ffffff" }}
        >
          <div class="container">
            <div class="block__inner">
              <div class="block__content" data-waay="step-up">
                <h2 data-waay-step>
                Making it Right
                </h2>

                <div class="block__description" data-waay-step>
                  <p>
                  The statement emphasizes that the new GVK website establishes a remarkable standard, providing an experience that authentically embodies the brand's essence. It intentionally mirrors the exceptional quality of the products and services while also showcasing the brand's extensive history. Every aspect of the website takes a distinctive approach, actively involving the audience with interactive elements, and pushing the boundaries of what can be achieved in the realm of a virtual showroom and digital brand presence.
                  </p>
                </div>
              </div>

              <div class="block__link-container">
                <a
                  href="#"
                  target="_blank"
                  rel="noopener"
                  title="Visit the WA Good Food Guide website (New window)"
                  class="block__link step-up btn btn--link-arrow"
                  data-delay="3"
                  style={{ backgroundColor: "#000000", color: "#ffffff" }}
                >
                  <span class="btn__label">Visit the GVK Power and engineering solutions</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>

    </div>
  );
}

export default transition(ProjectGvk);
