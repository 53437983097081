import React from "react";
import "./landing.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Assets/loo.png"
import transition from "../../transition";
function Landing() {
  const navigate = useNavigate();
  const handleButtonClick = () => {
   
    navigate("/home");
  };
  return (
    <div className="ht-mlando1">
      <div className="ScaleElement_wrapper__EsFga">
        <div className="ScaleElement_titleWrapper__KkyZl">
          <h3 className="ScaleElement_title__IbSz1">
      
            Unlocking your potential, together.
          </h3>
        </div>
        <div className="ScaleElement_images__dCVcb"  style={{cursor:'pointer'}}>
          <div className="ScaleElement_firstImageWrapper__iexTS" onClick={handleButtonClick}>
            <div className="ScaleElement_firstImage__USLLE ScaleElement_animate__szW0K">
              <div className="Image_wrapper__vXw8I">
              <p className="ScaleElement_caption__ofkVO">
               Design & Development
              </p>
              <img
                  src="https://images.unsplash.com/photo-1519211975560-4ca611f5a72a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
                  alt="G&amp;S&amp;-The-CubeHouse5"
                  width="1395"
                  height="1395"
                  loading="lazy"
                />
              
              </div>
            
            </div>
          </div>
          <div className="ScaleElement_secondImageWrapper__0Op_G"  style={{cursor:'pointer'}}>
            <div className="ScaleElement_secondImage__qvRKd">
              <div className="Image_wrapper__vXw8I">
              <p className="ScaleElement_caption__ofkVO">
              Enterprise resource planning(ERP)
              </p>
              
                  <img
                  src="https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
                  alt="ns trein"
                  width="1395"
                  height="1395"
                  loading="lazy"
                />
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default transition(Landing);
