export const Data = [
    {
      question: ' Website design & development',
      answer: 'Continually evolving as technology advances and design trends change. Staying up-to-date with the latest innovations is crucial for web designers and developers to create engaging, user-friendly, and visually appealing websites.'
    },
    {
      question: ' Digital marketing & Growth strategy ',
      answer: 'Digital marketing involves in promoting the website through SEO, SEM, social media, and content marketing. Growth strategy focuses on continuous improvement, analytics-driven optimizations, and expanding the online presence to achieve long-term business objectives and audience engagement.'
    },
    {
      question: ' Brand Identity',
      answer: 'Brand identity that aligns with their values, communicates their oneness, and resonates with their target followership and anticipate visually charming and stoner-friendly interface that enhances the overall stoner experience. And also look for designs that reflect their brands personality and maintain thickness across all touchpoints, fostering brand recognition and trust. Also, we acclimatize to arising design trends and technologies, icing their brand remains applicable and competitive in the digital geography.'
    },
    {
      question: ' UI/UX Design',
      answer: 'Remember that creativity in web design should always serve a purpose and enhance the user experience. Balancing creativity with usability is key to creating successful and memorable websites.'
    }
  ];
  