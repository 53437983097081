import React from 'react';
import "./About.css";

import hotel from "../Assets/hotel.png";
import fav from "../Assets/fav.png";
import gvk from "../Assets/gvk.png";
import icon from "../Assets/icon.png";
import ssv from "../Assets/ssv.png";
import ind from "../Assets/ind.png"
import bri from "../Assets/bri.png"
import { useState,useEffect, useRef } from 'react';
import Fade from 'react-reveal/Fade';
import i from "../Assets/i.png"
import l from "../Assets/l.png"
import d from "../Assets/d.png"
import c from "../Assets/c.png"
import ImageGallery from './ImageGallery';
import off from "../Assets/off.png";
import office from "../Assets/office.png"
import Banner from '../Home/Banner';
import transition from '../../transition';
import adds from "../Assets/adds.svg";
import dev from "../Assets/dev.svg";
import design from "../Assets/design.svg";
import sm from "../Assets/sm.svg";
import {motion, useInView, useAnimation} from "framer-motion"
import { inView } from 'motion';
function About() {
    const logos = [
        { id: 1, name: 'Logo 1', image: hotel },
        { id: 2, name: 'Logo 2', image: fav },
        { id: 3, name: 'Logo 3', image: gvk },
        { id: 3, name: 'Logo 3', image: icon},
        { id: 3, name: 'Logo 3', image: ssv },
        { id: 3, name: 'Logo 3', image: 'https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=869&q=80' },
        
      ];
      const [isActive, setIsActive] = useState(true);

  const handleClick = () => {
    setIsActive(!isActive);
    
  };
  const [aisActive, setIsaActive] = useState(false);

  const ahandleClick = () => {
    setIsaActive(!aisActive);
  };
  const [bisActive, setIsbActive] = useState(false);

  const bhandleClick = () => {
    setIsbActive(!bisActive);
  };
  const [cisActive, setIscActive] = useState(false);

  const chandleClick = () => {
    setIscActive(!cisActive);
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const paragraphRef = useRef(null);

  useEffect(() => {
    const paragraph = paragraphRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          paragraph.classList.add('active');
        }
      });
    });

    observer.observe(paragraph);

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);
  const h2Ref = useRef(null);

  useEffect(() => {
    const h2Element = h2Ref.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Split text into letters and add "letter-slide" class
          const text = h2Element.innerText;
          h2Element.innerHTML = text
            .split('')
            .map((char, index) => `<span class="letter-slide">${char}</span>`)
            .join('');

          // Add "active" class to trigger the animations
          const letters = h2Element.querySelectorAll('.letter-slide');
          letters.forEach((letter, index) => {
            setTimeout(() => {
              letter.classList.add('active');
            }, index * 100); // Adjust the delay as needed
          });
        }
      });
    });

    observer.observe(h2Element);

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  //our process
  const ourRef = useRef(null);

  useEffect(() => {
    const h2Element = ourRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Split text into letters and add "letter-slide" class
          const text = h2Element.innerText;
          h2Element.innerHTML = text
            .split('')
            .map((char, index) => `<span class="letter-slide">${char}</span>`)
            .join('');

          // Add "active" class to trigger the animations
          const letters = h2Element.querySelectorAll('.letter-slide');
          letters.forEach((letter, index) => {
            setTimeout(() => {
              letter.classList.add('active');
            }, index * 100); // Adjust the delay as needed
          });
        }
      });
    });

    observer.observe(h2Element);

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  
  //description
  const uRef = useRef(null);

  useEffect(() => {
    const h2Element = uRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Split text into letters and add "letter-slide" class
          const text = h2Element.innerText;
          h2Element.innerHTML = text
            .split('')
            .map((char, index) => `<span class="letter-slide">${char}</span>`)
            .join('');

          // Add "active" class to trigger the animations
          const letters = h2Element.querySelectorAll('.letter-slide');
          letters.forEach((letter, index) => {
            setTimeout(() => {
              letter.classList.add('active');
            }, index * 100); // Adjust the delay as needed
          });
        }
      });
    });

    observer.observe(h2Element);

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);
  
  const textRef = useRef(null);
  useEffect(() => {
    const textElement = textRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          textElement.classList.add('active');
        }
      });
    });

    observer.observe(textElement);

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);
  

  const rref = useRef(null);
  const isInView = useInView(rref);

  const mainControls = useAnimation();
  useEffect(()=>{
    if(inView){
        //fire animation
        mainControls.start("visible")
    }
  },)

  //as newland
  const [animate, setAnimate] = useState(false);
  const sectionRef = useRef(null);

  const handleScroll = () => {
    if (sectionRef.current) {
      const sectionTop = sectionRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (sectionTop < windowHeight * 0.75) {
        setAnimate(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (animate) {
      animateText();
    }
  }, [animate]);

  const animateText = () => {
    const spanTexts = document.querySelectorAll(".span-text");
    spanTexts.forEach((spanText) => {
      const letters = spanText.textContent.split("");

      spanText.innerHTML = letters
        .map(
          (letter, index) =>
            `<span style="animation-delay: ${index * 0.1}s">${letter}</span>`
        )
        .join("");
    });
  };

  return (
      <>
      
    <div className='mainaboutbody'>
<header className="section_hero-about">
            <div className="padding-global">
                <div className="container-large _90vw">
                    <div className="header_content-copy">
                        <div className="text-align-left">
                            <div className="max-width-full margin-bottom margin-large">
                                <div className="margin-bottom margin-small z overflow-hidden">
                                    <Banner/>
                                    {/* <h1 text-split="" letters-slide-down="" className="heading-style-h2 about-title">About</h1> */}
                                </div>
                                <div className="about-hero-info-wrapper margin-top margin-large max-width-custom">
                                <p
        ref={paragraphRef}
        className="text-size-medium max-width-xxsmall text-style-allcaps text-colour-grey text-weight-medium responsive-full-width margin-right margin-large rise-up"
      >
        {/* We are passionate about helping brands create meaningful connections with their customers through digital experiences. */}
        Connecting the bridges between digital experiences and branding since 2019.
      </p>


                                    
                            
                                    
                                </div>
                            </div>
                            <div  className="white-line not-visible-mobile about-line" style={{opacity:'0'}}></div>
                        </div>
                    </div>
                    <div className="about-image-wrapper">
    <img 
    // src={aa}
    src={off}
      alt="kitchen design" className="about-page-image parallax-image" />
      <img 
    //   src={resa}
    src={office}
       className='about-page-resimage'></img>
   
  </div>
  <img className="top-right-image" src={office} alt="Second Image"/>
                </div>
            </div>
        </header>
        <section className="section_about">
            <div className="padding-global">
                <div className="container-large _100--vw">
                    <div className="padding-section-large no-padding-bottom">
                        <div className="margin-vertical margin-xxlarge less-margin-bottom">
                            <div className="text-align-center">
                                <div className="max-width-full text-align-left vertical-align">
                                    <div className="margin-top">
                                        <div  style={{opacity:'1'}} className="section-number-wrapper margin-bottom margin-medium">
                                          
                                            
                                        </div>
                                        <div className="margin-bottom" ref={sectionRef}>
      <h2 text-split="" scrub-each-word="" className="heading-style-h2 _8vw-title">
        <span className={`span-text intro-one ${animate ? "rise-animation" : ""}`} style={{textTransform:'capitalize'}}>
            </span>
            
            <h2 ref={uRef} text-split="" words-slide-from-right="" className="heading-style-h2 _8vw-title" style={{textTransform:'capitalize'}}>Clientele</h2>
      </h2>
                                        </div>
                                        <div className="client-logo-slider">
      {/* <div className="logo-container"> */}
       <ImageGallery/>
      {/* </div> */}
      
    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
        <div className="section_services">
            <div className="padding-global">
                <div className="container-large _100--vw">
                    <div className="padding-top padding-xhuge">
                        <div className="margin-bottom margin-xxlarge">
                            <div className="text-align-center">
                                <div className="max-width-full text-align-left horizontal-align">
                                    <div className="margin-bottom">
                                        <div  className="section-number-wrapper margin-bottom margin-medium">
                                            {/* <div className="number-circle">
                                                <div className="text-color-black text-size-regular font-satoshi">2</div>
                                            </div> */}
                                            <div className="section-name-wrapper">
                                                <div className="text-size-small text-colour-grey">Services</div>
                                            </div>
                                        </div>
                                        <div className="margin-bottom">
                                            <h2 ref={h2Ref} text-split="" words-slide-from-right="" className="heading-style-h2 _8vw-title">What we do</h2>
                                            
                                        </div>
                                    </div>
                              
                                    <div className='whatdo' ref={rref}>
                                    <motion.div 
                                    variants={{
                                        hidden:{opacity:0,y:75},
                                        visible:{opacity:1,y:0}
                                    }}
                                    initial="hidden"
                                    animate={mainControls}
                                    transition={{duration:1.5, delay:0.25}}
                                    >
                                    <p  className="text-size-medium max-width-medium text-weight-light" ref={textRef}>
                                    Aimed at creating exceptional digital experiences for our clients. With a deep understanding of user-centric design principles, we specialize in crafting intuitive and visually appealing interfaces that captivate users and drive engagement.
                                    </p>
                                    </motion.div>
                                    </div>
                                 
                                </div>
                            </div>
                        </div>
                        <div  className="services-layout_component " >
                            
                            <div className="vertical-text-styling w-embed">
                             
                            </div>
                            <div
            className={`services-layout_accordion ${activeIndex === 0 ? 'active' : ''}`}
            onClick={() => handleAccordionClick(0)}
          >
                                <div className="services-layout_column">
                                    <div className="services-layout_column-text">01</div>
                                    <h3 className="layout351_column-vertical-text">Web Design & Development</h3>
                                    <h3 className="layout351_column-horiztonal-text">Web Design & Development</h3>
                                </div>
                                <div className="layout351_column-content">
                                    <div className="layout351_content-wrapper">
                                        <div className="services-content-wrapper">
                                            <div className="margin-right margin-huge responsive-max-width">
                                                <h3 className="heading-style-h3 text-style-allcaps margin-bottom margin-medium mobile-title-8vw hide">Lorem ipsum dolor sit</h3>
                                                <p className="text-size-medium text-weight-light max-width-large">Continually evolving as technology advances and design trends change. Staying up-to-date with the latest innovations is crucial for web designers and developers to create engaging, user-friendly, and visually appealing websites.<br></br>
                                               </p>
                                                <div className="button-group margin-top margin-large">
                                                    <a  href="/" className="button is-small is-icon is-alternate w-inline-block">
                                                        <div>Get In Touch</div>
                                                        <div className="button-icon">
                                                            <div className="icon-embed-xsmall w-embed"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="iconify iconify--ic" width="100%" height="100%" preserveAspectRatio="xMidYMid meet"
                                                                    viewBox="0 0 24 24"><path fill="currentColor" d="M16.01 11H4v2h12.01v3L20 12l-3.99-4v3z"></path></svg></div>
                                                            <div className="icon-embed-xsmall w-embed"><svg xmlns="http://www.w3.org/2000/svg"  aria-hidden="true" role="img" className="iconify iconify--ic" width="100%" height="100%" preserveAspectRatio="xMidYMid meet"
                                                                    viewBox="0 0 24 24"><path fill="currentColor" d="M16.01 11H4v2h12.01v3L20 12l-3.99-4v3z"></path></svg></div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="margin-top">
                                                <div className="layout351_image-wrapper"><img src={design} loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 991px) 80vw, 30vw" srcSet={design}
                                                        alt="Bucătărie elegantă kashmir" className="layout351_image" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
            className={`services-layout_accordion ${activeIndex === 1 ? 'active' : ''}`}
            onClick={() => handleAccordionClick(1)}
          >
                                <div className="services-layout_column">
                                    <div className="services-layout_column-text">02</div>
                                    <h3 className="layout351_column-vertical-text">Digital Marketing &Growth Strategy </h3>
                                    <h3 className="layout351_column-horiztonal-text">Digital Marketing &Growth Strategy </h3>
                                </div>
                                <div className="layout351_column-content">
                                    <div className="layout351_content-wrapper">
                                        <div className="services-content-wrapper">
                                            <div className="margin-right margin-huge responsive-max-width">
                                                <h3 className="heading-style-h3 text-style-allcaps margin-bottom margin-medium mobile-title-8vw hide">Lorem ipsum dolor sit</h3>
                                                <p className="text-size-medium text-weight-light max-width-large">Digital marketing involves in promoting the website through SEO, SEM, social media, and content marketing. Growth strategy focuses on continuous improvement, analytics-driven optimizations, and expanding the online presence to achieve long-term business objectives and audience engagement.
                                                <br></br>
                                              </p>
                                                <div className="button-group margin-top margin-large absolute-button">
                                                    <a  href="/" className="button is-small is-icon is-alternate w-inline-block">
                                                        <div>Get In Touch</div>
                                                        <div className="button-icon">
                                                            <div className="icon-embed-xsmall w-embed"><svg xmlns="http://www.w3.org/2000/svg"  aria-hidden="true" role="img" className="iconify iconify--ic" width="100%" height="100%" preserveAspectRatio="xMidYMid meet"
                                                                    viewBox="0 0 24 24"><path fill="currentColor" d="M16.01 11H4v2h12.01v3L20 12l-3.99-4v3z"></path></svg></div>
                                                            <div className="icon-embed-xsmall w-embed"><svg xmlns="http://www.w3.org/2000/svg"  aria-hidden="true" role="img" className="iconify iconify--ic" width="100%" height="100%" preserveAspectRatio="xMidYMid meet"
                                                                    viewBox="0 0 24 24"><path fill="currentColor" d="M16.01 11H4v2h12.01v3L20 12l-3.99-4v3z"></path></svg></div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="margin-top">
                                                <div className="layout351_image-wrapper"><img src={adds} loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 991px) 80vw, 30vw"
                                                        srcSet={adds}
                                                        alt="Bucătărie clasică albă" className="layout351_image" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
            className={`services-layout_accordion ${activeIndex === 2 ? 'active' : ''}`}
            onClick={() => handleAccordionClick(2)}
          >
                                <div className="services-layout_column">
                                    <div className="services-layout_column-text">03</div>
                                    <h3 className="layout351_column-vertical-text">Brand Identity</h3>
                                    <h3 className="layout351_column-horiztonal-text">Brand Identity</h3>
                                </div>
                                <div className="layout351_column-content">
                                    <div className="layout351_content-wrapper">
                                        <div className="services-content-wrapper">
                                            <div className="margin-right margin-huge responsive-max-width">
                                                <h3 className="heading-style-h3 text-style-allcaps margin-bottom margin-medium mobile-title-8vw hide">Lorem ipsum dolor sit</h3>
                                                <p className="text-size-medium text-weight-light max-width-large">Brand identity that aligns with their values, communicates their oneness, and resonates with their target followership and anticipate visually charming and stoner-friendly interface that enhances the overall stoner experience. And also look for designs that reflect their brand's personality and maintain thickness across all touchpoints, fostering brand recognition and trust. Also, we acclimatize to arising design trends and technologies, icing their brand remains applicable and competitive in the digital geography.</p>
                                                <div className="button-group margin-top margin-large absolute-button">
                                                    <a  href="/" className="button is-small is-icon is-alternate w-inline-block">
                                                        <div>Get In Touch</div>
                                                        <div className="button-icon">
                                                            <div className="icon-embed-xsmall w-embed"><svg xmlns="http://www.w3.org/2000/svg"  aria-hidden="true" role="img" className="iconify iconify--ic" width="100%" height="100%" preserveAspectRatio="xMidYMid meet"
                                                                    viewBox="0 0 24 24"><path fill="currentColor" d="M16.01 11H4v2h12.01v3L20 12l-3.99-4v3z"></path></svg></div>
                                                            <div className="icon-embed-xsmall w-embed"><svg xmlns="http://www.w3.org/2000/svg"  aria-hidden="true" role="img" className="iconify iconify--ic" width="100%" height="100%" preserveAspectRatio="xMidYMid meet"
                                                                    viewBox="0 0 24 24"><path fill="currentColor" d="M16.01 11H4v2h12.01v3L20 12l-3.99-4v3z"></path></svg></div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="margin-top">
                                                <div className="layout351_image-wrapper"><img src={sm} loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 991px) 80vw, 30vw"
                                                        srcSet={sm}
                                                        alt="" className="layout351_image" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
            className={`services-layout_accordion ${activeIndex === 3 ? 'active' : ''}`}
            onClick={() => handleAccordionClick(3)}
          >
                                <div className="services-layout_column">
                                    <div className="services-layout_column-text">04</div>
                                    <h3 className="layout351_column-vertical-text">UI/UX design</h3>
                                    <h3 className="layout351_column-horiztonal-text">UI/UX design</h3>
                                </div>
                                <div className="layout351_column-content">
                                    <div className="layout351_content-wrapper">
                                        <div className="services-content-wrapper">
                                            <div className="margin-right margin-huge responsive-max-width">
                                                <h3 className="heading-style-h3 text-style-allcaps margin-bottom margin-medium mobile-title-8vw hide">Lorem ipsum dolor sit</h3>
                                                <p className="text-size-medium text-weight-light max-width-large">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                <div className="button-group margin-top margin-large absolute-button">
                                                    <a  href="/" className="button is-small is-icon is-alternate w-inline-block">
                                                        <div>Get In Touch</div>
                                                        <div className="button-icon">
                                                            <div className="icon-embed-xsmall w-embed"><svg xmlns="http://www.w3.org/2000/svg"  aria-hidden="true" role="img" className="iconify iconify--ic" width="100%" height="100%" preserveAspectRatio="xMidYMid meet"
                                                                    viewBox="0 0 24 24"><path fill="currentColor" d="M16.01 11H4v2h12.01v3L20 12l-3.99-4v3z"></path></svg></div>
                                                            <div className="icon-embed-xsmall w-embed"><svg xmlns="http://www.w3.org/2000/svg"  aria-hidden="true" role="img" className="iconify iconify--ic" width="100%" height="100%" preserveAspectRatio="xMidYMid meet"
                                                                    viewBox="0 0 24 24"><path fill="currentColor" d="M16.01 11H4v2h12.01v3L20 12l-3.99-4v3z"></path></svg></div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="margin-top">
                                                <div className="layout351_image-wrapper"><img src={dev}loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 991px) 80vw, 30vw"
                                                        srcSet={dev}
                                                        alt="" className="layout351_image" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <section className="section_process">
            <div className="padding-global">
                <div className="container-large _90vw">
                    <div className="padding-top padding-xhuge">
                        <div className="w-layout-grid process-layout_component">
                            <div className="process-layout_content-left" style={{marginBottom:'4rem'}}>
                                <div className="margin-bottom margin-medium">
                                    <div  className="section-number-wrapper margin-bottom margin-medium" >
                                        {/* <div className="number-circle">
                                            <div className="text-color-black text-size-regular font-satoshi">3</div>
                                        </div> */}
                                        <div className="section-name-wrapper">
                                            <div className="text-size-small text-colour-grey">How we do it</div>
                                        </div>
                                    </div>
                                    <div className="margin-bottom">
                                        <h2 text-split="" words-slide-from-right="" ref={ourRef} className="heading-style-h2 _8vw-title"></h2>
                                        <h2 ref={ourRef} text-split="" words-slide-from-right="" className="heading-style-h2 _8vw-title">our process</h2>
                                    </div>
                                </div>
                                <p  style={{opacity:'1'}} className="text-size-medium max-width-medium text-weight-light">We believe that the best way to create a successful brand identity is to follow a process that is both systematic and creative. Our process begins with understanding your brand's unique personality, values, and target audience. Once we have a clear understanding of your brand, we can begin to develop a visual identity that is both visually appealing and relevant to your target audience.</p>
                                {/* <div
                                    className="button-group margin-top margin-large">
                                    <a  style={{opacity:'1'}} href="/" className="button is-small is-icon is-alternate w-inline-block">
                                        <div>projects</div>
                                        <div className="button-icon">
                                            <div className="icon-embed-xsmall w-embed"><svg xmlns="http://www.w3.org/2000/svg"  aria-hidden="true" role="img" className="iconify iconify--ic" width="100%" height="100%" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M16.01 11H4v2h12.01v3L20 12l-3.99-4v3z"></path></svg></div>
                                            <div
                                                className="icon-embed-xsmall w-embed"><svg xmlns="http://www.w3.org/2000/svg"  aria-hidden="true" role="img" className="iconify iconify--ic" width="100%" height="100%" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M16.01 11H4v2h12.01v3L20 12l-3.99-4v3z"></path></svg></div>
                            </div>
                            </a>
                        </div> */}
                    </div>
                    <div className="process-layout_content-right">
                        <div className="process-layout_content-item content-item-1" style={{color:'white'}}>
                            <div className="margin-bottom margin-xsmall">
                                <div  style={{opacity:'1'}} className="icon-embed-medium w-embed">
                              
                                <img src={d} className='iconify iconify--ph'  ></img>
                                    </div>
                            </div>
                            <div className="margin-bottom margin-xsmall">
                                <h3 text-split="" words-slide-from-right="" className="heading-style-h5 text-style-allcaps text-weight-medium">Discovery & Research<br/></h3>
                            </div>
                            <p  style={{opacity:'1'}} className="text-size-regular">We meet with you to understand your brand's unique personality, values, and target audience.We conduct research to learn more about your industry and your target audience.</p>
                        </div>
                        <div className="process-layout_content-item content-item-2" style={{color:'white'}}>
                            <div className="margin-bottom margin-xsmall">
                                <div  style={{opacity:'1'}} className="icon-embed-medium w-embed">
                                <img src={c} className='iconify iconify--ph'  ></img>
                                    </div>
                            </div>
                            <div className="margin-bottom margin-xsmall">
                                <h3 text-split="" words-slide-from-right="" className="heading-style-h5 text-style-allcaps text-weight-medium">Brainstorming <br/></h3>
                            </div>
                            <p  style={{opacity:'1'}} className="text-size-regular">We brainstorm ideas for your brand identity, including your logo, colors, typography, and overall look and feel.</p>
                        </div>
                        <div className="process-layout_content-item content-item-3" style={{color:'white'}}>
                            <div className="margin-bottom margin-xsmall">
                                <div  style={{opacity:'1'}} className="icon-embed-medium w-embed">
                                <img src={d} className='iconify iconify--ph'  ></img>
                                    </div>
                            </div>
                            <div className="margin-bottom margin-xsmall">
                                <h3 text-split="" words-slide-from-right="" className="heading-style-h5 text-style-allcaps text-weight-medium">wireframing & Design<br/></h3>
                            </div>
                            <p  style={{opacity:'1'}} className="text-size-regular">We create wireframing which plans out struture & user flow of your website <br></br>We create initial designs for your brand identity and present them to you for feedback</p>
                        </div>
                        <div className="process-layout_content-item content-item-4" style={{color:'white'}}>
                            <div className="margin-bottom margin-xsmall">
                                <div  style={{opacity:'1'}} className="icon-embed-medium w-embed">
                                <img src={l} className='iconify iconify--ph'  ></img>
                                    </div>
                            </div>
                            <div className="margin-bottom margin-xsmall">
                                <h3 text-split="" words-slide-from-right="" className="heading-style-h5 text-style-allcaps text-weight-medium">Development & testing<br/></h3>
                            </div>
                            <p  style={{opacity:'1'}} className="text-size-regular">We develop the final brand identity and test it in different environments to make sure it is effective</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
        </section>
       
    </div>
    </>
  )
}

export default transition(About)