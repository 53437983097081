import React, { useEffect, useState } from "react";
import "./newland.css";
import wlogo from "../Assets/flogo.png";
import { Link, useNavigate } from "react-router-dom";
import Newan from "../../Newan";
import Fade from 'react-reveal/Fade';
function Newland() {
  const divStyle = {
    WebkitTransform:
      "translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    MozTransform:
      "translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    MsTransform:
      "translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
    transform:
      "translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
  };

  const navigate = useNavigate();
  const handleButtonClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/home");
  };
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setAnimate(true);
    }, 100);

    return () => clearTimeout(animationTimeout);
  }, []);

  const animateText = () => {
    const spanTexts = document.querySelectorAll(".span-text");
    spanTexts.forEach(spanText => {
      const letters = spanText.textContent.split("");

      spanText.innerHTML = letters
        .map(
          (letter, index) =>
            `<span style="animation-delay: ${index * 0.1}s">${letter}</span>`
        )
        .join("");
    });
  };
  useEffect(() => {
    animateText();
  }, []);

//   for image hover
const [isHovered, setIsHovered] = useState(false);

const handleMouseEnter = () => {
  setIsHovered(true);
};

const handleMouseLeave = () => {
  setIsHovered(false);
};
const imgStyle = {
    transition: 'transform 0.4s ease',  // Adjust the duration for a smoother transition
    willChange: 'transform',  // Optimize for smoother transitions
    transform: isHovered ? 'scale(1.1)' : 'scale(1)',
  };
  const ahandleButtonClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/contact");
  };

  const tophandleButtonClick = () => {
    navigate("/home");
    window.scrollTo({
      top: 0,
      behavior: 'smooth', 
    });
  };
  const topahandleButtonClick = () => {
    navigate("/erpcomingsoon");
    window.scrollTo({
      top: 0,
      behavior: 'smooth', 
    });
  };

  
  return (
    <>
      <style>
        {`
        html {
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
        //   font-family: sans-serif
      }
      
      body {
          margin: 0
      }
      
      article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section,summary {
          display: block
      }
      
      audio,canvas,progress,video {
          vertical-align: baseline;
          display: inline-block
      }
      
      audio:not([controls]) {
          height: 0;
          display: none
      }
      
      [hidden],template {
          display: none
      }
      
      a {
          background-color: transparent
      }
      
      a:active,a:hover {
          outline: 0
      }
      
      abbr[title] {
          border-bottom: 1px dotted
      }
      
      b,strong {
          font-weight: 700
      }
      
      dfn {
          font-style: italic
      }
      
      h1 {
          margin: .67em 0;
          font-size: 2em
      }
      
      mark {
          color: #000;
          background: #ff0
      }
      
      small {
          font-size: 80%
      }
      
      sub,sup {
          vertical-align: baseline;
          font-size: 75%;
          line-height: 0;
          position: relative
      }
      
      sup {
          top: -.5em
      }
      
      sub {
          bottom: -.25em
      }
      
      img {
          border: 0
      }
      
      svg:not(:root) {
          overflow: hidden
      }
      
      figure {
          margin: 1em 40px
      }
      
      hr {
          box-sizing: content-box;
          height: 0
      }
      
      pre {
          overflow: auto
      }
      
      code,kbd,pre,samp {
        //   font-family: monospace;
          font-size: 1em
      }
      
      button,input,optgroup,select,textarea {
          color: inherit;
          font: inherit;
          margin: 0
      }
      
      button {
          overflow: visible
      }
      
      button,select {
          text-transform: none
      }
      
      button,html input[type=button],input[type=reset] {
          -webkit-appearance: button;
          cursor: pointer
      }
      
      button[disabled],html input[disabled] {
          cursor: default
      }
      
      button::-moz-focus-inner,input::-moz-focus-inner {
          border: 0;
          padding: 0
      }
      
      input {
          line-height: normal
      }
      
      input[type=checkbox],input[type=radio] {
          box-sizing: border-box;
          padding: 0
      }
      
      input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button {
          height: auto
      }
      
      input[type=search] {
          -webkit-appearance: none
      }
      
      input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration {
          -webkit-appearance: none
      }
      
      fieldset {
          border: 1px solid silver;
          margin: 0 2px;
          padding: .35em .625em .75em
      }
      
      legend {
          border: 0;
          padding: 0
      }
      
      textarea {
          overflow: auto
      }
      
      optgroup {
          font-weight: 700
      }
      
      table {
          border-collapse: collapse;
          border-spacing: 0
      }
      
      td,th {
          padding: 0
      }
      
    
      
      [class^=w-icon-],[class*=\ w-icon-] {
          speak: none;
          font-variant: normal;
          text-transform: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-style: normal;
          font-weight: 400;
          line-height: 1;
        //   font-family: webflow-icons!important
      }
      
      .w-icon-slider-right:before {
          content: "î˜€"
      }
      
      .w-icon-slider-left:before {
          content: "î˜"
      }
      
      .w-icon-nav-menu:before {
          content: "î˜‚"
      }
      
      .w-icon-arrow-down:before,.w-icon-dropdown-toggle:before {
          content: "î˜ƒ"
      }
      
      .w-icon-file-upload-remove:before {
          content: "î¤€"
      }
      
      .w-icon-file-upload-icon:before {
          content: "î¤ƒ"
      }
      
      * {
          box-sizing: border-box
      }
      
      html {
          height: 100%
      }
      
      body {
        //   min-height: 100%;
          color: #333;
          background-color: #fff;
          margin: 0;
        //   font-family: Arial,sans-serif;
          font-size: 14px;
          line-height: 20px
      }
      
      img {
          max-width: 100%;
          vertical-align: middle;
          display: inline-block
      }
      
      html.w-mod-touch * {
          background-attachment: scroll!important
      }
      
      .w-block {
          display: block
      }
      
      .w-inline-block {
          max-width: 100%;
          display: inline-block
      }
      
      .w-clearfix:before,.w-clearfix:after {
          content: " ";
          grid-area: 1/1/2/2;
          display: table
      }
      
      .w-clearfix:after {
          clear: both
      }
      
      .w-hidden {
          display: none
      }
      
      .w-button {
          color: #fff;
          line-height: inherit;
          cursor: pointer;
          background-color: #3898ec;
          border: 0;
          border-radius: 0;
          padding: 9px 15px;
          text-decoration: none;
          display: inline-block
      }
      
      input.w-button {
          -webkit-appearance: button
      }
      
      html[data-w-dynpage] [data-w-cloak] {
          color: transparent!important
      }
      
      .w-webflow-badge,.w-webflow-badge * {
          z-index: auto;
          visibility: visible;
          box-sizing: border-box;
          width: auto;
          height: auto;
          max-height: none;
          max-width: none;
          min-height: 0;
          min-width: 0;
          float: none;
          clear: none;
          box-shadow: none;
          opacity: 1;
          direction: ltr;
        //   font-family: inherit;
          font-weight: inherit;
          color: inherit;
          font-size: inherit;
          line-height: inherit;
          font-style: inherit;
          font-variant: inherit;
          text-align: inherit;
          letter-spacing: inherit;
          -webkit-text-decoration: inherit;
          text-decoration: inherit;
          text-indent: 0;
          text-transform: inherit;
          text-shadow: none;
          font-smoothing: auto;
          vertical-align: baseline;
          cursor: inherit;
          white-space: inherit;
          word-break: normal;
          word-spacing: normal;
          word-wrap: normal;
          background: 0 0;
          border: 0 transparent;
          border-radius: 0;
          margin: 0;
          padding: 0;
          list-style-type: disc;
          transition: none;
          display: block;
          position: static;
          top: auto;
          bottom: auto;
          left: auto;
          right: auto;
          overflow: visible;
          transform: none
      }
      
      .w-webflow-badge {
          white-space: nowrap;
          cursor: pointer;
          box-shadow: 0 0 0 1px rgba(0,0,0,.1),0 1px 3px rgba(0,0,0,.1);
          visibility: visible!important;
          z-index: 2147483647!important;
          color: #aaadb0!important;
          opacity: 1!important;
          width: auto!important;
          height: auto!important;
          background-color: #fff!important;
          border-radius: 3px!important;
          margin: 0!important;
          padding: 6px 8px 6px 6px!important;
          font-size: 12px!important;
          line-height: 14px!important;
          text-decoration: none!important;
          display: inline-block!important;
          position: fixed!important;
          top: auto!important;
          bottom: 12px!important;
          left: auto!important;
          right: 12px!important;
          overflow: visible!important;
          transform: none!important
      }
      
      .w-webflow-badge>img {
          visibility: visible!important;
          opacity: 1!important;
          vertical-align: middle!important;
          display: inline-block!important
      }
      
      h1,h2,h3,h4,h5,h6 {
          margin-bottom: 10px;
          font-weight: 700
      }
      
      h1 {
          margin-top: 20px;
          font-size: 38px;
          line-height: 44px
      }
      
      h2 {
          margin-top: 20px;
          font-size: 32px;
          line-height: 36px
      }
      
      h3 {
          margin-top: 20px;
          font-size: 24px;
          line-height: 30px
      }
      
      h4 {
          margin-top: 10px;
          font-size: 18px;
          line-height: 24px
      }
      
      h5 {
          margin-top: 10px;
          font-size: 14px;
          line-height: 20px
      }
      
      h6 {
          margin-top: 10px;
          font-size: 12px;
          line-height: 18px
      }
      
      p {
          margin-top: 0;
          margin-bottom: 10px
      }
      
      blockquote {
          border-left: 5px solid #e2e2e2;
          margin: 0 0 10px;
          padding: 10px 20px;
          font-size: 18px;
          line-height: 22px
      }
      
      figure {
          margin: 0 0 10px
      }
      
      figcaption {
          text-align: center;
          margin-top: 5px
      }
      
      ul,ol {
          margin-top: 0;
          margin-bottom: 10px;
          padding-left: 40px
      }
      
      .w-list-unstyled {
          padding-left: 0;
          list-style: none
      }
      
      .w-embed:before,.w-embed:after {
          /* content: " "; */
          /* grid-area: 1/1/2/2; */
          /* display: table */
      }
      
      .w-embed:after {
          clear: both
      }
      
      .w-video {
          width: 100%;
          padding: 0;
          position: relative
      }
      
      .w-video iframe,.w-video object,.w-video embed {
          width: 100%;
          height: 100%;
          border: none;
          position: absolute;
          top: 0;
          left: 0
      }
      
      fieldset {
          border: 0;
          margin: 0;
          padding: 0
      }
      
      button,[type=button],[type=reset] {
          cursor: pointer;
          -webkit-appearance: button;
          border: 0
      }
      
      .w-form {
          margin: 0 0 15px
      }
      
      .w-form-done {
          text-align: center;
          background-color: #ddd;
          padding: 20px;
          display: none
      }
      
      .w-form-fail {
          background-color: #ffdede;
          margin-top: 10px;
          padding: 10px;
          display: none
      }
      
      label {
          margin-bottom: 5px;
          font-weight: 700;
          display: block
      }
      
      .w-input,.w-select {
          width: 100%;
          height: 38px;
          color: #333;
          vertical-align: middle;
          background-color: #fff;
          border: 1px solid #ccc;
          margin-bottom: 10px;
          padding: 8px 12px;
          font-size: 14px;
          line-height: 1.42857;
          display: block
      }
      
      .w-input:-moz-placeholder,.w-select:-moz-placeholder {
          color: #999
      }
      
      .w-input::-moz-placeholder,.w-select::-moz-placeholder {
          color: #999;
          opacity: 1
      }
      
      .w-input:-ms-input-placeholder,.w-select:-ms-input-placeholder {
          color: #999
      }
      
      .w-input::-webkit-input-placeholder,.w-select::-webkit-input-placeholder {
          color: #999
      }
      
      .w-input:focus,.w-select:focus {
          border-color: #3898ec;
          outline: 0
      }
      
      .w-input[disabled],.w-select[disabled],.w-input[readonly],.w-select[readonly],fieldset[disabled] .w-input,fieldset[disabled] .w-select {
          cursor: not-allowed
      }
      
      .w-input[disabled]:not(.w-input-disabled),.w-select[disabled]:not(.w-input-disabled),.w-input[readonly],.w-select[readonly],fieldset[disabled]:not(.w-input-disabled) .w-input,fieldset[disabled]:not(.w-input-disabled) .w-select {
          background-color: #eee
      }
      
      textarea.w-input,textarea.w-select {
          height: auto
      }
      
      .w-select {
          background-color: #f3f3f3
      }
      
      .w-select[multiple] {
          height: auto
      }
      
      .w-form-label {
          cursor: pointer;
          margin-bottom: 0;
          font-weight: 400;
          display: inline-block
      }
      
      .w-radio {
          margin-bottom: 5px;
          padding-left: 20px;
          display: block
      }
      
      .w-radio:before,.w-radio:after {
          content: " ";
          grid-area: 1/1/2/2;
          display: table
      }
      
      .w-radio:after {
          clear: both
      }
      
      .w-radio-input {
          margin: 4px 0 0;
          margin-top: 1px ;
          float: left;
          margin-top: 3px;
          margin-left: -20px;
          line-height: normal
      }
      
      .w-file-upload {
          margin-bottom: 10px;
          display: block
      }
      
      .w-file-upload-input {
          width: .1px;
          height: .1px;
          opacity: 0;
          z-index: -100;
          position: absolute;
          overflow: hidden
      }
      
      .w-file-upload-default,.w-file-upload-uploading,.w-file-upload-success {
          color: #333;
          display: inline-block
      }
      
      .w-file-upload-error {
          margin-top: 10px;
          display: block
      }
      
      .w-file-upload-default.w-hidden,.w-file-upload-uploading.w-hidden,.w-file-upload-error.w-hidden,.w-file-upload-success.w-hidden {
          display: none
      }
      
      .w-file-upload-uploading-btn {
          cursor: pointer;
          background-color: #fafafa;
          border: 1px solid #ccc;
          margin: 0;
          padding: 8px 12px;
          font-size: 14px;
          font-weight: 400;
          display: flex
      }
      
      .w-file-upload-file {
          background-color: #fafafa;
          border: 1px solid #ccc;
          flex-grow: 1;
          justify-content: space-between;
          margin: 0;
          padding: 8px 9px 8px 11px;
          display: flex
      }
      
      .w-file-upload-file-name {
          font-size: 14px;
          font-weight: 400;
          display: block
      }
      
      .w-file-remove-link {
          width: auto;
          height: auto;
          cursor: pointer;
          margin-top: 3px;
          margin-left: 10px;
          padding: 3px;
          display: block
      }
      
      .w-icon-file-upload-remove {
          margin: auto;
          font-size: 10px
      }
      
      .w-file-upload-error-msg {
          color: #ea384c;
          padding: 2px 0;
          display: inline-block
      }
      
      .w-file-upload-info {
          padding: 0 12px;
          line-height: 38px;
          display: inline-block
      }
      
      .w-file-upload-label {
          cursor: pointer;
          background-color: #fafafa;
          border: 1px solid #ccc;
          margin: 0;
          padding: 8px 12px;
          font-size: 14px;
          font-weight: 400;
          display: inline-block
      }
      
      .w-icon-file-upload-icon,.w-icon-file-upload-uploading {
          width: 20px;
          margin-right: 8px;
          display: inline-block
      }
      
      .w-icon-file-upload-uploading {
          height: 20px
      }
      
      .w-container {
          max-width: 940px;
          margin-left: auto;
          margin-right: auto
      }
      
      .w-container:before,.w-container:after {
          content: " ";
          grid-area: 1/1/2/2;
          display: table
      }
      
      .w-container:after {
          clear: both
      }
      
      .w-container .w-row {
          margin-left: -10px;
          margin-right: -10px
      }
      
      .w-row:before,.w-row:after {
          content: " ";
          grid-area: 1/1/2/2;
          display: table
      }
      
      .w-row:after {
          clear: both
      }
      
      .w-row .w-row {
          margin-left: 0;
          margin-right: 0
      }
      
      .w-col {
          float: left;
          width: 100%;
          min-height: 1px;
          padding-left: 10px;
          padding-right: 10px;
          position: relative
      }
      
      .w-col .w-col {
          padding-left: 0;
          padding-right: 0
      }
      
      .w-col-1 {
          width: 8.33333%
      }
      
      .w-col-2 {
          width: 16.6667%
      }
      
      .w-col-3 {
          width: 25%
      }
      
      .w-col-4 {
          width: 33.3333%
      }
      
      .w-col-5 {
          width: 41.6667%
      }
      
      .w-col-6 {
          width: 50%
      }
      
      .w-col-7 {
          width: 58.3333%
      }
      
      .w-col-8 {
          width: 66.6667%
      }
      
      .w-col-9 {
          width: 75%
      }
      
      .w-col-10 {
          width: 83.3333%
      }
      
      .w-col-11 {
          width: 91.6667%
      }
      
      .w-col-12 {
          width: 100%
      }
      
      .w-hidden-main {
          display: none!important
      }
      
      @media screen and (max-width: 991px) {
          .w-container {
              max-width:728px
          }
      
          .w-hidden-main {
              display: inherit!important
          }
      
          .w-hidden-medium {
              display: none!important
          }
      
          .w-col-medium-1 {
              width: 8.33333%
          }
      
          .w-col-medium-2 {
              width: 16.6667%
          }
      
          .w-col-medium-3 {
              width: 25%
          }
      
          .w-col-medium-4 {
              width: 33.3333%
          }
      
          .w-col-medium-5 {
              width: 41.6667%
          }
      
          .w-col-medium-6 {
              width: 50%
          }
      
          .w-col-medium-7 {
              width: 58.3333%
          }
      
          .w-col-medium-8 {
              width: 66.6667%
          }
      
          .w-col-medium-9 {
              width: 75%
          }
      
          .w-col-medium-10 {
              width: 83.3333%
          }
      
          .w-col-medium-11 {
              width: 91.6667%
          }
      
          .w-col-medium-12 {
              width: 100%
          }
      
          .w-col-stack {
              width: 100%;
              left: auto;
              right: auto
          }
      }
      
      @media screen and (max-width: 767px) {
          .w-hidden-main,.w-hidden-medium {
              display:inherit!important
          }
      
          .w-hidden-small {
              display: none!important
          }
      
          .w-row,.w-container .w-row {
              margin-left: 0;
              margin-right: 0
          }
      
          .w-col {
              width: 100%;
              left: auto;
              right: auto
          }
      
          .w-col-small-1 {
              width: 8.33333%
          }
      
          .w-col-small-2 {
              width: 16.6667%
          }
      
          .w-col-small-3 {
              width: 25%
          }
      
          .w-col-small-4 {
              width: 33.3333%
          }
      
          .w-col-small-5 {
              width: 41.6667%
          }
      
          .w-col-small-6 {
              width: 50%
          }
      
          .w-col-small-7 {
              width: 58.3333%
          }
      
          .w-col-small-8 {
              width: 66.6667%
          }
      
          .w-col-small-9 {
              width: 75%
          }
      
          .w-col-small-10 {
              width: 83.3333%
          }
      
          .w-col-small-11 {
              width: 91.6667%
          }
      
          .w-col-small-12 {
              width: 100%
          }
      }
      
      @media screen and (max-width: 479px) {
          .w-container {
              max-width:none
          }
      
          .w-hidden-main,.w-hidden-medium,.w-hidden-small {
              display: inherit!important
          }
      
          .w-hidden-tiny {
              display: none!important
          }
      
          .w-col {
              width: 100%
          }
      
          .w-col-tiny-1 {
              width: 8.33333%
          }
      
          .w-col-tiny-2 {
              width: 16.6667%
          }
      
          .w-col-tiny-3 {
              width: 25%
          }
      
          .w-col-tiny-4 {
              width: 33.3333%
          }
      
          .w-col-tiny-5 {
              width: 41.6667%
          }
      
          .w-col-tiny-6 {
              width: 50%
          }
      
          .w-col-tiny-7 {
              width: 58.3333%
          }
      
          .w-col-tiny-8 {
              width: 66.6667%
          }
      
          .w-col-tiny-9 {
              width: 75%
          }
      
          .w-col-tiny-10 {
              width: 83.3333%
          }
      
          .w-col-tiny-11 {
              width: 91.6667%
          }
      
          .w-col-tiny-12 {
              width: 100%
          }
      }
      
      .w-widget {
          position: relative
      }
      
      .w-widget-map {
          width: 100%;
          height: 400px
      }
      
      .w-widget-map label {
          width: auto;
          display: inline
      }
      
      .w-widget-map img {
          max-width: inherit
      }
      
      .w-widget-map .gm-style-iw {
          text-align: center
      }
      
      .w-widget-map .gm-style-iw>button {
          display: none!important
      }
      
      .w-widget-twitter {
          overflow: hidden
      }
      
      .w-widget-twitter-count-shim {
          vertical-align: top;
          width: 28px;
          height: 20px;
          text-align: center;
          background: #fff;
          border: 1px solid #758696;
          border-radius: 3px;
          display: inline-block;
          position: relative
      }
      
      .w-widget-twitter-count-shim * {
          pointer-events: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none
      }
      
      .w-widget-twitter-count-shim .w-widget-twitter-count-inner {
          text-align: center;
          color: #999;
        //   font-family: serif;
          font-size: 15px;
          line-height: 12px;
          position: relative
      }
      
      .w-widget-twitter-count-shim .w-widget-twitter-count-clear {
          display: block;
          position: relative
      }
      
      .w-widget-twitter-count-shim.w--large {
          width: 36px;
          height: 28px
      }
      
      .w-widget-twitter-count-shim.w--large .w-widget-twitter-count-inner {
          font-size: 18px;
          line-height: 18px
      }
      
      .w-widget-twitter-count-shim:not(.w--vertical) {
          margin-left: 5px;
          margin-right: 8px
      }
      
      .w-widget-twitter-count-shim:not(.w--vertical).w--large {
          margin-left: 6px
      }
      
      .w-widget-twitter-count-shim:not(.w--vertical):before,.w-widget-twitter-count-shim:not(.w--vertical):after {
          content: " ";
          height: 0;
          width: 0;
          pointer-events: none;
          border: solid transparent;
          position: absolute;
          top: 50%;
          left: 0
      }
      
      .w-widget-twitter-count-shim:not(.w--vertical):before {
          border-width: 4px;
          border-color: rgba(117,134,150,0) #5d6c7b rgba(117,134,150,0) rgba(117,134,150,0);
          margin-top: -4px;
          margin-left: -9px
      }
      
      .w-widget-twitter-count-shim:not(.w--vertical).w--large:before {
          border-width: 5px;
          margin-top: -5px;
          margin-left: -10px
      }
      
      .w-widget-twitter-count-shim:not(.w--vertical):after {
          border-width: 4px;
          border-color: rgba(255,255,255,0) #fff rgba(255,255,255,0) rgba(255,255,255,0);
          margin-top: -4px;
          margin-left: -8px
      }
      
      .w-widget-twitter-count-shim:not(.w--vertical).w--large:after {
          border-width: 5px;
          margin-top: -5px;
          margin-left: -9px
      }
      
      .w-widget-twitter-count-shim.w--vertical {
          width: 61px;
          height: 33px;
          margin-bottom: 8px
      }
      
      .w-widget-twitter-count-shim.w--vertical:before,.w-widget-twitter-count-shim.w--vertical:after {
          content: " ";
          height: 0;
          width: 0;
          pointer-events: none;
          border: solid transparent;
          position: absolute;
          top: 100%;
          left: 50%
      }
      
      .w-widget-twitter-count-shim.w--vertical:before {
          border-width: 5px;
          border-color: #5d6c7b rgba(117,134,150,0) rgba(117,134,150,0);
          margin-left: -5px
      }
      
      .w-widget-twitter-count-shim.w--vertical:after {
          border-width: 4px;
          border-color: #fff rgba(255,255,255,0) rgba(255,255,255,0);
          margin-left: -4px
      }
      
      .w-widget-twitter-count-shim.w--vertical .w-widget-twitter-count-inner {
          font-size: 18px;
          line-height: 22px
      }
      
      .w-widget-twitter-count-shim.w--vertical.w--large {
          width: 76px
      }
      
      .w-background-video {
          height: 500px;
          color: #fff;
          position: relative;
          overflow: hidden
      }
      
      .w-background-video>video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: -100;
          background-position: 50%;
          background-size: cover;
          margin: auto;
          position: absolute;
          top: -100%;
          bottom: -100%;
          left: -100%;
          right: -100%
      }
      
      .w-background-video>video::-webkit-media-controls-start-playback-button {
          -webkit-appearance: none;
          display: none!important
      }
      
      .w-background-video--control {
          background-color: transparent;
          padding: 0;
          position: absolute;
          bottom: 1em;
          right: 1em
      }
      
      .w-background-video--control>[hidden] {
          display: none!important
      }
      
      .w-slider {
          height: 300px;
          text-align: center;
          clear: both;
          -webkit-tap-highlight-color: transparent;
          tap-highlight-color: transparent;
          background: #ddd;
          position: relative
      }
      
      .w-slider-mask {
          z-index: 1;
          height: 100%;
          white-space: nowrap;
          display: block;
          position: relative;
          left: 0;
          right: 0;
          overflow: hidden
      }
      
      .w-slide {
          vertical-align: top;
          width: 100%;
          height: 100%;
          white-space: normal;
          text-align: left;
          display: inline-block;
          position: relative
      }
      
      .w-slider-nav {
          z-index: 2;
          height: 40px;
          text-align: center;
          -webkit-tap-highlight-color: transparent;
          tap-highlight-color: transparent;
          margin: auto;
          padding-top: 10px;
          position: absolute;
          top: auto;
          bottom: 0;
          left: 0;
          right: 0
      }
      
      .w-slider-nav.w-round>div {
          border-radius: 100%
      }
      
      .w-slider-nav.w-num>div {
          width: auto;
          height: auto;
          font-size: inherit;
          line-height: inherit;
          padding: .2em .5em
      }
      
      .w-slider-nav.w-shadow>div {
          box-shadow: 0 0 3px rgba(51,51,51,.4)
      }
      
      .w-slider-nav-invert {
          color: #fff
      }
      
      .w-slider-nav-invert>div {
          background-color: rgba(34,34,34,.4)
      }
      
      .w-slider-nav-invert>div.w-active {
          background-color: #222
      }
      
      .w-slider-dot {
          width: 1em;
          height: 1em;
          cursor: pointer;
          background-color: rgba(255,255,255,.4);
          margin: 0 3px .5em;
          transition: background-color .1s,color .1s;
          display: inline-block;
          position: relative
      }
      
      .w-slider-dot.w-active {
          background-color: #fff
      }
      
      .w-slider-dot:focus {
          outline: none;
          box-shadow: 0 0 0 2px #fff
      }
      
      .w-slider-dot:focus.w-active {
          box-shadow: none
      }
      
      .w-slider-arrow-left,.w-slider-arrow-right {
          width: 80px;
          cursor: pointer;
          color: #fff;
          -webkit-tap-highlight-color: transparent;
          tap-highlight-color: transparent;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          margin: auto;
          font-size: 40px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          overflow: hidden
      }
      
      .w-slider-arrow-left [class^=w-icon-],.w-slider-arrow-right [class^=w-icon-],.w-slider-arrow-left [class*=\ w-icon-],.w-slider-arrow-right [class*=\ w-icon-] {
          position: absolute
      }
      
      .w-slider-arrow-left:focus,.w-slider-arrow-right:focus {
          outline: 0
      }
      
      .w-slider-arrow-left {
          z-index: 3;
          right: auto
      }
      
      .w-slider-arrow-right {
          z-index: 4;
          left: auto
      }
      
      .w-icon-slider-left,.w-icon-slider-right {
          width: 1em;
          height: 1em;
          margin: auto;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0
      }
      
      .w-slider-aria-label {
          clip: rect(0 0 0 0);
          height: 1px;
          width: 1px;
          border: 0;
          margin: -1px;
          padding: 0;
          position: absolute;
          overflow: hidden
      }
      
      .w-slider-force-show {
          display: block!important
      }
      
      .w-dropdown {
          text-align: left;
          z-index: 900;
          margin-left: auto;
          margin-right: auto;
          display: inline-block;
          position: relative
      }
      
      .w-dropdown-btn,.w-dropdown-toggle,.w-dropdown-link {
          vertical-align: top;
          color: #222;
          text-align: left;
          white-space: nowrap;
          margin-left: auto;
          margin-right: auto;
          padding: 20px;
          text-decoration: none;
          position: relative
      }
      
      .w-dropdown-toggle {
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          cursor: pointer;
          padding-right: 40px;
          display: inline-block
      }
      
      .w-dropdown-toggle:focus {
          outline: 0
      }
      
      .w-icon-dropdown-toggle {
          width: 1em;
          height: 1em;
          margin: auto 20px auto auto;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0
      }
      
      .w-dropdown-list {
          min-width: 100%;
          background: #ddd;
          display: none;
          position: absolute
      }
      
      .w-dropdown-list.w--open {
          display: block
      }
      
      .w-dropdown-link {
          color: #222;
          padding: 10px 20px;
          display: block
      }
      
      .w-dropdown-link.w--current {
          color: #0082f3
      }
      
      .w-dropdown-link:focus {
          outline: 0
      }
      
      @media screen and (max-width: 767px) {
          .w-nav-brand {
              padding-left:10px
          }
      }
      
      .w-lightbox-backdrop {
          cursor: auto;
          letter-spacing: normal;
          text-indent: 0;
          text-shadow: none;
          text-transform: none;
          visibility: visible;
          white-space: normal;
          word-break: normal;
          word-spacing: normal;
          word-wrap: normal;
          color: #fff;
          text-align: center;
          z-index: 2000;
          opacity: 0;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          -webkit-tap-highlight-color: transparent;
          background: rgba(0,0,0,.9);
          outline: 0;
        //   font-family: Helvetica Neue,Helvetica,Ubuntu,Segoe UI,Verdana,sans-serif;
          font-size: 17px;
          font-style: normal;
          font-weight: 300;
          line-height: 1.2;
          list-style: disc;
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          -webkit-transform: translate(0)
      }
      
      .w-lightbox-backdrop,.w-lightbox-container {
          height: 100%;
          -webkit-overflow-scrolling: touch;
          overflow: auto
      }
      
      .w-lightbox-content {
          height: 100vh;
          position: relative;
          overflow: hidden
      }
      
      .w-lightbox-view {
          width: 100vw;
          height: 100vh;
          opacity: 0;
          position: absolute
      }
      
      .w-lightbox-view:before {
          content: "";
          height: 100vh
      }
      
      .w-lightbox-group,.w-lightbox-group .w-lightbox-view,.w-lightbox-group .w-lightbox-view:before {
          height: 86vh
      }
      
      .w-lightbox-frame,.w-lightbox-view:before {
          vertical-align: middle;
          display: inline-block
      }
      
      .w-lightbox-figure {
          margin: 0;
          position: relative
      }
      
      .w-lightbox-group .w-lightbox-figure {
          cursor: pointer
      }
      
      .w-lightbox-img {
          width: auto;
          height: auto;
          max-width: none
      }
      
      .w-lightbox-image {
          float: none;
          max-width: 100vw;
          max-height: 100vh;
          display: block
      }
      
      .w-lightbox-group .w-lightbox-image {
          max-height: 86vh
      }
      
      .w-lightbox-caption {
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          background: rgba(0,0,0,.4);
          padding: .5em 1em;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          overflow: hidden
      }
      
      .w-lightbox-embed {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0
      }
      
      .w-lightbox-control {
          width: 4em;
          cursor: pointer;
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: 24px;
          transition: all .3s;
          position: absolute;
          top: 0
      }
      
      .w-lightbox-left {
          background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0yMCAwIDI0IDQwIiB3aWR0aD0iMjQiIGhlaWdodD0iNDAiPjxnIHRyYW5zZm9ybT0icm90YXRlKDQ1KSI+PHBhdGggZD0ibTAgMGg1djIzaDIzdjVoLTI4eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDN2MjNoMjN2M2gtMjZ6IiBmaWxsPSIjZmZmIi8+PC9nPjwvc3ZnPg==);
          display: none;
          bottom: 0;
          left: 0
      }
      
      .w-lightbox-right {
          background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMjQgNDAiIHdpZHRoPSIyNCIgaGVpZ2h0PSI0MCI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMC0waDI4djI4aC01di0yM2gtMjN6IiBvcGFjaXR5PSIuNCIvPjxwYXRoIGQ9Im0xIDFoMjZ2MjZoLTN2LTIzaC0yM3oiIGZpbGw9IiNmZmYiLz48L2c+PC9zdmc+);
          display: none;
          bottom: 0;
          right: 0
      }
      
      .w-lightbox-close {
          height: 2.6em;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMTggMTciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNyI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMCAwaDd2LTdoNXY3aDd2NWgtN3Y3aC01di03aC03eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDd2LTdoM3Y3aDd2M2gtN3Y3aC0zdi03aC03eiIgZmlsbD0iI2ZmZiIvPjwvZz48L3N2Zz4=);
          background-size: 18px;
          right: 0
      }
      
      .w-lightbox-strip {
          white-space: nowrap;
          padding: 0 1vh;
          line-height: 0;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          overflow-x: auto;
          overflow-y: hidden
      }
      
      .w-lightbox-item {
          width: 10vh;
          box-sizing: content-box;
          cursor: pointer;
          padding: 2vh 1vh;
          display: inline-block;
          -webkit-transform: translate(0,0)
      }
      
      .w-lightbox-active {
          opacity: .3
      }
      
      .w-lightbox-thumbnail {
          height: 10vh;
          background: #222;
          position: relative;
          overflow: hidden
      }
      
      .w-lightbox-thumbnail-image {
          position: absolute;
          top: 0;
          left: 0
      }
      
      .w-lightbox-thumbnail .w-lightbox-tall {
          width: 100%;
          top: 50%;
          transform: translateY(-50%)
      }
      
      .w-lightbox-thumbnail .w-lightbox-wide {
          height: 100%;
          left: 50%;
          transform: translate(-50%)
      }
      
      .w-lightbox-spinner {
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          border: 5px solid rgba(0,0,0,.4);
          border-radius: 50%;
          margin-top: -20px;
          margin-left: -20px;
          animation: .8s linear infinite spin;
          position: absolute;
          top: 50%;
          left: 50%
      }
      
      .w-lightbox-spinner:after {
          content: "";
          border: 3px solid transparent;
          border-bottom-color: #fff;
          border-radius: 50%;
          position: absolute;
          top: -4px;
          bottom: -4px;
          left: -4px;
          right: -4px
      }
      
      .w-lightbox-hide {
          display: none
      }
      
      .w-lightbox-noscroll {
          overflow: hidden
      }
      
      @media (min-width: 768px) {
          .w-lightbox-content {
              height:96vh;
              margin-top: 2vh
          }
      
          .w-lightbox-view,.w-lightbox-view:before {
              height: 96vh
          }
      
          .w-lightbox-group,.w-lightbox-group .w-lightbox-view,.w-lightbox-group .w-lightbox-view:before {
              height: 84vh
          }
      
          .w-lightbox-image {
              max-width: 96vw;
              max-height: 96vh
          }
      
          .w-lightbox-group .w-lightbox-image {
              max-width: 82.3vw;
              max-height: 84vh
          }
      
          .w-lightbox-left,.w-lightbox-right {
              opacity: .5;
              display: block
          }
      
          .w-lightbox-close {
              opacity: .8
          }
      
          .w-lightbox-control:hover {
              opacity: 1
          }
      }
      
      .w-lightbox-inactive,.w-lightbox-inactive:hover {
          opacity: 0
      }
      
      .w-richtext:before,.w-richtext:after {
          content: " ";
          grid-area: 1/1/2/2;
          display: table
      }
      
      .w-richtext:after {
          clear: both
      }
      
      .w-richtext[contenteditable=true]:before,.w-richtext[contenteditable=true]:after {
          white-space: initial
      }
      
      .w-richtext ol,.w-richtext ul {
          overflow: hidden
      }
      
      .w-richtext .w-richtext-figure-selected.w-richtext-figure-type-video div:after,.w-richtext .w-richtext-figure-selected[data-rt-type=video] div:after,.w-richtext .w-richtext-figure-selected.w-richtext-figure-type-image div,.w-richtext .w-richtext-figure-selected[data-rt-type=image] div {
          outline: 2px solid #2895f7
      }
      
      .w-richtext figure.w-richtext-figure-type-video>div:after,.w-richtext figure[data-rt-type=video]>div:after {
          content: "";
          display: none;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0
      }
      
      .w-richtext figure {
          max-width: 60%;
          position: relative
      }
      
      .w-richtext figure>div:before {
          cursor: default!important
      }
      
      .w-richtext figure img {
          width: 100%
      }
      
      .w-richtext figure figcaption.w-richtext-figcaption-placeholder {
          opacity: .6
      }
      
      .w-richtext figure div {
          color: transparent;
          font-size: 0
      }
      
      .w-richtext figure.w-richtext-figure-type-image,.w-richtext figure[data-rt-type=image] {
          display: table
      }
      
      .w-richtext figure.w-richtext-figure-type-image>div,.w-richtext figure[data-rt-type=image]>div {
          display: inline-block
      }
      
      .w-richtext figure.w-richtext-figure-type-image>figcaption,.w-richtext figure[data-rt-type=image]>figcaption {
          caption-side: bottom;
          display: table-caption
      }
      
      .w-richtext figure.w-richtext-figure-type-video,.w-richtext figure[data-rt-type=video] {
          width: 60%;
          height: 0
      }
      
      .w-richtext figure.w-richtext-figure-type-video iframe,.w-richtext figure[data-rt-type=video] iframe {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0
      }
      
      .w-richtext figure.w-richtext-figure-type-video>div,.w-richtext figure[data-rt-type=video]>div {
          width: 100%
      }
      
      .w-richtext figure.w-richtext-align-center {
          clear: both;
          margin-left: auto;
          margin-right: auto
      }
      
      .w-richtext figure.w-richtext-align-center.w-richtext-figure-type-image>div,.w-richtext figure.w-richtext-align-center[data-rt-type=image]>div {
          max-width: 100%
      }
      
      .w-richtext figure.w-richtext-align-normal {
          clear: both
      }
      
      .w-richtext figure.w-richtext-align-fullwidth {
          width: 100%;
          max-width: 100%;
          text-align: center;
          clear: both;
          margin-left: auto;
          margin-right: auto;
          display: block
      }
      
      .w-richtext figure.w-richtext-align-fullwidth>div {
          padding-bottom: inherit;
          display: inline-block
      }
      
      .w-richtext figure.w-richtext-align-fullwidth>figcaption {
          display: block
      }
      
      .w-richtext figure.w-richtext-align-floatleft {
          float: left;
          clear: none;
          margin-right: 15px
      }
      
      .w-richtext figure.w-richtext-align-floatright {
          float: right;
          clear: none;
          margin-left: 15px
      }
      
      .w-nav {
          z-index: 1000;
          background: #ddd;
          position: relative
      }
      
      .w-nav:before,.w-nav:after {
          content: " ";
          grid-area: 1/1/2/2;
          display: table
      }
      
      .w-nav:after {
          clear: both
      }
      
      .w-nav-brand {
          float: left;
          color: #333;
          text-decoration: none;
          position: relative
      }
      
      .w-nav-link {
          vertical-align: top;
          color: #222;
          text-align: left;
          margin-left: auto;
          margin-right: auto;
          padding: 20px;
          text-decoration: none;
          display: inline-block;
          position: relative
      }
      
      .w-nav-link.w--current {
          color: #0082f3
      }
      
      .w-nav-menu {
          float: right;
          position: relative
      }
      
      [data-nav-menu-open] {
          text-align: center;
          min-width: 200px;
          background: #c8c8c8;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          overflow: visible;
          display: block!important
      }
      
      .w--nav-link-open {
          display: block;
          position: relative
      }
      
      .w-nav-overlay {
          width: 100%;
          display: none;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          overflow: hidden
      }
      
      .w-nav-overlay [data-nav-menu-open] {
          top: 0
      }
      
      .w-nav[data-animation=over-left] .w-nav-overlay {
          width: auto
      }
      
      .w-nav[data-animation=over-left] .w-nav-overlay,.w-nav[data-animation=over-left] [data-nav-menu-open] {
          z-index: 1;
          top: 0;
          right: auto
      }
      
      .w-nav[data-animation=over-right] .w-nav-overlay {
          width: auto
      }
      
      .w-nav[data-animation=over-right] .w-nav-overlay,.w-nav[data-animation=over-right] [data-nav-menu-open] {
          z-index: 1;
          top: 0;
          left: auto
      }
      
      .w-nav-button {
          float: right;
          cursor: pointer;
          -webkit-tap-highlight-color: transparent;
          tap-highlight-color: transparent;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
          padding: 18px;
          font-size: 24px;
          display: none;
          position: relative
      }
      
      .w-nav-button:focus {
          outline: 0
      }
      
      .w-nav-button.w--open {
          color: #fff;
          background-color: #c8c8c8
      }
      
      .w-nav[data-collapse=all] .w-nav-menu {
          display: none
      }
      
      .w-nav[data-collapse=all] .w-nav-button,.w--nav-dropdown-open,.w--nav-dropdown-toggle-open {
          display: block
      }
      
      .w--nav-dropdown-list-open {
          position: static
      }
      
      @media screen and (max-width: 991px) {
          .w-nav[data-collapse=medium] .w-nav-menu {
              display:none
          }
      
          .w-nav[data-collapse=medium] .w-nav-button {
              display: block
          }
      }
      
      @media screen and (max-width: 767px) {
          .w-nav[data-collapse=small] .w-nav-menu {
              display:none
          }
      
          .w-nav[data-collapse=small] .w-nav-button {
              display: block
          }
      
          .w-nav-brand {
              padding-left: 10px
          }
      }
      
      @media screen and (max-width: 479px) {
          .w-nav[data-collapse=tiny] .w-nav-menu {
              display:none
          }
      
          .w-nav[data-collapse=tiny] .w-nav-button {
              display: block
          }
      }
      
      .w-tabs {
          position: relative
      }
      
      .w-tabs:before,.w-tabs:after {
          content: " ";
          grid-area: 1/1/2/2;
          display: table
      }
      
      .w-tabs:after {
          clear: both
      }
      
      .w-tab-menu {
          position: relative
      }
      
      .w-tab-link {
          vertical-align: top;
          text-align: left;
          cursor: pointer;
          color: #222;
          background-color: #ddd;
          padding: 9px 30px;
          text-decoration: none;
          display: inline-block;
          position: relative
      }
      
      .w-tab-link.w--current {
          background-color: #c8c8c8
      }
      
      .w-tab-link:focus {
          outline: 0
      }
      
      .w-tab-content {
          display: block;
          position: relative;
          overflow: hidden
      }
      
      .w-tab-pane {
          display: none;
          position: relative
      }
      
      .w--tab-active {
          display: block
      }
      
      @media screen and (max-width: 479px) {
          .w-tab-link {
              display:block
          }
      }
      
      .w-ix-emptyfix:after {
          content: ""
      }
      
      @keyframes spin {
          0% {
              transform: rotate(0)
          }
      
          to {
              transform: rotate(360deg)
          }
      }
      
      .w-dyn-empty {
          background-color: #ddd;
          padding: 10px
      }
      
      .w-dyn-hide,.w-dyn-bind-empty,.w-condition-invisible {
          display: none!important
      }
      
      .w-layout-grid {
          grid-row-gap: 16px;
          grid-column-gap: 16px;
          grid-template-rows: auto auto;
          grid-template-columns: 1fr 1fr;
          grid-auto-columns: 1fr;
          display: grid
      }
      
      .w-pagination-wrapper {
          flex-wrap: wrap;
          justify-content: center;
          display: flex
      }
      
      .w-pagination-previous {
          color: #333;
          background-color: #fafafa;
          border: 1px solid #ccc;
          border-radius: 2px;
          margin-left: 10px;
          margin-right: 10px;
          padding: 9px 20px;
          font-size: 14px;
          display: block
      }
      
      .w-pagination-previous-icon {
          margin-right: 4px
      }
      
      .w-pagination-next {
          color: #333;
          background-color: #fafafa;
          border: 1px solid #ccc;
          border-radius: 2px;
          margin-left: 10px;
          margin-right: 10px;
          padding: 9px 20px;
          font-size: 14px;
          display: block
      }
      
      body {
          color: #fff;
          letter-spacing: -.5px;
          background-color: black;
        //   font-family: Helveticaneue,sans-serif;
          font-size: 14px;
          line-height: 20px
      }
      
      h1 {
          text-transform: uppercase;
          margin-top: 0;
          margin-bottom: 0;
          font-size: 11rem;
          font-weight: 400;
          line-height: 1
      }
      
      h2 {
          text-transform: uppercase;
          margin-top: 0;
          margin-bottom: 0;
          font-size: 6rem;
          font-weight: 400;
          line-height: 1
      }
      
      h3 {
          text-transform: uppercase;
          margin-top: 20px;
          margin-bottom: 10px;
          font-size: 2.4rem;
          font-weight: 400;
          line-height: 1.2
      }
      
      h4 {
          text-transform: uppercase;
          margin-top: 10px;
          margin-bottom: 10px;
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 1.2
      }
      
      p {
          margin-bottom: 0;
          font-size: 1.2rem;
          line-height: 1.5
      }
      
      a {
          color: #fff;
          text-decoration: none
      }
      
      .body {
        //   font-family: Oakes grotesk rel,sans-serif;
          font-weight: 400
      }
      
      .heading-lg {
          text-transform: uppercase;
          padding-top: 5px;
          font-size: 10rem;
          line-height: .95
      }
      
      .heading-lg.hero {
          font-size: 8.5vw
      }
      
      .heading-md {
          text-transform: uppercase;
          font-size: 5.8rem;
          line-height: 1
      }
      
      .heading-sm {
          text-transform: uppercase;
          font-size: 2.4rem;
          line-height: 1.1
      }
      
      .heading-sm.lg {
          color: #1a1a1a;
          font-size: 2.8rem
      }
      
      .heading-sm.lowercase-intro {
          text-transform: none
      }
      
      .heading-xsm {
          text-transform: uppercase;
          font-size: 1.4rem;
          line-height: 1.2
      }
      
      .p-lg {
          text-transform: none;
          font-size: 3.4rem;
          line-height: 1.15
      }
      
      .p-lg.indent {
          text-indent: 110px
      }
      
      .p-lg.indent.dark-fade {
          display: none
      }
      
      .p-lg.indent-sm {
          text-indent: 50px
      }
      
      .p-lg.dark-fade {
          text-align: left;
          display: block
      }
      
      .p-lg.text-black {
          background-color: transparent
      }
      
      .intro {
          z-index: 999;
          width: 100%;
          height: 102vh;
          border-radius: 10px;
          justify-content: center;
          align-items: center;
          display: none;
          position: fixed;
          top: -1vh;
          bottom: auto;
          left: 0%;
          right: auto;
          overflow: hidden
      }
      
      .bg-whipe-right {
        //   z-index: 5;
        //   width: 100%;
        //   height: 100%;
        //   border-radius: 10px;
        //   position: absolute;
        //   top: auto;
        //   bottom: 0%;
        //   left: auto;
        //   right: auto
      }
      
      .bg-whipe-right.bg-green.one,.bg-whipe-right.bg-pink.one,.bg-whipe-right.bg-yellow.one {
          display: block
      }
      
      .bg-whipe-right.top {
          top: 0%;
          bottom: auto;
          left: 0%;
          right: auto
      }
      
      .bg-whipe-right.top.bg-yellow {
          display: block
      }
      
      .bg-pink {
          background-color: #ff1cdb
      }
      
      .bg-green {
          background-color: #29ff65
      }
      
      .bg-yellow {
          background-color: #dfff1c
      }
      
      .intro-logo-parent {
          z-index: 10;
          justify-content: center;
          display: flex;
          position: relative;
          overflow: hidden
      }
      
      .div-hide {
          position: relative;
          overflow: hidden
      }
      
      .div-hide.pull-left {
          display: flex;
          left: -40px
      }
      
      .div-hide.pull-right {
          right: -50px
      }
      
      .div-hide.margin-left {
          margin-left: 24px
      }
      
      .div-hide.ab-right {
          z-index: 2;
          position: absolute;
          top: auto;
          bottom: -5.1rem;
          left: auto;
          right: 5%
      }
      
      .div-hide.push-right {
          margin-left: 0
      }
      
      .div-hide.size {
          width: 8rem;
          justify-content: center;
          display: flex
      }
      
      .intro-logo-two {
          width: 250px;
          filter: brightness(0%)
      }
      
      .hero-main {
          width: 100%;
          height: 120vh;
          padding-top: 12rem
      }
      
      .container-large {
          z-index: 1;
          width: 95%;
          max-width: 1860px;
          margin-left: auto;
          margin-right: auto;
          position: relative
      }
      
      .container-large.full-height {
          height: 100%
      }
      
      .container-large.footer-section {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-top: 8rem;
          padding-bottom: 8rem;
          display: flex
      }
      
      .container-large.footer-btm {
          justify-content: space-between;
          align-items: center;
          display: flex
      }
      
      .text-width-hero {
        //   width: 68rem;
          position: relative
      }
      
      .nav {
          z-index: 998;
          width: 100%;
          mix-blend-mode: difference;
          padding-top: 2rem;
          padding-bottom: 2rem;
          position: fixed;
          top: 0%;
          bottom: auto;
          left: 0%;
          right: auto
      }
      
      .flex-split {
          justify-content: space-between;
          align-items: center;
          display: flex
      }
      
      .flex-split.btm {
          align-items: flex-end
      }
      
      .flex-split.mobile.v {
          text-align: center;
          flex-direction: column
      }
      
      .logo-home {
          width: 20rem
      }
      
      .span-text {
          display: inline-block
      }
      
      .span-text.four {
          margin-left: auto
      }
      
      .underline {
          text-decoration: underline
      }
      
      .img-parent {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          position: relative;
          overflow: hidden
      }
      
      .img-parent.hero {
          width: 100%;
          height: 100%;
          flex-direction: column;
          justify-content: center;
          display: flex
      }
      
      .img-parent.work {
          width: 100%;
          flex-direction: column;
          justify-content: center;
          display: flex
      }
      
      .img-parent.hero-flex {
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: flex
      }
      
      .img-child {
          width: 100%;
          height: 100%;
          overflow: hidden
      }
      
      .img-child.scroll-img {
        //   height: 105%;
        //   min-height: 105%;
          justify-content: center;
          align-items: center
      }
      
      .video-html5 {
          z-index: 0;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          display: flex;
          position: relative;
          overflow: hidden
      }
      
      .video-html5.profile {
          height: 36rem
      }
      
      .inline {
          display: inline-block
      }
      
      .border-line-btm {
          width: 100%;
          height: 2px;
          position: absolute;
          top: auto;
          bottom: 0%;
          left: 0%;
          right: auto
      }
      
      .border-line-btm.bg-white.black,.border-line-btm.dark {
          background-color: #1a1a1a
      }
      
      .border-line-btm.fade-light {
          height: 1px;
          background-color: rgba(255,255,255,.2)
      }
      
      .border-line-btm.light {
          height: 1px;
          background-color: #fff
      }
      
      .border-line-btm.fade-dark {
          height: 1px;
          background-color: rgba(0,0,0,.2)
      }
      
      .border-line-btm.show-m {
          display: none
      }
      
      .bg-white {
          background-color: #fff
      }
      
      .icon-spin {
          z-index: 2;
          width: 14rem;
          position: absolute;
          top: auto;
          bottom: -6rem;
          left: auto;
          right: 5rem
      }
      
      .hero-wrap {
          width: 70%;
          height: 75%;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          display: flex;
          position: absolute;
          top: auto;
          bottom: 0%;
          left: auto;
          right: 0%
      }
      
      .parent {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          position: relative
      }
      
      .parent.work {
          z-index: 4;
          width: 100%;
          padding-bottom: 1.5rem;
          padding-left: 2rem;
          padding-right: 2rem;
          position: absolute;
          top: auto;
          bottom: 0%;
          left: 0%;
          right: auto
      }
      
      .parent.work.hide {
          display: none
      }
      
      .parent.div-hide.fill-parent {
          width: 100%;
          height: 100%
      }
      
      .parent.width {
          width: 28%;
          height: 16rem;
          left: 0%
      }
      
      .parent.width.div-hide.hide {
          display: none
      }
      
      .parent.width-one {
          width: 50rem;
          height: 32rem;
          position: absolute;
          top: 22%;
          overflow: hidden
      }
      
      .parent.width-two {
          width: 18rem;
          height: 14rem;
          position: absolute;
          top: 76%;
          right: 0;
          overflow: hidden
      }
      
      .parent.width-three {
          z-index: 20;
          width: 24rem;
          height: 18rem;
          position: absolute;
          top: 110%;
          right: 30%;
          overflow: hidden
      }
      
      .parent.size-p {
          width: 50%;
          height: 22rem;
          overflow: hidden
      }
      
      .parent.width-four {
          width: 100%;
          height: 100vh;
          position: relative;
          overflow: hidden
      }
      
      .parent.menu {
          border-radius: 0;
          padding-right: 3.9rem;
          display: inline-block;
          overflow: hidden
      }
      
      .parent.btn {
          cursor: pointer
      }
      
      .parent.width-four {
          z-index: 20;
          width: 24rem;
          height: 18rem;
          position: absolute;
          top: auto;
          bottom: -10%;
          left: auto;
          right: 0%;
          overflow: hidden
      }
      
      .parent.width-five {
          width: 100%;
          height: 100vh;
          position: relative;
          overflow: hidden
      }
      
      .parent.info-text {
          padding-top: 1.3rem;
          padding-bottom: 1.5rem;
          padding-right: 6rem
      }
      
      .sm-txt {
          z-index: 1;
          text-transform: uppercase;
          font-size: 1.1rem;
          position: relative;
          text-align: initial;
      }
      
      .flag {
          width: 1.2rem;
          margin-left: .4rem
      }
      
      .spacer-x-small {
          width: 100%;
          padding-bottom: 1rem
      }
      
      .scroll-block {
          justify-content: center;
          align-items: center;
          display: flex;
          transform: rotate(-90deg)
      }
      
      .line {
          width: 100px;
          height: 1px;
          background-color: #fff;
          margin-left: 6px
      }
      
      .scroll-parent {
          /* width: 195px; */
          /* height: 220px; */
          justify-content: flex-end;
          align-items: flex-start;
          display: flex;
          position: absolute;
          top: auto;
          bottom: 14%;
          left: -60px;
          right: 0px;
          right: auto;
          /* transform: rotate(0) */
      }
      
      .section {
          width: 100%;
          position: relative
      }
      
      .section.flex-cc {
          justify-content: center;
          align-items: center;
          display: flex
      }
      
      .section.flex-cc.bg {
          flex-direction: column
      }
      
      .section.bg-black {
          display: block
      }
      
      .spacer-large {
          width: 100%;
          height: 12rem
      }
      
      .spacer-large.sm {
          height: 10rem
      }
      
      .text-width-intro {
          width: 59rem;
          text-indent: 2.6rem;
          margin-left: auto;
          margin-right: auto;
          position: relative
      }
      
      .fade {
          color: rgba(255,255,255,.6)
      }
      
      .caps {
          opacity: 1;
          color: #fff;
          text-transform: none;
        //   font-family: Georgia,sans-serif;
          font-style: italic
      }
      
      .caps.black {
          color: #1a1a1a
      }
      
      .script {
          letter-spacing: -1px;
        //   font-family: Sd georgia demo,sans-serif;
          font-style: italic;
          font-weight: 300;
          line-height: 1
      }
      
      .text-yellow {
          color: #dfff1c
      }
      
      .icon-text {
          width: 4.3rem;
          height: 2.8rem;
          margin-bottom: -5px;
          display: inline-block
      }
      
      .icon-text.icon-pink {
          background-image: url(https://uploads-ssl.webflow.com/633c3f6cb573b74f358e9feb/633c3f6cb573b73f1d8ea030_icon-p.png);
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: contain
      }
      
      .icon-text.icon-green {
          background-image: url(https://uploads-ssl.webflow.com/633c3f6cb573b74f358e9feb/633c3f6cb573b72f4d8ea052_icon-g.png);
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: contain
      }
      
      .text-width-work {
          width: 27rem;
          letter-spacing: 0;
          text-transform: uppercase;
          line-height: 1.3;
          position: relative
      }
      
      .grid-home-alt {
          grid-column-gap: 2rem;
          grid-row-gap: 2rem;
          grid-template-rows: 45rem 45rem;
          grid-template-columns: 2fr 1fr;
          grid-auto-rows: auto
      }
      
      .spacer-small {
          width: 100%;
          padding-bottom: 2.5rem
      }
      
      .lowercase {
          text-transform: none
      }
      
      .flex {
          align-items: center;
          display: flex
      }
      
      .dot {
          width: .6rem;
          height: .6rem;
          min-height: .6rem;
          min-width: .6rem;
          border-radius: 3px;
          margin-right: 8px;
          margin-bottom: 1rem;
      }
      
      .overlay {
          z-index: 2;
          width: 100%;
          height: 100%;
          background-color: rgba(26,26,26,.4);
          position: absolute;
          top: auto;
          bottom: 0%;
          left: 0%;
          right: auto
      }
      
      .overlay.black {
          background-color: #000;
          justify-content: center;
          align-items: center;
          display: flex
      }
      
      .img-cover {
          width: 100%;
          height: 100%;
          object-fit: cover
      }
      
      .img-cover.opacity {
          height: 100%;
          max-height: none;
          opacity: .4;
          object-fit: cover
      }
      
      .arrow-top {
          z-index: 3;
          width: 2.8rem;
          position: absolute;
          top: 1rem;
          bottom: auto;
          left: auto;
          right: 1rem;
          overflow: hidden
      }
      
      .spacer-medium {
          width: 100%;
          height: 6rem
      }
      
      .main-btn {
          z-index: 10;
          width: 14rem;
          height: 14rem;
          float: right;
          cursor: pointer;
          justify-content: center;
          align-items: center;
          display: flex;
          position: relative
      }
      
      .main-btn.ab {
          position: absolute
      }
      
      .main-btn.dark {
          filter: brightness(0%);
          margin-top: 1rem;
          margin-bottom: 1rem
      }
      
      .hover-icon {
          position: absolute
      }
      
      .hover-icon.one.h {
          display: none
      }
      
      .hover-icon.four {
          text-align: left;
          position: absolute
      }
      
      .text-width-stament {
          z-index: 10;
          width: 67rem;
          position: relative;
          left: 2rem
      }
      
      .icon-line {
          width: 95%;
          opacity: .15;
          position: absolute
      }
      
      .icon-line.pull {
          left: -20%
      }
      
      .icon-line.pull-dark {
          z-index: 0;
          width: 90%;
          filter: brightness(0%);
          top: 0%;
          bottom: auto;
          left: -15%;
          right: auto
      }
      
      .icon-line.pull-dark.push-top {
          top: 25%
      }
      
      .spacer-x-large {
          width: 100%;
          height: 16rem
      }
      
      .icon-flick {
          width: 7rem;
          position: absolute
      }
      
      .icon-flick.one {
          z-index: 3
      }
      
      .icon-flick.two {
          z-index: 2
      }
      
      .icon-flick.three {
          z-index: 1
      }
      
      .text-black {
          color: #1a1a1a;
          font-style: normal
      }
      
      .text-width-services {
          width: 65vw;
          max-width: 1200px;
          margin-left: auto;
          position: relative
      }
      
      .text-width-services.right {
          flex-direction: column;
          align-items: flex-end;
          display: flex
      }
      
      .dark-fade {
          color: #1a1a1a
      }
      
      .text-width-service-in {
          width: 41.3rem;
          line-height: 2px
      }
      
      .drop-down-parent {
          width: 100%;
          cursor: pointer;
          border-bottom: 1px #fff;
          flex-direction: column;
          align-items: center;
          margin-bottom: 1px;
          display: flex;
          overflow: hidden
      }
      
      .drop-down-top {
          width: 100%;
          height: 80px;
          min-height: 80px;
          justify-content: space-between;
          align-items: center;
          transition: padding .2s;
          display: flex;
          position: relative
      }
      
      .drop-down-top:hover {
          padding-left: 1rem
      }
      
      .drop-down-content {
          width: 100%;
          padding: 2rem
      }
      
      .list-item {
          justify-content: space-between;
          align-items: center;
          padding-top: .6rem;
          padding-bottom: .6rem;
          display: flex;
          position: relative;
          overflow: hidden
      }
      
      .caps-other {
          opacity: 1;
          text-transform: uppercase;
          font-size: 1rem
      }
      
      .caps-other.fade {
          color: rgba(255,255,255,.5)
      }
      
      .caps-other.text-black {
          color: #000;
          text-align: left;
          text-transform: uppercase;
          align-self: flex-start;
          display: block
      }
      
      .caps-other.text-black.hide {
          display: none
      }
      
      .caps-other.underline.text-black {
          text-transform: uppercase;
          text-decoration: none
      }
      
      .wrapper {
          display: block;
          position: relative;
          overflow: hidden
      }
      
      .icon-blend {
          z-index: 2;
          width: 28rem;
          mix-blend-mode: overlay;
          position: absolute
      }
      
      .text-width-out {
          z-index: 80;
          width: 74rem;
          text-align: center;
          mix-blend-mode: difference;
          margin-bottom: 10rem;
          margin-left: auto;
          margin-right: auto;
          position: relative
      }
      
      .img-text {
          z-index: 10;
          width: 30rem;
          text-align: center;
          position: absolute;
          top: 6vh
      }
      
      .img-text.heading-sm {
          text-transform: uppercase;
          margin-top: 0;
          margin-bottom: 0;
          padding-bottom: 10px;
        //   font-family: Helveticaneue,sans-serif
      }
      
      .bg-black {
          background-color: #070707
      }
      
      .footer {
          z-index: 1;
          padding-top: 4rem;
          padding-bottom: 10rem;
          position: relative
      }
      
      .footer.bg-black {
          display: block
      }
      
      .footer-parent {
          width: 29rem;
          justify-content: space-between;
          display: flex
      }
      
      .heading-xsm-ab {
          text-transform: uppercase;
          font-size: 1.4rem;
          line-height: 1.2;
          position: absolute;
          top: 0%;
          bottom: auto;
          left: 0%;
          right: auto
      }
      
      .foooter-from-parent {
          z-index: 2;
          width: 90%;
          height: 50rem;
          margin-left: auto;
          margin-right: auto;
          display: block;
          position: absolute;
          top: auto;
          bottom: 0%;
          left: 0%;
          right: 0%;
          overflow: hidden
      }
      
      .footer-top {
          z-index: 1;
          width: 100%;
          height: 6.4rem;
          justify-content: space-between;
          align-items: center;
          padding-left: 4rem;
          padding-right: 4rem;
          display: flex;
          position: relative
      }
      
      .icon-dark {
          width: 1rem;
          filter: brightness(0%);
          margin-bottom: 3px;
          margin-left: 5px
      }
      
      .form-parent {
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: flex-start;
          padding-top: 5rem;
          padding-bottom: 10rem;
          display: flex;
          position: absolute;
          top: 0%;
          bottom: auto;
          left: 0%;
          right: auto
      }
      
      .text-width-form {
          width: 44rem;
          text-align: center
      }
      
      .form-flex {
          grid-column-gap: 20px;
          grid-row-gap: 20px;
          display: flex
      }
      
      .form {
          height: 3rem;
          background-color: transparent;
          border: 1px #000;
          border-bottom: 1px solid #1a1a1a;
          border-radius: 0;
          margin-bottom: 0;
          padding-left: 0
      }
      
      .form::-ms-input-placeholder {
          color: #1a1a1a
      }
      
      .form::placeholder {
          color: #1a1a1a
      }
      
      .textarea {
          min-height: 12rem;
          background-color: transparent;
          border: 1px #000;
          border-bottom: 1px solid #1a1a1a;
          border-radius: 0;
          padding-top: 12px;
          padding-left: 0
      }
      
      .textarea::-ms-input-placeholder {
          color: #1a1a1a
      }
      
      .textarea::placeholder {
          color: #1a1a1a
      }
      
      .submit-button {
          height: 3rem;
          background-color: #1a1a1a;
          border-radius: 50px;
          margin-top: 2rem;
          padding-left: 24px;
          padding-right: 25px
      }
      
      .submit-button.right {
          float: right
      }
      
      .footer-from-child {
          width: 100%;
          height: 100%;
          background-color: #fff;
          border-top-left-radius: 50px;
          border-top-right-radius: 50px;
          display: block;
          position: absolute;
          top: auto;
          bottom: 0%;
          left: 0%;
          right: 0%
      }
      
      .click {
          cursor: pointer
      }
      
      .click.hide-btn {
          display: flex
      }
      
      .close {
          position: absolute;
          top: 0%;
          bottom: auto;
          left: 0%;
          right: auto
      }
      
      .menu-parent {
          align-items: center;
          display: flex
      }
      
      .menu-btn {
          width: 3.4rem;
          height: 3.4rem;
          cursor: pointer;
          border: 1px solid rgba(255,255,255,.2);
          border-radius: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
          transition: border-color .2s;
          display: flex
      }
      
      .menu-btn:hover {
          border-color: #fff
      }
      
      .menu-bar {
          width: 1.2rem;
          height: 1px;
          background-color: #fff;
          margin-top: 2px;
          margin-bottom: 2px
      }
      
      .primary-btn {
          height: 3.4rem;
          cursor: pointer;
          border-radius: 100px;
          justify-content: center;
          align-items: center;
          padding-left: 1.6rem;
          padding-right: 1.6rem;
          display: flex
      }
      
      .primary-btn.border {
          background-color: transparent;
          border: 1px solid rgba(255,255,255,.2);
          transition: border-color .2s
      }
      
      .primary-btn.border:hover {
          border-color: #fff
      }
      
      .sm-txt-ab {
          z-index: 1;
          text-align: center;
          text-transform: uppercase;
          font-size: 1.1rem;
          position: absolute;
          top: 0%;
          bottom: auto;
          left: 0%;
          right: 0%
      }
      
      .menu-parent-drop-down {
          z-index: 997;
          width: 100%;
          height: 100vh;
          background-color: #f1f1f1;
          flex-direction: column;
          justify-content: space-between;
          padding: 10rem 4rem 4rem;
          display: none;
          position: fixed;
          top: 0%;
          bottom: auto;
          left: 0%;
          right: auto
      }
      
      .menu-text {
          color: #1a1a1a;
          text-transform: uppercase;
          padding-top: 5px;
          font-size: 8rem;
          line-height: .95;
          display: inline-block
      }
      
      .arrow-top-menu {
          z-index: 3;
          width: 2.8rem;
          filter: brightness(0%);
          position: absolute;
          top: 0%;
          bottom: auto;
          left: auto;
          right: 0;
          overflow: hidden
      }
      
      .menu-line {
          width: 100%;
          height: 2px;
          background-color: #1a1a1a;
          position: absolute;
          top: auto;
          bottom: 0%;
          left: 0%;
          right: auto
      }
      
      .s-link {
          margin-left: 10px;
          transition: opacity .2s
      }
      
      .s-link:hover {
          opacity: .5
      }
      
      .success-message {
          height: 100%;
          text-transform: uppercase;
          background-color: #000;
          border-radius: 100px
      }
      
      .error-message {
          text-transform: uppercase;
          background-color: #ff1cdb;
          border-radius: 100px;
          margin-top: 2rem
      }
      
      .flex-inner {
          height: 100%;
          grid-row-gap: 2rem;
          flex-direction: column;
          display: flex
      }
      
      .fill {
          flex: 1
      }
      
      .grid-home {
          width: 100%;
          grid-column-gap: 2rem;
          grid-row-gap: 2rem;
          /* grid-template-rows: 40rem 40rem; */
          grid-template-columns: 1fr 1fr;
          grid-auto-rows: 40rem;
          grid-auto-columns: 1fr;
          display: grid
      }
      
      .caps-other-1 {
          opacity: 1;
          text-transform: uppercase
      }
      
      .caps-other-1.text-black.margin-left,.caps-other-1.margin-left {
          margin-left: 10px
      }
      
      .hero-work {
          width: 100%;
          padding-top: 11rem;
          padding-bottom: 10rem
      }
      
      .align-c {
          text-align: center
      }
      
      .tabs-menu {
          max-width: 46rem;
          text-align: center;
          margin-bottom: 4rem;
          margin-left: auto;
          margin-right: auto
      }
      
      .tab {
          color: rgba(255,255,255,.5);
          cursor: pointer;
          background-color: transparent;
          padding: 10px;
          font-size: 2rem;
          transition: color .4s
      }
      
      .tab:hover {
          color: #fff
      }
      
      .tab.w--current {
          color: #fff;
          background-color: transparent
      }
      
      .subscript {
        //   font-family: Sd georgia demo,sans-serif;
          font-size: 1.1rem;
          font-style: italic;
          font-weight: 300;
          bottom: 0;
          right: 4px
      }
      
      .outro {
          z-index: 999;
          width: 100%;
          height: 102vh;
          border-radius: 10px;
          justify-content: center;
          align-items: center;
          display: none;
          position: fixed;
          top: -1vh;
          bottom: auto;
          left: 0%;
          right: auto;
          overflow: hidden
      }
      
      .out-logo-parent {
          z-index: 10;
          justify-content: center;
          display: flex;
          position: relative;
          overflow: hidden
      }
      
      .hero-main-auto {
          width: 100%;
          padding-top: 12rem;
          padding-bottom: 6rem
      }
      
      .vimeo-player {
          z-index: 1;
          width: 100%;
          height: 100%;
          opacity: 1;
          border-radius: 10px;
          position: relative;
          overflow: hidden
      }
      
      .project-info {
          width: 100%;
          margin-top: -1rem;
          padding-top: 2rem;
          padding-bottom: 2rem;
          position: relative
      }
      
      .col-right {
          width: 53rem;
          float: right
      }
      
      .splide__arrow {
          width: 4rem;
          height: 4rem;
          background-color: transparent;
          background-image: url(https://uploads-ssl.webflow.com/633c3f6cb573b74f358e9feb/633c3f6cb573b73e688ea02d_arrow-right.svg);
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: 30%;
          border: 1px solid #d9d9e4;
          margin-left: -1px;
          transition: all .2s
      }
      
      .splide__arrow:hover {
          background-color: rgba(55,50,53,.06)
      }
      
      .splide__arrow.splide__arrow--prev {
          transform: rotate(-180deg)
      }
      
      .splide__arrow.splide__arrow--prev:active {
          transform: scale(.95)rotate(-180deg)
      }
      
      .splide__arrow.splide__arrow--next:active {
          transform: scale(.95)
      }
      
      .splide__arrows {
          justify-content: flex-end;
          align-items: stretch;
          margin-top: 2rem;
          display: none
      }
      
      .splide__slide {
          width: 67%;
          min-width: 67%;
          border-radius: 10px;
          flex-direction: column;
          flex: none;
          justify-content: space-between;
          align-items: center;
          display: flex;
          position: relative;
          overflow: hidden
      }
      
      .splide__list {
          height: 100%;
          justify-content: flex-start;
          align-items: stretch;
          display: flex
      }
      
      .splide {
          width: 100%;
          height: 42rem
      }
      
      .splide.slider1 {
          padding-left: 5%;
          overflow: hidden
      }
      
      .splide__track {
          width: 100%;
          height: 100%;
          cursor: grab;
          overflow: hidden
      }
      
      .cover-img {
          z-index: 1;
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: relative
      }
      
      .text-width-next {
          z-index: 10;
          width: 51rem;
          position: relative
      }
      
      .grid-next {
          grid-column-gap: 2rem;
          grid-row-gap: 2rem;
          grid-template-rows: 40rem;
          grid-template-columns: 1fr 1fr;
          grid-auto-rows: 40rem;
          grid-auto-columns: 1fr;
          display: grid
      }
      
      .mouse-overlay {
          z-index: 996;
          width: 100%;
          height: 100vh;
          justify-content: center;
          align-items: center;
          display: flex;
          position: fixed
      }
      
      .drag {
          width: 7rem;
          height: 7rem;
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          background-color: rgba(255,255,255,.6);
          border-radius: 100%;
          justify-content: center;
          align-items: center;
          display: flex
      }
      
      .text-width-contact {
          width: 69rem;
          position: relative
      }
      
      .text-width-service-in-other {
          width: 49.7rem
      }
      
      .img-dark {
          filter: brightness(0%)
      }
      
      .form-full {
          width: 100%
      }
      
      .ab-tr {
          position: absolute;
          top: 0%;
          bottom: auto;
          left: 0%;
          right: auto
      }
      
      .body-w {
          background-color: #fff
      }
      
      .blend-dif {
          mix-blend-mode: difference
      }
      
      .text-width-about {
          z-index: 10;
          text-align: left;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: flex;
          position: relative;
          bottom: 0
      }
      
      .other-text {
          color: #1a1a1a;
          text-align: left;
          font-style: italic;
          display: none;
          position: relative;
          top: 3.2rem
      }
      
      .container-small {
          z-index: 1;
          width: 43rem;
          max-width: 1860px;
          margin-left: 5%;
          margin-right: auto;
          position: relative;
          top: -5rem
      }
      
      .split-col {
          color: #1a1a1a;
          column-count: 2;
          column-gap: 20px;
        //   font-family: Helveticaneue,sans-serif;
          font-weight: 400
      }
      
      ._3-col-grid {
          grid-column-gap: 2rem;
          grid-row-gap: 2rem;
          grid-template-rows: auto;
          grid-template-columns: 1fr 1fr 1fr
      }
      
      .bg-whipe-pull {
          z-index: 0;
          width: 100%;
          height: 100%;
          background-color: #29ff65;
          display: block;
          position: absolute;
          top: 0%;
          bottom: auto;
          left: 0%;
          right: auto
      }
      
      .bg-whipe-pull.size {
          height: 23rem;
          top: auto;
          bottom: 0%;
          left: 0%;
          right: auto
      }
      
      .text-width-service-out {
          width: 47rem;
          text-indent: 9.7rem;
          margin-left: auto;
          line-height: 2px;
          position: relative
      }
      
      ._3-col-grid-alt {
          width: 100%;
          grid-column-gap: 2rem;
          grid-row-gap: 0px;
          grid-template-rows: auto;
          grid-template-columns: 1fr 1fr 1fr;
          margin-top: 2rem
      }
      
      .plus-parent {
          z-index: 3;
          width: 3rem;
          height: 3rem;
          cursor: pointer;
          border-radius: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          position: absolute;
          top: 2rem;
          bottom: auto;
          left: auto;
          right: 2rem
      }
      
      .plus-parent:hover {
          opacity: .8
      }
      
      .plus-parent.bg-yellow {
          transition: opacity .2s
      }
      
      .bg-whipe-top {
          z-index: 2;
          width: 100%;
          height: 100%;
          background-color: #1a1a1a;
          border-radius: 0 0 10px 10px;
          align-items: flex-end;
          padding: 1.7rem;
          display: flex;
          position: absolute;
          top: 0%;
          bottom: auto;
          left: 0%;
          right: auto
      }
      
      .utility-page-wrap {
          width: 100vw;
          height: 100vh;
          max-height: 100%;
          max-width: 100%;
          justify-content: center;
          align-items: center;
          display: flex
      }
      
      .utility-page-content {
          max-width: 30rem;
          grid-column-gap: 2rem;
          grid-row-gap: 2rem;
          text-align: center;
          flex-direction: column;
          align-items: center;
          display: flex
      }
      
      .split-title-w {
          grid-column-gap: .75rem;
          grid-row-gap: .75rem;
          flex-direction: row;
          align-items: center;
          margin-bottom: .5rem;
          display: flex
      }
      
      .grid-home-w {
          width: 100%
      }
      
      .grid-home-item {
          width: 100%;
          height: 100%
      }
      
      .empty-state {
          color: #1a1a1a;
          text-align: center;
          text-transform: uppercase;
          background-color: #fff;
          border-radius: 100vw
      }
      
      .work-prev {
          opacity: 0;
          display: none
      }
      
      .pagination-btn {
          z-index: 10;
          width: 14rem;
          height: 14rem;
          float: right;
          cursor: pointer;
          background-color: transparent;
          border-style: none;
          border-radius: 0;
          justify-content: center;
          align-items: center;
          display: flex;
          position: relative
      }
      
      .pagination-btn.work-next {
          color: #fff;
          background-color: transparent;
          border-style: none;
          border-radius: 0;
          margin: 5rem 0 2rem;
          padding: 0
      }
      
      .work-bg-whipe {
          z-index: 5;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          transition: transform 1s cubic-bezier(.165,.84,.44,1);
          position: absolute;
          top: auto;
          bottom: 0%;
          left: auto;
          right: auto
      }
      
      .flex-animation {
          align-items: center;
          transition: transform .5s cubic-bezier(.165,.84,.44,1);
          display: flex;
          transform: translateY(110%)
      }
      
      .heading-sm-animate {
          font-size: 2.4rem;
          line-height: 1.1
      }
      
      .heading-sm-animate.lowercase {
          transition: transform .5s cubic-bezier(.165,.84,.44,1);
          transform: translateY(110%)
      }
      
      .arrow-animate {
          transition: transform 1s cubic-bezier(.165,.84,.44,1);
          transform: translate(-100%,100%)
      }
      
      .overlay-animate {
          z-index: 2;
          width: 100%;
          height: 100%;
          opacity: 0;
          background-color: rgba(26,26,26,.4);
          transition: opacity .5s;
          position: absolute;
          top: auto;
          bottom: 0%;
          left: 0%;
          right: auto
      }
      
      .flex-contact {
          flex-direction: column;
          align-items: flex-start;
          display: flex
      }
      
      .text-width-about-sm {
          width: 35rem;
          text-indent: 7.8rem
      }
      
      .marquee-wrapper {
          width: 100%;
          height: 280px;
          position: relative;
          overflow: hidden
      }
      
      .marquee-wrapper.border-btm {
          border-bottom: 1px solid rgba(255,255,255,.2)
      }
      
      .marquee-track {
          height: 100%;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          display: flex;
          position: absolute
      }
      
      .marquee-list {
          height: 100%;
          border-top: 1px solid rgba(0,0,0,.2);
          flex-flow: row;
          justify-content: flex-start;
          align-items: stretch;
          display: flex
      }
      
      .marquee-item {
          width: 18rem;
          height: 100%;
          border-right: 1px solid rgba(0,0,0,.2);
          flex-direction: row;
          flex: none;
          justify-content: center;
          align-items: center;
          display: flex;
          position: relative
      }
      
      .icon-img {
          width: 100%;
          height: 100%;
          background-image: url(https://uploads-ssl.webflow.com/633c3f6cb573b74f358e9feb/633c3f6cb573b7b4d28ea068_lazy.jpg);
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: 50%
      }
      
      .grid-next-new {
          width: 100%;
          grid-column-gap: 2rem;
          grid-row-gap: 2rem;
          grid-template-rows: 40rem;
          grid-template-columns: 1fr 1fr;
          grid-auto-rows: 40rem;
          grid-auto-columns: 1fr;
          display: grid
      }
      
      .border-line-btm-1 {
          width: 100%;
          height: 2px;
          position: absolute;
          top: auto;
          bottom: 0%;
          left: 0%;
          right: auto
      }
      
      .text-width-warehouse {
          width: 33rem;
          margin-left: auto;
          margin-right: auto;
          display: none
      }
      
      .text-span {
          color: #ff1cdb;
          text-transform: uppercase;
          font-style: normal
      }
      
      .text-span-2 {
          color: #dfff1c;
          text-decoration: underline
      }
      
      .heading {
          font-size: 8rem;
          color: white;
      
      }
      
      .move {
          transform: translateY(-100%)
      }
      
      .italic-text {
          font-style: italic
      }
      
      .italic-text-2 {
          color: #000
      }
      
      .italic-text-3 {
          font-style: normal
      }
      
      .bold-text {
          display: none
      }
      
      @media screen and (max-width: 991px) {
          h1 {
              font-size:7.5rem
          }
      
          h2 {
              font-size: 4.6rem
          }
      
          h3 {
              font-size: 2rem
          }
      
          h4 {
              font-size: 1.4rem
          }
      
          p {
              font-size: 17px
          }
      
          .heading-lg {
              font-size: 7rem
          }
      
          .heading-lg.hero {
              font-size: 7.5rem
          }
      
          .heading-md {
              font-size: 4.6rem
          }
      
          .heading-sm {
              font-size: 1.9rem
          }
      
          .heading-xsm {
              font-size: 1.4rem
          }
      
          .p-lg {
              font-size: 2.4rem
          }
      
          .div-hide.margin-left {
              margin-left: 14px
          }
      
          .div-hide.ab-right {
              bottom: -7.5rem
          }
      
          .div-hide.push-right {
              margin-left: 0
          }
      
          .div-hide.push-left {
              margin-right: 0
          }
      
          .intro-logo-two {
              width: 200px
          }
      
          .hero-main {
              height: 100vh
          }
      
          .container-large.footer-section {
              padding-top: 10rem
          }
      
          .text-width-hero {
              width: 100%
          }
      
          .nav {
              padding-top: 1.4rem;
              padding-bottom: 1.4rem
          }
      
          .video-html5.profile {
              height: 50rem
          }
      
          .border-line-btm {
              height: 1px
          }
      
          .hero-wrap {
              width: 90%;
              height: 84%
          }
      
          .parent.width {
              width: 50%;
              left: 0%
          }
      
          .parent.width-one {
              width: 30rem;
              height: 19rem
          }
      
          .parent.width-two {
              width: 16rem;
              height: 12rem
          }
      
          .parent.width-three {
              width: 19rem;
              height: 12rem
          }
      
          .parent.size-p {
              height: 15rem
          }
      
          .parent.btn {
              top: -110px
          }
      
          .parent.width-four {
              width: 20rem;
              height: 13rem;
              bottom: 5%;
              right: 0%
          }
      
          .parent.info-text {
              padding-right: 0
          }
      
          .section.margin-bottom-small {
              margin-bottom: 4rem
          }
      
          .spacer-large,.spacer-large.sm {
              height: 8rem
          }
      
          .spacer-large.lg {
              height: 13rem
          }
      
          .text-width-intro {
              width: 44rem
          }
      
          .icon-text {
              height: 2.2rem
          }
      
          .icon-text.icon-pink {
              width: 3rem
          }
      
          .text-width-work {
              width: 290px
          }
      
          .grid-home-alt {
              grid-template-rows: 32em auto;
              grid-template-columns: 1fr 1fr
          }
      
          .arrow-top {
              display: none
          }
      
          .spacer-medium {
              height: 4rem
          }
      
          .text-width-stament {
              width: 100%;
              left: 0
          }
      
          .spacer-x-large {
              height: 10rem
          }
      
          .icon-flick {
              width: 4rem
          }
      
          .text-width-services {
              width: 100%
          }
      
          .drop-down-top,.drop-down-top:hover {
              padding-left: 0;
              padding-right: 0
          }
      
          .text-width-out {
              width: 90%;
              margin-bottom: 8rem
          }
      
          .img-text {
              width: 26rem;
              top: 8vh
          }
      
          .footer-parent {
              width: 22rem
          }
      
          .heading-xsm-ab {
              font-size: 1.4rem
          }
      
          .menu-parent-drop-down {
              padding-left: 2.5rem
          }
      
          .menu-text {
              font-size: 4rem
          }
      
          .grid-home {
              /* grid-template-rows: 25rem 22rem; */
              grid-template-columns: 1fr 1fr;
              grid-auto-rows: 25rem
          }
      
          .hero-main-auto {
              padding-bottom: 4rem
          }
      
          .col-right {
              width: 100%;
              float: left
          }
      
          .splide__slide {
              width: 80%;
              min-width: 80%
          }
      
          .splide.slider1 {
              height: 28rem
          }
      
          .text-width-next {
              width: 100%;
              margin-left: 0
          }
      
          .grid-next {
              grid-template-rows: 22em;
              grid-template-columns: 1fr 1fr;
              grid-auto-rows: 22em
          }
      
          .mouse-overlay {
              display: none
          }
      
          .text-width-contact,.text-width-service-in-other {
              width: 100%
          }
      
          .ab-tr {
              position: relative
          }
      
          .text-width-about {
              margin-bottom: 120px
          }
      
          .container-small {
              width: 37.1rem;
              margin-bottom: 6rem;
              top: 0
          }
      
          .text-width-service-out {
              width: 35rem
          }
      
          .heading-sm-animate {
              font-size: 2rem
          }
      
          .text-width-about-sm {
              width: 26.6rem
          }
      
          .marquee-wrapper {
              height: 200px
          }
      
          .marquee-item {
              width: 14rem
          }
      
          .icon-img {
              background-size: 60%
          }
      
          .grid-next-new {
              grid-template-rows: 25rem 22rem;
              grid-template-columns: 1fr 1fr;
              grid-auto-rows: 25rem
          }
      
          .border-line-btm-1 {
              height: 1px
          }
      }
      
      @media screen and (max-width: 767px) {
          h1 {
              font-size:5.8rem
          }
      
          h2 {
              font-size: 3.4rem
          }
      
          h3 {
              font-size: 1.9rem
          }
      
          h4 {
              font-size: 1.4rem
          }
      
          .heading-lg {
              padding-top: 0;
              padding-bottom: 5px;
              font-size: 5.4rem
          }
      
          .heading-lg.hero {
              font-size: 5.4rem
          }
      
          .heading-md {
              font-size: 3.4rem
          }
      
          .heading-sm {
              font-size: 1.3rem
          }
      
          .p-lg {
              font-size: 2.3rem
          }
      
          .hero-main {
              height: 800px
          }
      
          .container-large.footer-section {
              padding-bottom: 10rem
          }
      
          .flex-split.mobile {
              grid-row-gap: 10px;
              text-align: center;
              flex-direction: column;
              align-items: center
          }
      
          .video-html5.profile {
              height: 42rem
          }
      
          .border-line-btm.show-m {
              background-color: rgba(0,0,0,.2);
              display: block
          }
      
          .icon-spin {
              width: 10rem;
              bottom: -5rem;
              right: 3rem
          }
      
          .hero-wrap {
              width: 100%;
              height: 85%
          }
      
          .parent.width {
              height: 12rem
          }
      
          .parent.width-one {
              width: 26rem;
              height: 16rem
          }
      
          .parent.width-two {
              width: 14rem;
              height: 11rem
          }
      
          .parent.width-three {
              width: 16rem;
              height: 10rem
          }
      
          .parent.width-four {
              height: 780px;
              width: 14rem;
              height: 9rem
          }
      
          .parent.width-five {
              height: 780px
          }
      
          .parent.info-text {
              padding-bottom: 1.5rem
          }
      
          .parent.info-text.hide-m,.parent.hide-m,.scroll-parent {
              display: none
          }
      
          .spacer-large,.spacer-large.sm {
              height: 6rem
          }
      
          .spacer-large.lg {
              height: 12rem
          }
      
          .text-width-intro {
              width: 27rem
          }
      
          .icon-text {
              width: 3.7rem
          }
      
          .text-width-work {
              width: 21rem
          }
      
          .spacer-small {
              padding-bottom: 2rem
          }
      
          .spacer-medium {
              height: 3rem
          }
      
          .body-c {
              letter-spacing: -.3px
          }
      
          .text-width-service-in,.icon-blend {
              width: 26rem
          }
      
          .text-width-out {
              margin-bottom: 6rem
          }
      
          .img-text {
              width: 22rem;
              top: 7vh
          }
      
          .text-width-form {
              width: 90%
          }
      
          .menu-parent-drop-down {
              padding-bottom: 8rem;
              padding-left: 2rem
          }
      
          .menu-text {
              font-size: 3.5rem
          }
      
          .grid-home {
              grid-column-gap: 1.25rem;
              grid-row-gap: 1.25rem;
              /* grid-template-rows: 20em 20em; */
              grid-auto-rows: 20em
          }
      
          .tabs-menu {
              width: 90%
          }
      
          .tab {
              padding-top: 8px;
              padding-bottom: 8px
          }
      
          .grid-next {
              grid-template-rows: 16em;
              grid-auto-rows: 16em
          }
      
          .text-width-service-in-other {
              width: 100%
          }
      
          .body-w {
              letter-spacing: -.3px
          }
      
          .text-width-about {
              margin-bottom: 90px
          }
      
          .container-small {
              width: 88%;
              margin-bottom: 4rem
          }
      
          .split-col {
              column-count: 1
          }
      
          .text-width-service-out {
              width: 35rem
          }
      
          ._3-col-grid-alt {
              margin-top: 3rem
          }
      
          .split-title-w {
              align-items: flex-end
          }
      
          .heading-sm-animate {
              font-size: 1.8rem
          }
      
          .icon-img {
              background-size: 65%
          }
      
          .grid-next-new {
              grid-column-gap: 1.25rem;
              grid-row-gap: 1.25rem;
              grid-template-rows: 16em 16em;
              grid-auto-rows: 16em
          }
      
          .heading {
              font-size: 7rem
          }
      }
      
      @media screen and (max-width: 479px) {
          h1 {
              font-size:3.3rem
          }
      
          h2 {
              font-size: 2.2rem
          }
      
          h3 {
              font-size: 1.7rem
          }
      
          h4 {
              font-size: 1.2rem
          }
      
          p {
              font-size: 16px
          }
      
          .heading-lg {
              font-size: 13vw
          }
      
          .heading-lg.hero {
              font-size: 16vw
          }
      
          .heading-md {
              font-size: 2.2rem
          }
      
          .heading-sm,.heading-sm.lg {
              font-size: 1.5rem
          }
      
          .heading-sm.lowercase-intro {
              font-size: 1.1rem
          }
      
          .heading-xsm {
              font-size: 1.2rem
          }
      
          .p-lg {
              font-size: 1.6rem
          }
      
          .div-hide.flex-split.hide {
              display: none
          }
      
          .div-hide.margin-left {
              margin-left: 14px
          }
      
          .div-hide.ab-right {
              bottom: -1.8rem
          }
      
          .div-hide.margin-btm {
              margin-bottom: 10px
          }
      
          .hero-main {
              height: 720px;
              padding-top: 11rem
          }
      
          .container-large.footer-section {
              padding-top: 10rem;
              padding-bottom: 10rem
          }
      
          .container-large.footer-btm {
              flex-direction: column
          }
      
          .flex-split.btm.v {
              flex-direction: column;
              align-items: flex-start
          }
      
          .video-html5.profile {
              height: 25rem
          }
      
          .icon-spin {
              width: 10rem;
              display: none;
              bottom: -5rem;
              right: 2rem
          }
      
          .hero-wrap {
              height: 70%;
              align-items: flex-start
          }
      
          .parent.work {
              padding-bottom: 1rem;
              padding-left: .8rem;
              padding-right: .8rem
          }
      
          .parent.width {
              width: 60%;
              height: 9rem
          }
      
          .parent.width-one {
              width: 15rem;
              height: 10rem;
              top: 16%
          }
      
          .parent.width-two {
              width: 9rem;
              height: 6rem;
              top: 76%
          }
      
          .parent.width-three {
              width: 11rem;
              height: 7rem;
              top: 115%;
              right: 20%
          }
      
          .parent.width-four {
              height: 700px
          }
      
          .parent.btn {
              top: -110px
          }
      
          .parent.width-four {
              width: 12rem;
              height: 8rem;
              bottom: 8%
          }
      
          .parent.width-five {
              height: 700px
          }
      
          .section.margin-bottom-small {
              margin-top: 2rem
          }
      
          .spacer-large,.spacer-large.sm {
              height: 4rem
          }
      
          .spacer-large.lg {
              height: 10rem
          }
      
          .text-width-intro {
            //   width: 18.6rem;
              text-indent: 1.8rem
          }
      
          .icon-text {
              width: 2.8rem;
              height: 1.8rem
          }
      
          .icon-text.icon-pink {
              width: 2.2rem;
              height: 1.5rem;
              margin-bottom: -2px
          }
      
          .icon-text.icon-green {
              width: 2.5rem;
              height: 1.6rem
          }
      
          .text-width-work {
              width: 19rem
          }
      
          .spacer-small {
              padding-bottom: 1.4rem
          }
      
          .spacer-medium {
              height: 3rem
          }
      
          .spacer-medium.more {
              height: 4rem
          }
      
          .main-btn {
              width: 10rem;
              height: 10rem;
              margin-left: auto;
              margin-right: auto
          }
      
          .icon-line.pull-dark {
              width: 100%
          }
      
          .icon-line.pull-dark.push-top {
              display: none
          }
      
          .spacer-x-large {
              height: 11rem
          }
      
          .spacer-x-large.add {
              height: 15rem
          }
      
          .icon-flick {
              width: 5rem
          }
      
          .text-width-service-in {
              width: 16rem
          }
      
          .drop-down-content {
              padding-bottom: 1.8rem;
              padding-left: 1.3rem;
              padding-right: 1.3rem
          }
      
          .caps-other {
              font-size: 14px
          }
      
          .icon-blend {
              width: 17rem
          }
      
          .text-width-out {
              margin-bottom: 4rem
          }
      
          .img-text {
              width: 18rem;
              top: 8vh
          }
      
          .footer-parent {
              width: 16.2rem;
              margin-bottom: 2em
          }
      
          .heading-xsm-ab {
              font-size: 1.2rem
          }
      
          .foooter-from-parent {
              height: 48rem
          }
      
          .footer-top {
              flex-direction: column;
              justify-content: center;
              padding-left: 0;
              padding-right: 0
          }
      
          .text-width-form {
              width: 90%
          }
      
          .footer-from-child {
              border-top-left-radius: 30px;
              border-top-right-radius: 30px
          }
      
          .primary-btn.border.hide-m {
            //   display: none
          }
      
          .menu-parent-drop-down {
              padding-left: 1.2rem
          }
      
          .menu-text {
              font-size: 3rem
          }
      
          .grid-home {
              grid-column-gap: 1.25rem;
              grid-row-gap: 1.25rem;
              grid-template-rows: 25em 5em;
              grid-auto-rows: 25em
          }
      
          .hero-work {
              padding-bottom: 8rem
          }
      
          .tabs-menu {
              width: 100%;
              margin-bottom: 2rem
          }
      
          .tab {
              padding-top: 6px;
              padding-bottom: 6px;
              font-size: 1.6rem;
              display: inline-block
          }
      
          .splide.slider1 {
              height: 17rem;
              padding-left: 1.2rem
          }
      
          .cover-img {
              width: 100%;
              max-width: 100%;
              min-width: 104%
          }
      
          .grid-next {
              grid-column-gap: 1.25rem;
              grid-row-gap: 1.25rem;
              grid-template-rows: 12em 12em;
              grid-auto-rows: 12em
          }
      
          .text-width-about {
              text-align: left;
              margin-bottom: 100px
          }
      
          .other-text {
              width: 150px;
              top: 2.8rem
          }
      
          .split-col {
              column-count: 1
          }
      
          .bg-whipe-pull.size {
              height: 17rem
          }
      
          .text-width-service-out {
              width: 21rem;
              text-indent: 4.2rem
          }
      
          .bg-whipe-top {
              padding-left: 1.2rem;
              padding-right: 1.2rem
          }
      
          .scroll-child {
              max-height: 320px
          }
      
          .split-title-w {
              grid-column-gap: .5rem;
              grid-row-gap: .5rem
          }
      
          .pagination-btn {
              width: 10rem;
              height: 10rem;
              margin-left: auto;
              margin-right: auto
          }
      
          .heading-sm-animate {
              font-size: 1.5rem
          }
      
          .text-width-about-sm {
              width: 20rem;
              margin-left: auto;
              margin-right: auto
          }
      
          .marquee-wrapper {
              height: 180px
          }
      
          .marquee-item {
              width: 14rem
          }
      
          .grid-next-new {
              grid-column-gap: 1.25rem;
              grid-row-gap: 1.25rem;
              grid-template-rows: 25em 25em;
              grid-auto-rows: 25em
          }
      
          .heading {
              font-size: 6rem
          }
      }
      
      #w-node-_81f49f79-d8ea-e448-f1d2-ddb0d043c99f-ead8522c,#w-node-c96f202b-1b5f-e718-77bf-39f1eb1284bb-ead8522c,#w-node-_933f6d18-b6a6-591a-50fc-7da734eede1f-ead8522c,#w-node-_42200cb9-f482-f023-3c49-34b20309c80c-ead8522c,#w-node-da30ae69-fa1b-e35e-e5a9-ca23b401b282-ead8522c,#w-node-_26b75957-6243-9ee9-a943-5b7d9b611c92-ead8522c,#w-node-e37105fe-d60c-f55d-ea37-2cd955e0dbed-ead8522c,#w-node-e37105fe-d60c-f55d-ea37-2cd955e0dbee-ead8522c,#w-node-d2e10e91-cfa9-4394-2bda-1d5c01fb3143-ead8522c,#w-node-a8b717b5-2cfb-b025-1152-a8ad4bd65230-ead8522c,#w-node-a8b717b5-2cfb-b025-1152-a8ad4bd65231-ead8522c,#w-node-_0701d923-31cc-8070-bd82-059cd6449c96-ead8522c,#w-node-_0701d923-31cc-8070-bd82-059cd6449c97-ead8522c,#w-node-dd9efa57-e6dc-e4d0-fbbe-1ce3bcc90b4a-ead8522c,#w-node-_0275a601-31f9-21cc-9f7e-2a097974152f-ead8522c,#w-node-a21658ae-b292-2a59-59c6-1c56340f5b34-ead8522c,#w-node-d8c6cb05-469c-f62e-bc46-fbbc145831e7-ead8522c,#w-node-d8c6cb05-469c-f62e-bc46-fbbc145831ea-ead8522c,#w-node-d8c6cb05-469c-f62e-bc46-fbbc145831eb-ead8522c,#w-node-d8c6cb05-469c-f62e-bc46-fbbc145831ee-ead8522c,#w-node-d8c6cb05-469c-f62e-bc46-fbbc145831ef-ead8522c,#w-node-d8c6cb05-469c-f62e-bc46-fbbc145831f2-ead8522c,#w-node-d8c6cb05-469c-f62e-bc46-fbbc145831f3-ead8522c,#w-node-d8c6cb05-469c-f62e-bc46-fbbc145831f4-ead8522c,#w-node-d8c6cb05-469c-f62e-bc46-fbbc145831f7-ead8522c,#w-node-d8c6cb05-469c-f62e-bc46-fbbc145831f8-ead8522c,#w-node-d8c6cb05-469c-f62e-bc46-fbbc145831fb-ead8522c,#w-node-d8c6cb05-469c-f62e-bc46-fbbc145831fc-ead8522c,#w-node-d8c6cb05-469c-f62e-bc46-fbbc14583200-ead8522c,#w-node-a69e1949-f86e-49f5-fc68-74d432148cf9-91d85232 {
          grid-area: span 1/span 1/span 1/span 1
      }
      
      #w-node-a69e1949-f86e-49f5-fc68-74d432148d1c-91d85232 {
          grid-area: span 1/span 2/span 1/span 2
      }
      
      @media screen and (max-width: 991px) {
          #w-node-_81f49f79-d8ea-e448-f1d2-ddb0d043c99f-ead8522c,#w-node-c96f202b-1b5f-e718-77bf-39f1eb1284bb-ead8522c,#w-node-_933f6d18-b6a6-591a-50fc-7da734eede1f-ead8522c {
              grid-area:span 1/span 3/span 1/span 3
          }
      
          #w-node-a69e1949-f86e-49f5-fc68-74d432148cfa-91d85232 {
              grid-area: span 3/span 1/span 3/span 1
          }
      
          #w-node-_8d26bfc3-dce4-1907-4fc7-b66fc46a0f1f-08d85234,#w-node-_8d26bfc3-dce4-1907-4fc7-b66fc46a0f32-08d85234 {
              grid-area: span 1/span 1/span 1/span 1
          }
      }
      
      @media screen and (max-width: 767px) {
          #w-node-_42200cb9-f482-f023-3c49-34b20309c80c-ead8522c,#w-node-da30ae69-fa1b-e35e-e5a9-ca23b401b282-ead8522c,#w-node-e37105fe-d60c-f55d-ea37-2cd955e0dbed-ead8522c,#w-node-a8b717b5-2cfb-b025-1152-a8ad4bd65230-ead8522c,#w-node-_0701d923-31cc-8070-bd82-059cd6449c96-ead8522c,#w-node-_0275a601-31f9-21cc-9f7e-2a097974152f-ead8522c,#w-node-a21658ae-b292-2a59-59c6-1c56340f5b34-ead8522c,#w-node-d8c6cb05-469c-f62e-bc46-fbbc145831e7-ead8522c,#w-node-d8c6cb05-469c-f62e-bc46-fbbc145831ea-ead8522c,#w-node-d8c6cb05-469c-f62e-bc46-fbbc145831ee-ead8522c,#w-node-d8c6cb05-469c-f62e-bc46-fbbc145831f3-ead8522c,#w-node-d8c6cb05-469c-f62e-bc46-fbbc145831f7-ead8522c,#w-node-d8c6cb05-469c-f62e-bc46-fbbc145831fc-ead8522c,#w-node-d8c6cb05-469c-f62e-bc46-fbbc14583200-ead8522c {
              grid-area:span 1/span 3/span 1/span 3
          }
      }
      
      
   
      /* <style> */
          .img-parent {
          -webkit-mask-image: -webkit-radial-gradient(white, black);
          }
          .w-input:focus {
          border-color: #000;
          }
            
          @media only screen and (min-width: 1024px) and (max-width: 1440px){
            .heading-lg.hero {
              font-size: 10rem;
            }
            .heading-lg{
              font-size: 10rem;
            }
          
          }
            
            @media only screen and (min-width: 992px) and (max-width: 1024px){
              
             .heading-lg.hero {
              font-size: 9rem;
            }
            .heading-lg{
              font-size: 9rem;
            }
              .text-width-stament {
                left: 0rem;
              }
              .parent.width-one {
                    width: 40rem;
              height: 26rem;
              }
              .grid-home {
                 grid-auto-rows: 35rem;
                 /* grid-template-rows: 35rem 35rem; */
              }
              .text-width-out {
                width: 55rem;
              }
              h2 {
                font-size: 5rem;
              }
              .spacer-large.sm {
                height: 10rem;
              }
              .spacer-large {
                 height: 8rem;
              }
              .parent.width-four {
                bottom: 10%;
              }
          }
          /* </style> */
        
          /* </head> */
         
            html { font-size: calc(0.625rem + 0.41666666666666663vw); }
            @media screen and (max-width:1920px) { html { font-size: calc(0.625rem + 0.41666666666666674vw); } }
            @media screen and (max-width:1440px) { html { font-size: calc(0.8126951092611863rem + 0.20811654526534862vw); } }
            @media screen and (max-width:479px) { html { font-size: calc(0.7494769874476988rem + 0.8368200836820083vw); } } 
          body {
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
          }
          
       
          
          .outline {
              color: white;
              -webkit-text-fill-color: rgba(255, 255, 255, 0);
              /* Will override color (regardless of order) */
              -webkit-text-stroke-width: 1px;
              -webkit-text-stroke-color: #fff;
          }
          .parent.menu:hover {
          color: white;
              -webkit-text-fill-color: rgba(255, 255, 255, 0);
              /* Will override color (regardless of order) */
              -webkit-text-stroke-width: 1px;
              -webkit-text-stroke-color: #1a1a1a;
          }
          
          @media only screen and (min-width: 1440px) and (max-width: 1540px){
          .icon-blend {
          max-width: 25rem;
          }
          .hero-main {
          height: 125vh;
          }
          }
          /* </style> */
          
          /* <style> */
          /*styles for work grid*/
          
              .grid-home-item:nth-child(3n+0) {
                grid-column: 1 / span 2;
              }
            
            @media only screen and (max-width: 479px) {
              .grid-home-item {
                grid-column: 1 / span 2;
              }
              }
            
            .grid-home-item:nth-child(3n+0) > .parent > .bg-whipe-right{  /*third*/
                background-color: #dfff1c; /*yellow*/
              }
              
            .grid-home-item:nth-child(3n-2) > .parent > .bg-whipe-right{  /*first*/
                /* background-color: #ff1cdb;  */
              }
            
            .grid-home-item:nth-child(3n-1) > .parent > .bg-whipe-right{  /*second*/
                /* background-color: #29ff65;  */
              }
            
          
          
          /* </style> */
          
          /* <style> */
          .marquee-track {
            position: absolute;
            white-space: nowrap;
            will-change: transform;
            animation: marquee-horizontal 20s linear infinite;
            /* manipulate the speed of the marquee by changing "40s" line above*/
          }
          
          .marquee-track-other {
            position: absolute;
            white-space: nowrap;
            will-change: transform;
            animation: marquee-horizontal-other 20s linear infinite;
            /* manipulate the speed of the marquee by changing "40s" line above*/
          }
          @keyframes marquee-horizontal-other {
            from { transform: translateX(0%); }
            to { transform: translateX(-50%); }
          }
          
          
          
          @keyframes marquee-horizontal {
            from { transform: translateX(-50%); }
            to { transform: translateX(0%); }
          }
          .html{
            background:black;
          }
          @media screen and (max-width: 767px){
          .span-text {
            margin-right: 5px;
             /* Adjust this value to increase/decrease the space */
          }
        }
          @media screen and (max-width: 767px)

.span-text {
    margin-right: 0px;
     /* Adjust this value to increase/decrease the space */
  }
          /* </style> */


          @media (max-width: 860px) {
	
            .logo-home {
                width: 15rem
            }
        }
        /* Previous CSS styles */

/* Media query for screens with a maximum width of 700px */
@media (max-width: 700px) {
  .grid-home-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    width: 100%; /* Adjust the width as needed */
  }
}

         
        
        `}
      </style>
      <div className="html">
        <nav class="nav">
          <div class="container-large flex-split">
            <a
              aria-label="home"
              data-w-id="f9ff9994-2723-c346-565a-25c9369a4913"
              href="/"
              aria-current="page"
              class="home w-inline-block w--current"
            >
              <img src={wlogo} loading="eager" alt="" class="logo-home" />
            </a>
            <div class="menu-parent">
              <Link
                data-w-id="f9ff9994-2723-c346-565a-25c9369a4916"
                to="/contact"
                class="primary-btn border hide-m w-inline-block"
                onClick={ahandleButtonClick}
              >
                <div class="div-hide" >
                  <div class="sm-txt">Say Hello</div>
                  <div class="sm-txt-ab">Say Hello</div>
                </div>
              </Link>
            </div>
          </div>
        </nav>

        <div class="wrapper newhomwhe">
          
          <div class="text-width-hero">
          {/* <div className="text-width-hero"> */}
      <div className="div-hide">
        <div className="heading-lg hero">
          <span className={`span-text intro-one ${animate ? "rise-animation" : ""}`}>
            Unlocking
          </span>{" "}
          <span className={`span-text intro-one ${animate ? "rise-animation" : ""}`}>
            your
          </span>{" "}
          <span className={`span-text intro-one ${animate ? "rise-animation" : ""}`}>
          Potential,
          </span>{" "}
          
          {/* <span className={`span-text intro-two ${animate ? "rise-animation" : ""}`} style={{ marginTop: '-6rem', verticalAlign: 'middle',marginLeft:'1rem' }}>
            Your
          </span>
          <span className={`span-text intro-two ${animate ? "rise-animation" : ""}`} style={{ marginTop: '-6rem', verticalAlign: 'middle',marginLeft:'1rem' }}>
          Potential,
          </span> */}
        </div>
      </div>
    {/* </div> */}
            <div class="div-hide inline">
              <div class="heading-lg hero">
                <span class="span-text intro-three">together.</span>
              </div>
              <div class="border-line-btm bg-white"></div>
            </div>
          </div>
          <section class="section margin-bottom-small">
            <div class="container-large w-clearfix">
              <div class="flex-split mobile v">
                <h2
                  data-w-id="f752d877-a83a-b0e2-eae6-f7ef7630158d"
                  class="heading"
                ></h2>
                <div class="spacer-small"></div>
              </div>
              <div class="spacer-medium"></div>
              <div class="grid-home-w w-dyn-list">
                <div role="list" class="grid-home w-dyn-items">
                <Fade bottom>
                  <div role="listitem" class="grid-home-item w-dyn-item">
                  
                  <div role="listitem" class="grid-home-item w-dyn-item">
                    <Link
                      data-w-id="bfcf8f70-d84d-9c16-8d0d-55fcd6c9bd9f"
                     to="/home"
                      class="parent div-hide fill-parent video w-inline-block"
                      onClick={tophandleButtonClick}
                    >
                      <div class="bg-whipe-right"></div>
                      <div class="parent work">
                        <div class="div-hide">
                          <div class="split-title-w">
                            <h2
                              data-w-id="bfcf8f70-d84d-9c16-8d0d-55fcd6c9bda3"
                              class="heading-sm lowercase"
                            >
                             Web Design
                            </h2>
                            {/* <h2 class="heading-sm lowercase script">
                            Holiday Miracle
                          </h2> */}
                          </div>
                        </div>
                        <div class="div-hide">
                          <div
                            data-w-id="bfcf8f70-d84d-9c16-8d0d-55fcd6c9bda8"
                            class="flex"
                          >
                            <div class="dot bg-white"></div>
                            <div class="sm-txt intro-text" style={divStyle}>
                            Giving your business a voice
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="img-parent work lg">
                        <div class="arrow-top">
                          <img
                            src="https://uploads-ssl.webflow.com/633c3f6cb573b74f358e9feb/633c3f6cb573b74f238ea034_arrow.svg"
                            loading="lazy"
                            data-w-id="bfcf8f70-d84d-9c16-8d0d-55fcd6c9bdae"
                            alt=""
                            class="arrow"
                          />
                        </div>
                        <div
                          data-scroll-speed="-1"
                          data-scroll="1"
                          class="img-child scroll-img"
                          
                        >
                          <div
                            data-w-id="bfcf8f70-d84d-9c16-8d0d-55fcd6c9bdb0"
                            class="overlay"
                          ></div>
                          <img
                            alt="Westjet Holiday Miracle"
                            loading="lazy"
                            src="https://images.unsplash.com/photo-1541506618330-7c369fc759b5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1965&q=80"
                            sizes="(max-width: 479px) 90vw, 44vw"
                            srcset="https://images.unsplash.com/photo-1541506618330-7c369fc759b5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1965&q=80"
                            class="img-cover"
                          />
                          {/* <div class="video-html5 w-condition-invisible w-embed">
                            <video
                              poster=""
                              class="lazy lazyload"
                              playsinline
                              muted
                              loop
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            >
                              <source
                                data-src=""
                                type="video/mp4"
                                class="thevideo"
                              ></source>
                            </video>
                          </div> */}
                        </div>
                      </div>
                    </Link>
                  </div>
                  
                  </div>
                  </Fade>
                  <Fade bottom>
                  <div role="listitem" class="grid-home-item w-dyn-item">
                    <Link
                      data-w-id="bfcf8f70-d84d-9c16-8d0d-55fcd6c9bd9f"
                      href="#"
                      class="parent div-hide fill-parent video w-inline-block"
                      to="/erpcomingsoon"
                      onClick={topahandleButtonClick}
                    >
                      <div class="bg-whipe-right"></div>
                      <div class="parent work">
                        <div class="div-hide">
                          <div class="split-title-w">
                            <h2
                              data-w-id="bfcf8f70-d84d-9c16-8d0d-55fcd6c9bda3"
                              class="heading-sm lowercase"
                            >
                              Enterprise resource planning(ERP)
                            </h2>
                            {/* <h2 class="heading-sm lowercase script">
                            Holiday Miracle
                          </h2> */}
                          </div>
                        </div>
                        <div class="div-hide">
                          <div
                            data-w-id="bfcf8f70-d84d-9c16-8d0d-55fcd6c9bda8"
                            class="flex"
                          >
                            <div class="dot bg-white"></div>
                            <div class="sm-txt intro-text" style={divStyle}>
                            Simplifying business operations
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="img-parent work lg">
                        <div class="arrow-top">
                          <img
                            src="https://uploads-ssl.webflow.com/633c3f6cb573b74f358e9feb/633c3f6cb573b74f238ea034_arrow.svg"
                            loading="lazy"
                            data-w-id="bfcf8f70-d84d-9c16-8d0d-55fcd6c9bdae"
                            alt=""
                            class="arrow"
                          />
                        </div>
                        <div
                          data-scroll-speed="-1"
                          data-scroll="1"
                          class="img-child scroll-img"
                        >
                          <div
                            data-w-id="bfcf8f70-d84d-9c16-8d0d-55fcd6c9bdb0"
                            class="overlay"
                          ></div>
                          <img
                            alt="Westjet Holiday Miracle"
                            loading="lazy"
                            src="https://images.unsplash.com/photo-1616156027751-fc9a850fdc9b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
                            sizes="(max-width: 479px) 90vw, 44vw"
                            srcset="https://images.unsplash.com/photo-1616156027751-fc9a850fdc9b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
                            class="img-cover"
                          />
                          <div class="video-html5 w-condition-invisible w-embed">
                            <video
                              poster=""
                              class="lazy lazyload"
                              playsinline
                              muted
                              loop
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            >
                              <source
                                data-src=""
                                type="video/mp4"
                                class="thevideo"
                              ></source>
                            </video>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* <Newan/> */}
      </div>
    </>
  );
}

export default Newland;
