import React, { useState } from 'react';
import { Data } from './Data';
import styled from 'styled-components';
import { IconContext } from 'react-icons';
import { FiPlus, FiMinus } from 'react-icons/fi';

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 65vh;
  background: white;
  margin-top: 6rem;
  @media (max-width: 900px) {
    margin-top: 0rem;
  }
`;

const Container = styled.div`
  position: absolute;
  top: 30%;
  width: 100%;

  /* Apply right: 2rem for devices with a min-width of 900px */
  @media (min-width: 900px) {
    right: 3rem;
    width:54vw;
  }
`;

const ServicesHeading = styled.h1`
  position: absolute;
  top: -8rem;
  left: 2rem;
  color: black;
  font-size: 1.2vw;
  text-transform: capitalize;
    font-family: sans-serif;
    @media (max-width: 900px) {
      top: -6rem;
      font-size: 18px;
    }
`;

const Wrap = styled.div`
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;
 
  // border-top: 1px solid;
  h1 {
    padding: 2rem;
    font-size: 20px;
    font-family: sans-serif;
    text-transform: capitalize;
    text-align: initial;
    line-height: 28px;
  }

  span {
    margin-right: 1.5rem;
  }
`;

const Dropdown = styled.div`
  color: black;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  

  p {
    font-size: 20px;
    margin-left: 2rem;
    line-height: 1.5vw;
    
  }
  @media (max-width: 900px) {
   p{
    font-size: 10px;
    line-height: 4.55vw;
    
   }
  }
  transition: all 0.3s ease;
`;

const Accordion = () => {
  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }

    setClicked(index);
  };

  return (
    <IconContext.Provider value={{  size: '25px' }}>
      <AccordionSection>
        <Container>
          <ServicesHeading>Services</ServicesHeading>
          {Data.map((item, index) => {
            return (
              <>
                <Wrap onClick={() => toggle(index)} key={index}>
                  <h1>{item.question}</h1>
                  <span>{clicked === index ? <FiMinus /> : <FiPlus />}</span>
                </Wrap>
                {clicked === index ? (
                  <Dropdown>
                    <p>{item.answer}</p>
                  </Dropdown>
                ) : null}
              </>
            );
          })}
        </Container>
      </AccordionSection>
    </IconContext.Provider>
  );
};

export default Accordion;
