import React from "react";
import "./projectdetails.css";
import hotelp from "../Assets/hotelp.png";
import food from "../Assets/food.jpg"
import foo1 from "../Assets/food2.jpg"
import bur1 from "../Assets/bur1.jpg"
import bur2 from "../Assets/bur2.jpg"
import phm from "../Assets/phm.png"
import fullh from "../Assets/fullh.png"
import m from "../Assets/m.png"
import all from "../Assets/all.png"
import Fade from 'react-reveal/Fade';
import hteltab from "../Assets/hoteltab.png"
import { useState,useEffect } from "react";
import transition from "../../transition";
function Projecthote() {


  const images = [
    {
      id: 1,
      src: "https://images.unsplash.com/photo-1543007630-9710e4a00a20?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1935&q=80",
      alt: "Image 1",
    },
    {
      id: 2,
      src: "https://images.unsplash.com/photo-1543007630-9710e4a00a20?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1935&q=80",
      alt: "Image 2",
    },

    // Add more images as needed
  ];
  const [isAnimating, setIsAnimating] = useState(false);

  const handleImageClick = () => {
    // Start the animation when the image is clicked
    setIsAnimating(true);
  };

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector('[data-waay-step]');
      if (element) {
        const elementPosition = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        setIsVisible(elementPosition < windowHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);
    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className="projectdetailgvk">
      <div class="header-aside-trigger"></div>
      <div class="header-aside">
        <svg
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="274"
          height="276"
          viewBox="0 0 274 276"
        >
          <path
            fill="#E2D1FF"
            fill-rule="evenodd"
            d="M151.551 150.546c13.85 0 25.118-11.256 25.118-25.091h25.117c0 27.67-22.535 50.181-50.235 50.181s-50.235-22.511-50.235-50.181V50.182c0-13.835-11.268-25.091-25.118-25.091-13.85 0-25.118 11.256-25.118 25.09H25.962c0-27.67 22.536-50.18 50.236-50.18 27.7 0 50.235 22.51 50.235 50.18v75.274c0 13.835 11.268 25.09 25.118 25.09zm-125.589 0c0 55.34 45.071 100.363 100.471 100.363V276C57.183 276 .845 219.721.845 150.546h25.117zm247.5-129.13l-28.796 28.766 28.796 28.765L255.7 96.69l-28.796-28.765-28.796 28.765-17.761-17.742 28.796-28.765-28.796-28.766 17.761-17.741 28.796 28.765L255.7 3.675l17.762 17.741z"
          />
        </svg>
      </div>
      <section
        class="banner banner--case-study"
        style={{ color: "#2e2e2e", backgroundColor: "#9aa2a5" }}
      >
        <div class="banner__image">
          <img
            width="1800"
            height="1076"
            src="https://images.unsplash.com/photo-1633204339709-0721e5c20280?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
            class="attachment-full size-full"
            alt="WA Good Food Guide Website Case Study"
            loading="eager"
            srcset="https://images.unsplash.com/photo-1633204339709-0721e5c20280?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
            onClick={handleImageClick}
          />{" "}
        </div>

        <div class="container">
          <div class="banner__caption">
            <h1 class="banner__title" data-waay="step-up">
              <span data-waay-step>
                <span class="banner__subtitle">
                <Fade bottom>
                <span style={{ fontFamily: "MaisonNeue, HelveticaNeue-Light, 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif" ,textTransform:'capitalize'}}>
                  37th Crescent
                  </span>
                  </Fade></span>
                  
              </span>{" "}
              <span data-waay-step>
         

              <Fade bottom ><span style={{ fontFamily: "MaisonNeue, HelveticaNeue-Light, 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif" ,textTransform:'initial'}}>The Perfect Place to Relax and Rejuvenate</span> </Fade></span>
            </h1>
          </div>
        </div>
      </section>
      <main id="skip-to-content" class="content-main">
        <div class="section section--floating-cta">
          <div class="section__inner">
            <a
              href="#"
              target="_blank"
              rel="noopener"
              title="Visit the WA Good Food Guide website (New window)"
              class="block__link step-up btn btn--link-arrow"
              data-delay="3"
              style={{ backgroundColor: "#ffffff", color: "#000000" }}
            >
              <span class="btn__label" style={{ marginTop: "-4rem" }}>
                Visit the 37th Crescent
              </span>
            </a>
          </div>
        </div>

        <section
          class="case-study__introduction case-study__introduction--alt"
          style={{ backgroundColor: "#9aa2a5", color: "#ffffff" }}
        >
          <div class="container">
            <div class="case-study__introduction-inner">
              <div
                class="case-study__introduction-description"
                data-waay="step-up"
              >
                {/* <div class="case-study__introduction-copy" data-waay-step> */}
                <div className={`case-study__introduction-copy ${isVisible ? 'animate' : ''}`} data-waay-step>

                {/* </div> */}
                <Fade bottom>
                <p style={{ fontFamily: "MaisonNeue, HelveticaNeue-Light, 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif" }}>
                  The 37th Crescent Hotel is a charming boutique hotel located in the heart of Bangalore. The hotel has a well-deserved reputation for its excellent food, and it has been featured in several publications.
                  The hotel's restaurant,  offers a wide variety of dishes, from Indian to Continental. The menu changes seasonally, but you can always expect to find fresh, locally-sourced ingredients. The restaurant also has a dedicated wine list, with wines from around the world.
                  In addition to its excellent food, the 37th Crescent Hotel also offers a number of other amenities, including a fitness center, a swimming pool, and a business center. The hotel is also pet-friendly, making it a great choice for travelers with furry friends.
                  </p>
                  </Fade>
                </div>
              </div>
              <div class="case-study__introduction-features">
                <ul data-waay="step-up">
                  <li data-waay-step>Digital Strategy</li>
                  <li data-waay-step>Editorial Focus</li>
                  <li data-waay-step>UX &amp; UI Design</li>
                  <li data-waay-step>Flexible Grid Layout</li>
                  <li data-waay-step>Responsive Design</li>
                </ul>

          
              </div>
            </div>
          </div>
        </section>
        <section class="case-study__modular-content"  style={{ backgroundColor: "#9aa2a5" }}>
          <section
            class="case-study__block case-study__block--1 case-study__image case-study__image--full-width"
            style={{ backgroundColor: "9aa2a5" }}
          >
            <div class="block__image">
              <div class="block__image-spacer"></div>

              <img
                width="1800"
                height="1000"
                src={phm}
                class="attachment-full size-full"
                alt="laptop showing wa good food guide homepage"
                loading="lazy"
                srcset={phm}
                sizes="(max-width: 1800px) 100vw, 1800px"
              />
            </div>
          </section>
          <section
            class="case-study__block case-study__block--2 case-study__50-50 case-study__50-50--flipped"
            style={{ backgroundColor: "#9aa2a5" }}
          >
            <div class="block__inner">
              <div>
                <div class="block__image">
                  <div class="block__image-spacer"></div>
                  <img
                    src={food}
                    alt=""
                    width="900"
                    height="769"
                    loading="lazy"
                  />
                </div>
              </div>
              <div>
                <div class="block__content">
                  
                  <div class="block__content-inner" data-waay="step-up">
                    
                    <h2 class="block__title" data-waay-step>
                      Minimal, elegant, agnostic
                    </h2>
                    <Fade bottom>
                    <div data-waay-step>
                      <p>
                      With inconceivable food and venue imagery from different sources it was vital that the oil remain visually neutral. Inspired by magazine tract, this black- and-white minimum style was n’t just a design decision, but a strategic call add content like above fro a banglore grounded 3satr hostel named 37th Crescent hostel
                      </p>
                    </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            class="case-study__block case-study__block--3 case-study__wysiwyg"
            style={{ backgroundColor: "#ffffff", color: "#222222" }}
          >
            <div class="container container--sm" data-waay="step-up">
              <h2 class="block__title" data-waay-step>
                Designing for flexibility
              </h2>
              <div class="block__content" data-waay-step>
              <Fade bottom>
                <p>
                A highly flexible grid design can accommodate a wide variety of visual styles and content. This ensures that the overall look and feel of the site is always consistent, no matter how much or little information is available for each venue.
                </p>
                </Fade>
              </div>
            </div>
          </section>
          <section
            class="case-study__block case-study__block--4 case-study__image"
            style={{ backgroundColor: "#ffffff" }}
          >

            <div class="container">
              <div class="block__image">
                <div class="block__image-spacer"></div>
 <Fade bottom>
                <img
                 
                  src={all}
                  class="attachment-full size-full"
                  alt="chef cards"
                  loading="lazy"
                  srcset={all}
                  sizes="(max-width: 1400px) 100vw, 1400px"
                />
                </Fade>
              </div>
            </div>
          </section>
       
          <section
            class="case-study__block case-study__block--6 case-study__image case-study__image--full-width"
            style={{ backgroundColor: "#ffffff", color: "#222222" }}
          >
            <div class="block__image">
              <div class="block__image-spacer"></div>

              <img
                width="1800"
                height="1400"
                src={hteltab}
                class="attachment-full size-full"
                alt="ipad and iphone devices showing top 50 restaurant listings"
                loading="lazy"
                srcset={hteltab}
                // sizes="(max-width: 1800px) 100vw, 1800px"
              />
            </div>
          </section>
        
          <section
            class="case-study__block case-study__block--8 case-study__aside-image  case-study__aside-image--flipped case-study__aside-image--sticky"
            style={{ backgroundColor: "#000000", color: "#ffffff" }}
          >
            <div class="container">
              {" "}
              <div class="block__inner">
                <div>
                  <div class="block__image">
                    <div class="block__image-spacer"></div>
                   
                    <img
                      width="815"
                      height="3352"
                      src={fullh}
                      class="attachment-full size-full"
                      alt="wa good food guide homepage screenshot"
                      loading="lazy"
                      srcset={fullh}
                      // sizes="(max-width: 815px) 100vw, 815px"
                      // style={{width:'80%'}}
                    />
                    
                  </div>
                </div>
                <div>
                  <div class="block__content block--sticky">
                    <div class="block__content-inner" data-waay="step-up">
                      <h2 class=" block__title" data-waay-step>
                      <Fade bottom>
                        The first bite is with the eye
                        </Fade>
                      </h2>

                      <div data-waay-step>
                      <Fade bottom>
                        <p>
                        The Top 50 Restaurants is more than just a list of the best restaurants in Bangalore. It is a taster that whets the appetite for all that the city has to offer in terms of food and entertainment.
                         
                        </p>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>{" "}
        </section>

        <div class="arrow-cursor">
          <svg
            class="arrow-cursor__icon"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="94"
            height="87"
            viewBox="0 0 94 87"
          >
            <path
              class="arrow-cursor__path"
              fill="none"
              fill-rule="evenodd"
              stroke="#000000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
              d="M54.177 83.487a3 3 0 0 1-4.244 0l-8.484-8.484a3 3 0 0 1 0-4.244l18.576-18.592H4.997a3 3 0 0 1-3-3v-12a3 3 0 0 1 3-3h55.028L41.45 15.607a3 3 0 0 1 0-4.244l8.484-8.484a3 3 0 0 1 4.244 0l36.064 36.06a6 6 0 0 1 0 8.488l-36.064 36.06z"
            />
          </svg>{" "}
        </div>

        <section
          class="case-study__footer case-study__footer--alt"
          style={{ backgroundColor: "#000000", color: "#ffffff" }}
        >
          <div class="container">
            <div class="block__inner">
              <div class="block__content" data-waay="step-up">
                <h2 data-waay-step>
                <Fade bottom>
                  Engaging visitors with a gourmet digital experience
                  </Fade>
                </h2>


                <div class="block__description" data-waay-step>
                <Fade bottom>
                  <p>
                  We stay true to the intention and purpose of the Bangalore Good Food Guide by stoking the hunger and passion for epicurean delights in Bangalore's booming food and entertainment industry. We believe that food is a way to bring people together and celebrate the diversity of our city. We are proud to be a part of this community, and we look forward to continuing to offer our guests the best of what Bangalore has to offer.

                  </p>
                  </Fade>
                </div>
              </div>

              <div class="block__link-container">
                <a
                  href="#"
                  target="_blank"
                  rel="noopener"
                  title="Visit the WA Good Food Guide website (New window)"
                  class="block__link step-up btn btn--link-arrow"
                  data-delay="3"
                  style={{ backgroundColor: "#000000", color: "#ffffff" }}
                >
                  <span class="btn__label">Visit the 37th Crescent hotel</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>

    
    </div>
  );
}

export default transition(Projecthote);
// export default transition(Home);