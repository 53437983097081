import React, { useState } from "react";
import "./imga.css";
import brb from "../Assets/brb.png";
import clpb from "../Assets/clpb.png";
import fav from "../Assets/favb.png";
import gvk from "../Assets/gvkb.png";
import hob from "../Assets/hob.png";
import inb from "../Assets/inb.png";
import pib from "../Assets/pib.png";
import sanb from "../Assets/sanb.png";
import ssvb from "../Assets/ssvb.png";

const ImageGallery = () => {
  const [isPaused, setIsPaused] = useState(false);

  const handleButtonClick = () => {
    setIsPaused((prevState) => !prevState);
  };

  return (
    <div className="abt-01">
      <section className="abt-slider">
        <button onClick={handleButtonClick}></button>
        <article className={isPaused ? "paused" : ""}>
          <div className="aboutheic">
            <ul className="slider">
              <li>
                <img src={brb} alt="Image 1" />
              </li>
              <li>
                <img src={clpb} alt="Image 2" />
              </li>
              <li>
                <img src={fav} alt="Image 3" />
              </li>
              <li>
                <img src={gvk} alt="Image 4" />
              </li>
              <li>
                <img src={hob} alt="Image 4" />
              </li>
              <li>
                <img src={inb} alt="Image 4" />
              </li>{" "}
              <li>
                <img src={pib} alt="Image 4" />
              </li>{" "}
              <li>
                <img src={sanb} alt="Image 4" />
              </li>
              <li>
                <img src={ssvb} alt="Image 4" />
              </li>
              <li>
                <img src={brb} alt="Image 1" />
              </li>
              <li>
                <img src={clpb} alt="Image 2" />
              </li>
              <li>
                <img src={fav} alt="Image 3" />
              </li>
            </ul>
          </div>
        
        </article>
      </section>
    </div>
  );
};

export default ImageGallery;
