import React from 'react'
import gsap from 'gsap';
import { useEffect,useState } from 'react';
function Loader() {
    useEffect(() => {
        const tll = gsap.timeline({
          paused: true
        });
    
        const percent = document.getElementById('percent');
        const bar = document.getElementById('barconfrm');
        const preloader = document.getElementById('preloader');
        // const container = document.querySelector('.container');
        // const h1Elements = document.querySelectorAll('.container h1');
    
        tll.to([percent, bar], {
          duration: 0.2,
          opacity: 0,
          zIndex: -1
        });
        tll.to(preloader, {
          duration: 0.8,
          width: '0%'
        });
       
      
    
        let width = 1;
        let id;
        const move = () => {
          id = setInterval(frame, 10);
        };
    
        const frame = () => {
          if (width >= 100) {
            clearInterval(id);
            tll.play();
          } else {
            width++;
            bar.style.width = width + '%';
            percent.innerHTML = width + '%';
          }
        };
    
        move();
      }, []);
  return (
    
    <div>
    <div id="preloader">
      <div id="percent">1%</div>
      <div id="bar">
        <div id="barconfrm" style={{ backgroundColor: 'white' }}></div>
      </div>
    </div>
   
  </div>
  )
}

export default Loader