import firebase from 'firebase'

// Your Firebase configuration object
var firebaseApp = firebase.initializeApp({
   
        apiKey: "AIzaSyBHh0SjyhAhJqVtOAmE7-ZkAjYl0Zyg1rs",
        authDomain: "react-contact-form-ee758.firebaseapp.com",
        projectId: "react-contact-form-ee758",
        storageBucket: "react-contact-form-ee758.appspot.com",
        messagingSenderId: "579703938698",
        appId: "1:579703938698:web:d7b490d4f3e391eb6e9773"
      
})


// Get a Firestore instance
// const db = getFirestore(firebaseApp);
const db = firebaseApp.firestore();

export { db };
